import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Tabs, Tab } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";

import { Link, useNavigate } from "react-router-dom";
import SwitchProfile from "../../component/login-register/switchProfile";
import { externalDocumentsCall, myAppointmentsCall, PatientMyBillsCall } from "../../actions/dashboard-api";
import InfiniteScroll from 'react-infinite-scroll-component';
import LodingComponent from "../../component/loding";
import NoMoreData from "../../component/no-more-data/noMoreData";

import { configurations } from "../../actions/get-calls";
import { ToastContainer, toast } from 'react-toastify';

import PatientMyBillsListing from "../patientMyBillsListing";
import DashboardLink from "../../component/dashboard-link";
import DaysFileter from "../../component/days-fileter";
import { groupId, hospitalgroupId, is_group_exist } from "../../utils/constant";
const breadcrumArrry=[
    {
      name:'My Dashboard',
      url:"/my-dashboard"
    }, 
    {
        name:'My Bills',
        url:''
      }, 
  ]
const PatientMyBills = () => {
    const switchToken = useSelector((state) => state.switchToken.value);
    const selectedUser = useSelector((state) => state.selectedUser.value);
    const selectedUserCard = useSelector((state) => state.selectedUserCard.value);
    const currentLocation = useSelector((state) => state.currentLocation.value);
    const [doccurrentTab, setDocCurrentTab] = useState("MyBills");

    // For External Documents Hooks
    const [billsDocuments, setBillsDocuments] = useState([]);



    const [billsPage, setBillsPage] = useState(1);
    const [billLoder, setBillLoder] = useState(false);
    const [filterDays, setFilterDays] = useState(30);


    const [pagination, setPagination] = useState();
    const [hasMore, setHasMore] = useState(true);




    const allUsers = useSelector((state) => state.allUsers.value);
    const navigate = useNavigate();
      useEffect(() => {
        if (!allUsers && !selectedUserCard) {
          navigate(`/login`);
        }
      },[]);

      let patient_id;
      if(selectedUserCard){
        let patient_id = selectedUserCard.id;
      }


         useEffect(() => {
        // For External Documents 
        const payload = new FormData();
        payload.append('registrationId', selectedUserCard.mhea_patient_id);
        payload.append('page', 1);
        payload.append('departmentType',"");
        payload.append('encounterType', '');
        payload.append('pageSize', 2000);
        payload.append('days', filterDays);
        payload.append('hospital_id', currentLocation.hospitalID);
        if(is_group_exist === true){
            payload.append('hospital_group_id', hospitalgroupId);
           // payload.append('group_id', groupId);
        }
   
        PatientMyBillsCall( payload, switchToken).then(function (response) {           
            // setExtPagination(response.data.pagination)
            setBillsDocuments(response.data.data);
            }).catch(function (err) {
                console.log(err);
            });
     
     

    }, [ selectedUserCard]);

    useEffect(() => {
        setBillsDocuments(billsDocuments)
    },[billsDocuments])


    const getDatFiltterCall=(e)=>{
        let daysValue=parseInt(e.target.value)
        setFilterDays(daysValue);
        const payload = new FormData();
        payload.append('registrationId', selectedUserCard.mhea_patient_id);
        payload.append('page', 1);
        payload.append('departmentType','');
        payload.append('encounterType', '');
        payload.append('pageSize', 2000);
        payload.append('days', daysValue);
        payload.append('hospital_id', currentLocation.hospitalID);
        if(is_group_exist === true){
            payload.append('hospital_group_id', hospitalgroupId);
           // payload.append('group_id', groupId);
        }
    
        PatientMyBillsCall( payload, switchToken).then(function (response) {
         
            // setExtPagination(response.data.pagination)
            setBillsDocuments(response.data.data);
        }).catch(function (err) {
                console.log(err);
            });
    }



    // For My Appointments More Data
    const fetchExternalData = () => {
    
        // if (doccurrentTab === "ExternalDocuments" && billsDocuments < pagination.total_pages) {
        //     setExtLoder(true)       
        //     let extNextPage;
        //     extNextPage = extNextPage + 1;
        //     setMyAppointmentsPage(extNextPage)
        //     externalDocumentsCall(patient_id, extNextPage, '', switchToken).then(function (response) {
        //         setBillsDocuments(response.data.records);
        //         setExtLoder(false)
        //     })

        // } else {
        //     setExtMore(false);
        // }
    }


      function showError(str) {
        toast.error(str, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false
        });
      }
      function showToast(str) {
        toast.success(str, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false
        });
      }
    

    return (
        <React.Fragment>
            <Container className="min-body-height">
                <div className="row  align-items-center breadcrumb_sticky">
                    <div className="col-md-9">   <Breadcrumb textUrl={breadcrumArrry} />        </div>
                    <div className="col-md-3 d-none d-md-block">
                    <DashboardLink />
                    </div>

                </div>
                
                <SwitchProfile />

                <div className="row mt-4">
                    <div className="col-12">
                        <h1 className="fs-4 mb-1 text-secondary">My Bills</h1>
                        {/* <p className="m-0 fs-6">Self-upload your health documents</p> */}
                    </div>
                </div>
                <div className="mhc-tabs position-relative mt-4">
                <DaysFileter getDatFiltterDay={(e)=>getDatFiltterCall(e)} filterDays={filterDays} id="mybill" name="mybill" />
                {
                        // <LodingComponent className="mhc-Loding-minHeight" />
                                billsDocuments?.length ==  0 ? <h6 className="text-center mt-2 mb-4">No Data Found</h6> :
                                billsDocuments === undefined ? <h6 className="text-center mt-2 mb-4">No Data Found</h6>
                                
                           : <div id="billsDocuments" className="mhc-scrollDiv">
                                    
                                  
                                    {/* <InfiniteScrollMy Bills
                                    dataLength={billsDocuments?.length}
                                    next={fetchExternalData}
                                    hasMore={hasMore}
                                    loader={billLoder == true ? <LodingComponent className="text-center" /> : ''}
                                    scrollableTarget="billsDocuments"
                                    endMessage={pagination.total_pages > 1 ? <NoMoreData className="text-center mb-3" /> : ''}
                                >
                                    {
                                        <PatientMyBillsListing patientMyBills={billsDocuments}  />
                                    }

                                </InfiniteScrollMy> */}
                                <PatientMyBillsListing patientMyBills={billsDocuments}  />
                                </div>
                            }
                
                    {/* <Tabs                 
                     activeKey={doccurrentTab}
                     onSelect={(key) => setDocCurrentTab(key)}
                    id="controlled-tab-example" className="mb-3">
                        <Tab eventKey="MyBills" title="My Bills">
                       
                            
                           <PatientMyBillsListing />
                        </Tab>
                 

                    </Tabs> */}


                </div>

            </Container>
 
        </React.Fragment>
    )
}


export default PatientMyBills