import React from "react";
import bloodTest from '../../assets/images/Blood Test CBC.svg'
import UltrasoundWholeAbdomen from '../../assets/images/Ultrasound Whole Abdomen.svg'
import DownloadIcon from '../../assets/images/download-btn.svg'
import InputDatePiker from "../../component/datepiker/inputDatePiker";
import { saveAs } from "file-saver";
import moment from 'moment';
import { dateTo, from } from 'date-fns'
 const PatientDischargeSummaryData  =({dischargeSummary})=>{

   
    const savePdf = (e, url) => {
        saveAs(url, "myreport.pdf");
      };
  
        return(
            <React.Fragment>
                    <div className="row d-none">
                <div className="col-xl-5 col-lg-6 col-md-8">
                 <div className="d-flex flex-column flex-md-row">
                 <div className="form-floating mhc-dob date">
                 <InputDatePiker />
              <i className="mhc-icon icon-calendar"></i>
             <label htmlFor="Dob" className="dob">Start Date</label>
                    </div>
                    <div className="form-floating mhc-dob date mt-3 mt-md-0  ms-0 ms-md-3">
                    <InputDatePiker />
              <i className="mhc-icon icon-calendar"></i>
             <label htmlFor="Dob" className="dob">End Date</label>
                   </div>
                 </div>
                 </div>
                 </div>
                <div className="table-responsive">
           <table className="table mhc-document-table">
                    <thead>
                        <tr>
                        <th>Doctor Name</th>
                        <th>Admission Type</th>
                        <th>Admission Date</th>
                        <th>Discharge Date</th>
                        <th className="text-center"> Report</th>
                        </tr>
                    </thead>
                   <tbody>
                    
                       {
                            dischargeSummary?.map((item, i)=>{                             
                                let url = item.filepath;                                                                                  
                               return (
                                <tr key={i}>
                                <td >
                                    {/* <img src={item.filepath}  alt={item.form_type} className="test-icon" /> */}
                                
                                 {item.doctor_name}<br /><small>{item.specialilty_name}</small></td>
                                <td>{item.form_type }</td>
                                <td>{ item.admission_date.split("|", 1) }</td>
                                <td>{item.discharge_date.split("|", 1) }</td>
                                <td className="text-center">
                                     <a
                                        onClick={(e) => savePdf(e, url)}
                                        download={item.patient_report_pdf}>
                                        <img
                                        src={DownloadIcon}
                                        alt={DownloadIcon}
                                        className="downloadbtn"
                                        />
                                    </a>

                                </td>
                               </tr>
                                 )
                             })
                        }
                    

                    </tbody>
            </table>
        </div>
            </React.Fragment>
        )
    }

    export default PatientDischargeSummaryData

