import React, { useState } from "react";
import moment from 'moment';
import DatePicker from "react-datepicker";
const IProps ={
    onSelect:''
}
function InputDatePiker (props=IProps){
    const [startDate, setStartDate] = useState(new Date());
    return(
        <React.Fragment>
  <DatePicker    calendarClassName="mhc-input-date"   dateFormat="dd/MM/yyyy" selected={startDate} onSelect={props.onSelect} onChange={(date) => setStartDate(date)}
        minDate={moment().toDate()}
  calendarStartDay={1}
   formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
  />
        </React.Fragment>
    )
}

export default InputDatePiker