import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getOTP, switchPatient, verifyMobileOTP } from "../../actions/post-call";
import { getProfile } from "../../actions/get-calls";
import logo from "../../assets/images/logo-ck-birla.svg"
import { Link, useNavigate } from 'react-router-dom';
import antiClock from "../../assets/images/anticlock.png";



import { setCallTokenReducer, clearCallTokenReducer } from "../../redux/slice/call-token";
import { setSwitchTokenReducer, clearSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setAllUsersReducer, clearAllUsersReducer } from "../../redux/slice/all-users";
import { setSelectedUserReducer, clearSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer, clearUserProfileReducer } from "../../redux/slice/user-profile";
import SpinnerComponent from "../spinnerComponent";
import { APP_SUB_FOLDER, Logo, TermsPageURL, PrivacyPageURL } from "../../utils/constant";


const DashboardLogin = () => {

  const [countryCode, setCountryCode] = useState('');
  const [phone, setPhone] = useState();
  const [otpMessage, setOtpMessageTag] = useState(false);
  const [otpMessageText, setOtpMessageText] = useState("");
  const [otpM, setOtpM] = useState();
  const [otpRe, setOtpRe] = useState(false);
  const [otpNum, setOtpNum] = useState();
  const [numberEmpty, setNumberEmpty] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [callKey, setCallKey] = useState(null);
  const [optErrorMsg, setOptErrorMsg] = useState('');
  const [spinnerResponse, setSpinnerResponse]=useState(false)
  const navigate = useNavigate();


  const dispatch = useDispatch();
  const callToken = useSelector((state) => state.callToken.value);
  const switchToken = useSelector((state) => state.switchToken.value);
  const allUsers = useSelector((state) => state.allUsers.value);
  const selectedUser = useSelector((state) => state.selectedUser.value);
  const currentLocation = useSelector((state) => state.currentLocation.value);


  // console.log(">>>>>>",allUsers)
  useEffect(() => {
    if (allUsers) {
      navigate(`/my-dashboard`);
    }

  },[]);



  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1)
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  function mobileNumberCheck(){
    setNumberEmpty(false);
  }
  function getOTPMobile() {
    if(showOTPForm){
       document.querySelector('#sendOTP').value = '';
       setOptErrorMsg('');
    }
    if(phone == undefined || phone.length != 10){
      setNumberEmpty(true);
   
    }
    else{
    getOTP(phone)
      .then(function (login_response) {
        // console.log(login_response)
        if(login_response != undefined){
              let login_res = login_response.data;
              const login_text="The OTP has been sent to the mobile number "

              if (login_res.message && login_res.message.length > 0) {
                setOtpMessageTag(true);
                setOtpMessageText(login_text + login_res.mobile_number)
                setOtpM('');
                setOtpRe(false);
              }

              if (login_res.errors && login_res.errors.length > 0) {
                setOtpM(login_res.errors[0].message);
                setOtpRe(true);
                setOtpMessageTag(false);
                setOtpMessageText('')
                return false;
              }

              setIsResend(true);
              setTimeLeft(30);
              setShowOTPForm(true);
        }else{
              showError('Something went wrong! Please try again later.')
        }
        
      })
      .catch(function () { });
    }
  }

  function verifyOTPMobile() {
    setSpinnerResponse(true)
    verifyMobileOTP({
      isd_code: "91",
      otp: otpNum,
      uid: phone,
      hospital_group_id: 1,
      group_id:1
    })
      .then(function (response) {
        let res = response.data;
        setSpinnerResponse(true)
        setOptErrorMsg('');
        if(res.errors){
          setOptErrorMsg(res.errors[0].message)
        }
        
        setOtpMessageTag(false);
        setOtpMessageText('');

        dispatch(setCallTokenReducer(response.headers.authorization));
        dispatch(setAllUsersReducer(res.users));

        setOtpRe(false);
        setCurrentUserId(res.users[0].id);

        if (res && res.users && res.users.length == 1) {
          dispatch(setSwitchTokenReducer(response.headers.authorization));
          dispatch(setSelectedUserReducer(res.users[0]));
          navigate(`/my-dashboard`);
        }

        if (res && res.users && res.users.length > 1) {
          switchUser(res.users[0].id, response.headers.authorization);
          navigate(`/my-dashboard`);
        }
      })
      .catch(function (err) { });
  }

  function switchUser(id, token) {
    switchPatient({ patient_id: id, hospital_group_id:1, group_id:1}, token ? token : callKey)
      .then(function (switch_response) {
        let switch_token = switch_response.headers.authorization;
        dispatch(setSwitchTokenReducer(switch_token));
        dispatch(setSelectedUserReducer(switch_response.data.user));

        getProfile(switch_token)
          .then(function (profile_response) {
            if (profile_response && profile_response.data) {
              // console.log(profile_response.data)
              dispatch(setUserProfileReducer(profile_response.data.user));
              // window.location.reload();
            }
          }).catch(function (err) { });

        setShowConfirmation(false);




      }).catch(function (err) { });
  }


  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }


  return (
    <React.Fragment>
      {allUsers == null ?
        <Container className="login-min-body-height">
          <div className='jumbotron vertical-center d-flex align-items-center min-vh-100s'>
            <div className='container center-container'>
              <div className='row text-center'>
                    <div className='col-12'>
                        <img className='login-logo' src={`${APP_SUB_FOLDER}${Logo}`} />
                    </div>
                </div>

              <div className='row text-center mt-3'>
                <div className='col-12 p-0'>
                  <div className="nav-reigster-form new_login">
                    <div className='row mb-4'>
                      <div className='col-12 '>
                        <h3>Log in to your account</h3>
                      </div>
                    </div>


                    <div className="d-flex">
                      <div className="w-25">
                        <select
                          className="form-select"
                          value={countryCode}
                          onChange={(e) => setCountryCode(e.target.value)}
                        >
                          <option value="91">+91</option>
                        </select>
                      </div>
                      <div className="w-75">
                        <div className="input-group enter-otp">
                          <input
                            type="text"
                            maxLength="10"
                            className="form-control"
                            id="mobile_no"
                            placeholder="Mobile Number"
                            onKeyDown={(e) => mobileNumberCheck()}
                            onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />
                          <button
                            type="submit"
                            className=" rounded-end  border-0 text-white bg-custom px-2 px-md-3"
                            id="basic-addon1"
                            onClick={() => getOTPMobile()}>
                            Send OTP
                          </button>
                        </div>
                      </div>
                    </div>
                    {numberEmpty === true && (<><p className="m-0 fs-7 text-primary mt-2">Please enter a valid mobile number.</p></>)}
                    <p className="mt-2">Please use mobile number registered with {currentLocation.label}  to avoid re-registration</p>
                 
                    {otpMessage === true && (<p className="m-0 fs-7 mt-2">{otpMessageText}</p>)}
                    {otpRe === true && (<><p className="m-0 fs-7 text-primary mt-2">{otpM}</p></>)}


                    {
                      showOTPForm && (
                        <>
                          <div className="input-group enter-otp mt-4">
                            <input
                              type="text"
                              id="sendOTP"
                              className="form-control rounded-start"
                              placeholder="Enter OTP"
                              maxLength="4"
                              aria-label="Enter OTP"
                              aria-describedby="basic-addon1"
                              onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                              onChange={(e) => {
                                setOtpNum(e.target.value);
                              }}
                            />
                            <div className="input-group-text rounded-end custom-text">

                            {
                        isResend ? (
                          <div className="anitClock">
                            <img src={`${APP_SUB_FOLDER}${antiClock}`} className="anti-img" alt="" />
                            <p className="otp-time">{timeLeft}</p>
                          </div>
                        ) : (
                          <button onClick={() => getOTPMobile()} className="btn fs-6 text-decoration-none text-nowrap" id="basic-addon1" disabled={isResend}>Resend OTP</button>
                        )
                      }
                            </div>
                            {/* <button onClick={() => getOTPMobile()} type="submit" className="input-group-text rounded-end custom-text"
                              id="basic-addon1" disabled={isResend}>Resend OTP{isResend && (' in ' + timeLeft + 's')}</button> */}
                          </div>
                          {optErrorMsg !=''? <p className="text-primary mt-2">{optErrorMsg}</p> : ''}

                          <p className="mb-4 mt-2">
                            By logging in, you agree to our <a href={TermsPageURL} target="_blank" className="text-primary">Terms and Conditions</a> and <a href={PrivacyPageURL} target="_blank" className="text-primary">Privacy Policy</a>
                          </p>

                          <button
                            className="btn btn-custom  rounded-1"
                            onClick={(e) => { verifyOTPMobile() }}>
                            Submit  {spinnerResponse == true? <SpinnerComponent />: null}
                          </button>
                        </>
                      )
                    }

                    <div className='row mt-3'>
                      <div className='col-12 text-center'>
                        <Link to="/user-register" className="text-primary">Register Now</Link> on the {currentLocation.label} Patient Portal
                      </div>
                    </div>
                  </div>

                </div>
              </div>





            </div>
          </div>
        </Container>

        : null}

    <ToastContainer />
    </React.Fragment>
  )

}
export default DashboardLogin;
