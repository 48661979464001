// import logo from "../assets/images/logo-myhealthcare.svg";
import logo from "../assets/images/logo-sps.svg";

// export const APP_SUB_FOLDER = "";
export const APP_SUB_FOLDER = "/sps"; // Default value is empty, else values should be in this format /foldername

export const apiURL = "https://sandboxapiportal.mhea.myhealthcare.co/api";
// export const apiURL = "https://parasmhcapi.myhealthcare.life/api";
export const apiPATH = apiURL + "/patients/web/v2/";

// Discharge Summary
export const opdEmr = "https://opdipd.emr.myhealthcare.co/api";

// MHEA API
// export const mheaUrl = "https://mhctestbaseapi.mhea.myhealthcare.life/api";
export const mheaUrl = "https://sandboxbaseapi.mhea.myhealthcare.co/api";
export const payLaterLink = apiURL + "patients/web/v2/doctors/thyro-thank-you/";
export const aggregatorType = "market_place";
export const apitoken =
  "eyJpdiI6IllkMVdCSVo5QnVacitJQlpcL3NJOTJBPT0iLCJ2YWx1ZSI6IjRERHdxWlB2V0JDYnhzcFwvOEFBbzk1MUFCWllyaFZzczZrQnZ5dmRuYk1VPSIsIm1hYyI6ImVhNGJhZWRjZjI4YmNjOTNiNzEyNmEyYTE2ZDVkYWI3NmY2YWM1NmYxZDNhZTlmNjlhNWFlNTBkY2QxNGY1M2EifQ==";

export const topLogin = true;
export const selectedCountry = "India";

export const Logo = logo;

export const showBookTest = {
  show: false,
  url: "/booking-consult",
  target: "_self",
};
export const showOrderMedicine = {
  show: false,
  url: "/booking-consult",
  target: "_self",
};

export const web_source = "SPS";

export const is_group_exist = true;
export const hospitalgroupName = "SPS";
export const groupId = 7;
export const hospitalgroupId = 7;
export const callCenterTFN = { tel: "03340884088", tfn: "+91 03340884088" };
// export const enquiryEmail = {"email" : "contact@abc.com"}
export const site_SEO_META = false;
export const TermsPageURL = "https://spshospitals.com/privacy-policy/";
export const PrivacyPageURL = "https://spshospitals.com/privacy-policy/";

export function dateFormatIntoYYYYMMDD(x) {
  let today = new Date(x);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return yyyy + "-" + mm + "-" + dd;
}

export function encodeString(str) {
  return window.btoa(unescape(encodeURIComponent(str))).replaceAll("=", "");
}

export function decodeString(str) {
  return window.atob(str);
}

export function calculateAge(dob) {
  if (dob) {
    var from = dob.split("/");
    var birthdateTimeStamp = new Date(from[2], from[1] - 1, from[0]);
    var cur = new Date();
    var diff = cur - birthdateTimeStamp;
    // This is the difference in milliseconds
    var currentAge = Math.floor(diff / 31557600000);
    return currentAge;
  } else {
    return 0;
  }
}
export function timeConvertIntoAM_PM(time) {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
}

export function currentTimeConvertIntoAM_PM() {
  var date = new Date();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function changeDayString(day) {
	let originalDay = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	].indexOf(day);
	let tempDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][originalDay];
	return tempDay;
}
