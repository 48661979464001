import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import phoneCall from "../../assets/images/phone-call.svg";
import loginUser from "../../assets/images/login-User.png";
import location from "../../assets/images/location.svg";
import { getOTP, switchPatient, verifyMobileOTP } from "../../actions/post-call";
import { getHospitalGroupListing, getHospitalListingByCat, getProfile } from "../../actions/get-calls";

import { setCallTokenReducer, clearCallTokenReducer } from "../../redux/slice/call-token";
import { setSwitchTokenReducer, clearSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setAllUsersReducer, clearAllUsersReducer } from "../../redux/slice/all-users";
import { setSelectedUserReducer, clearSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer, clearUserProfileReducer } from "../../redux/slice/user-profile";
import { setHospitalGroupReducer, clearHospitalGroupReducer } from "../../redux/slice/group-hospitals";
import { setcurrentLocationReducer, clearcurrentLocationReducer } from "../../redux/slice/current-location";
import { APP_SUB_FOLDER, hospitalAddressList, hospitalgroupName, topLogin, web_source } from "../../utils/constant";
import { useNavigate } from 'react-router-dom';
import {Logo} from "../../utils/constant";
import { calculateAge } from "../../utils/fliters";
import antiClock from "../../assets/images/anticlock.png";
import { clearMemberProfileReducer } from "../../redux/slice/member-profile";
import { clearSelectedUserCardReducer, setSelectedUserCardReducer } from "../../redux/slice/selected-userCard";
import SpinnerComponent from "../spinnerComponent";
const NavBar = () => {

  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [callKey, setCallKey] = useState(null);
  const [switchKey, setSwitchKey] = useState(null);
  const [userList, setUserList] = useState(null);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [isSubMunu, setIsSubMunu] = useState(true);
  const [isLoginSubMenu, setIsLoginSubMenu] = useState(true);
  const [isAfterLoginSubmenu, setIsAfterLoginSubMenu] = useState(true);
  const [open, setOpen] = useState(false);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [phone, setPhone] = useState();
  const [countryCode, setCountryCode] = useState('');
  const [otpM, setOtpM] = useState();
  const [otpRe, setOtpRe] = useState(false);
  const [otpNum, setOtpNum] = useState();
  const [numberEmpty, setNumberEmpty] = useState(false);
  const [currentLocationMob, setCurrentLocationMob]=useState(true);
  const [sendDisbled, setSendDisabled] = useState(false);
  const [spinnerResponse, setSpinnerResponse]=useState(false)
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const callToken = useSelector((state) => state.callToken.value);
  const switchToken = useSelector((state) => state.switchToken.value);
  const allUsers = useSelector((state) => state.allUsers.value);
  const selectedUser = useSelector((state) => state.selectedUser.value);
  const hospitalAddressList = useSelector((state) => state.hospitalAddressList.value);
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const reschedule = useSelector((state) => state.reschedule.value);

     

  const node = useRef();
// For Hospital List
useEffect(() => {
  if(!hospitalAddressList || hospitalAddressList[0].hospital_group_code != hospitalgroupName){
      getHospitalGroupListing(hospitalgroupName)
      .then(function (response) {
        // console.log(response.data.hospitals[0])
        dispatch(setHospitalGroupReducer(response.data.hospitals));
        dispatch(setcurrentLocationReducer(response.data.hospitals[0]));
      }).catch(function (err) {
          // setLoading(false);
      });
  }
}, [hospitalAddressList]);

  useEffect(() => {
    if (callToken) {
      setCallKey(callToken);
    }
  }, [callToken]);

  useEffect(() => {
    if (switchToken) {
      setSwitchKey(switchToken);
    }
  }, [switchToken]);

  useEffect(() => {
    if (allUsers) {
      setUserList(allUsers);
    }
  }, [allUsers]);

  useEffect(() => {
    if (selectedUser) {
      setSelectedUserProfile(selectedUser);
      setCurrentUserId(selectedUser.id);
    }
  }, [selectedUser]);

  useEffect(() => {
    setTimeout(function() {
        const allProfileList = document.querySelectorAll('input[name="switchUser"]');
        if(searchParams.get("reschedule") === 'true' && reschedule){
          allProfileList.forEach((item) => {
            item.disabled = true;
          });
        }else{
          allProfileList.forEach((item) => {
            item.disabled = false;
          });
        }
    }, 2000);
  }, [reschedule, searchParams]);


  function handlenavbar() {
    setIsNavCollapsed(!isNavCollapsed);
  }

  function handleLogout() {
    //selectedUser
    if (allUsers) {
      dispatch(clearCallTokenReducer());
      dispatch(clearSwitchTokenReducer());
      dispatch(clearAllUsersReducer());
      dispatch(clearSelectedUserReducer());
      dispatch(clearUserProfileReducer());
      dispatch(clearMemberProfileReducer());
      dispatch(clearSelectedUserCardReducer());
    }

    localStorage.clear();
    // window.location.reload();
    navigate(`/`);
  }

  function closeNaveBar(e) {
    // e.preventDefault();
    setIsNavCollapsed(true);
    setIsLoginSubMenu(true);
    setIsAfterLoginSubMenu(true);
    setIsSubMunu(true);
  }
  function handlesubmune() {
    setIsSubMunu(!isSubMunu);
    setIsLoginSubMenu(true);
    setIsAfterLoginSubMenu(true);
  }

  function handleLoginSubMenu(e) {
    e.preventDefault();
    setIsLoginSubMenu(!isLoginSubMenu);
    setIsSubMunu(true);
    setIsAfterLoginSubMenu(true);
  }


/// on click Event Function
  const clickOutside = (e) => {
    if(node.current.contains(e.target)) {     
      return;
    }       setIsLoginSubMenu(true)
  }
  useEffect(() => { 
    document.addEventListener('mousedown', clickOutside);  
    return () => {
        document.removeEventListener('mousedown', clickOutside);
    }
  }, [])



  function handleAfterLoginSubmenu() {
    setIsAfterLoginSubMenu(!isAfterLoginSubmenu);
    setIsSubMunu(true);
    setIsLoginSubMenu(true);
    setCurrentLocationMob(true)
  }
   const handleCurrentLocation=()=>{
    setCurrentLocationMob(!currentLocationMob)   
    setIsAfterLoginSubMenu(true)
   }

  function handlecollspe() {
    setOpen(!open);
  }
  function mobileNumberCheck(){
    setNumberEmpty(false);
    setSendDisabled(false);
    setShowOTPForm(false);
  }
  function getOTPMobile() {
    setOtpRe(false);
    // setShowOTPForm(false);
    if(phone == undefined || phone.length != 10){
      setNumberEmpty(true);
   
    }else{
      getOTP(phone)
      .then(function (login_response) {
        let login_res = login_response.data;

        if (login_res.errors && login_res.errors.length > 0) {
          setOtpM(login_res.errors[0].message);
          setOtpRe(true);
          return false;
        }

        setIsResend(true);
        setTimeLeft(30);
        setSendDisabled(true);
        setShowOTPForm(true);
      })
      .catch(function () { });
    }

  }

  function verifyOTPMobile() {
    setSpinnerResponse(true)
    verifyMobileOTP({
      isd_code: "91",
      otp: otpNum,
      uid: phone,
    })
      .then(function (response) {
        let res = response.data;
        setSpinnerResponse(false)
        if (res.errors && res.errors.length > 0) {
          return toast.error(res.errors[0].message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false
          });
        }

        dispatch(setCallTokenReducer(response.headers.authorization));
        dispatch(setAllUsersReducer(res.users));
        setPhone()
        setShowOTPForm(false);
        setSendDisabled(false);
        setOtpRe(false);
        setCurrentUserId(res.users[0].id);

        if (res && res.users && res.users.length > 1) {
          switchUser(res.users[0].id, response.headers.authorization);
        }else{
          getProfile(response.headers.authorization)
          .then(function (profile_response) {
            if (profile_response && profile_response.data) {
              dispatch(setUserProfileReducer(profile_response.data.user));
              dispatch(setSelectedUserReducer(profile_response.data.user));
              dispatch(setSwitchTokenReducer(response.headers.authorization));

              
              // window.location.reload();
            }
          }).catch(function (err) { });
        }


        navigate(`/my-dashboard`);
      })
      .catch(function (err) { });
  }

  function switchUser(id, token) {
    switchPatient({ patient_id: id }, token ? token : callKey)
      .then(function (switch_response) {
        let switch_token = switch_response.headers.authorization;

        dispatch(setSwitchTokenReducer(switch_token));
        dispatch(setSelectedUserReducer(switch_response.data.user));

        getProfile(switch_token)
          .then(function (profile_response) {
            if (profile_response && profile_response.data) {
              dispatch(setUserProfileReducer(profile_response.data.user));
              // window.location.reload();
            }
          }).catch(function (err) { });

        setShowConfirmation(false);
      }).catch(function (err) { });
  }

  // function switchProfile(e) {
  //   setShowConfirmation(true);
  //   setCurrentUserId(e);
  // }

  function confirmSwitch(e) {
    e.preventDefault();
    switchUser(currentUserId, null);
    setOpen(false);
    dispatch(setSelectedUserCardReducer({"card":"self", "id": currentUserId}));
  }

  function cancelSwitch() {
    setShowConfirmation(false);
    setOpen(false);
  }


  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);

  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1)
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });



  const handleHopital=(e, index)=>{            
    dispatch(setcurrentLocationReducer(hospitalAddressList[index]));
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white ">
      <div className="container position-relative">
        <Link className="navbar-brand" to="/">
          <img src={`${APP_SUB_FOLDER}${Logo}`} />
        </Link>
        <a href={`tel:${currentLocation?.primary_contact}`}><img src={`${APP_SUB_FOLDER}${phoneCall}`} className="mob-phone-call d-block d-lg-none" /></a>
        <div
          className={`menu-toggle d-lg-none ${isNavCollapsed ? "" : "on"}`}
          onClick={handlenavbar}>
          <div className="line-one"></div>
          <div className="line-two"></div>
          <div className="line-three"></div>
        </div>

        <div className={`${isNavCollapsed ? "" : "mob-submenu"} navbar-collapse`} id="navbarSupportedContent">
          <ul className="navbar-nav align-items-center ms-auto  mb-2 mb-lg-0">
            {(allUsers == null && topLogin == true) ? <>
              <li className="nav-item d-block d-lg-flex  align-items-center clicked order-first order-lg-last">
                <a
                  className="nav-link  d-none d-lg-block"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"  
                  onClick={(e) => handleLoginSubMenu(e)}>
                  <span className="icon-user-_1_ "></span>
                  <span className="ms-1">Login</span>
                  
                </a>
                 <span className=" d-none d-lg-block">|</span>
                  <Link className="nav-link   d-none d-lg-block" to="/user-register">Register</Link>
                <span
                  className="nav-link d-flex d-lg-none justify-content-between align-items-center nav-service"
                  onClick={(e) => handleLoginSubMenu(e)}>
                  Login  {isLoginSubMenu === true ? (<i className="mhc-icon  icon-plus"></i>) : (<i className="mhc-icon  icon-minus"></i>)}
                </span>
                <ul ref={node}  className={`${isLoginSubMenu ? "mobsubnone" : "mobsublock"} dropdown-menu dropdown-right`} aria-labelledby="navbarDropdown">
                  <li>
                    <div className="nav-reigster-form">
                      <div className="d-flex">
                        <div className="w-25">
                          <select
                            className="form-select"
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                          >
                            <option value="91">+91</option>
                          </select>
                        </div>
                        <div className="w-75">
                          <div className={`input-group enter-otp ${sendDisbled? "hide_send_btn":""}`}>
                            <input
                              type="text"
                              maxLength="10"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Mobile Number"
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                              onKeyDown={(e) => mobileNumberCheck()}
                              onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                            />
                            <button
                              type="submit"
                              className=" rounded-end btn-custom px-3 border-0"
                              id="basic-addon1"
                              onClick={() => getOTPMobile()}
                        
                              >
                              Send OTP
                            </button>
                          </div>
                        </div>
                      </div>
                      {numberEmpty === true && (<><p className="m-0 fs-7 text-primary mt-2">Please enter a valid mobile number.</p></>)}
                      {otpRe === true && (<><p className="m-0 fs-7 text-primary mt-2">{otpM}</p></>)}

                      {
                        showOTPForm && (
                          <>
                            <div className="input-group enter-otp mt-3">
                              <input
                                type="text"
                                className="form-control rounded-start"
                                placeholder="Enter OTP"
                                maxLength="4"
                                aria-label="Enter OTP"
                                aria-describedby="basic-addon1"
                                onChange={(e) => {
                                  setOtpNum(e.target.value);
                                }}
                              />
                              {/* <button
                                onClick={() => getOTPMobile()}
                                type="submit"
                                className="input-group-text rounded-end text-primary"
                                id="basic-addon1" disabled={isResend}>Resend OTP{isResend && (' in ' + timeLeft + 's')}</button> */}
                               

                                {
                                   isResend ? (
                                    <div className="input-group-text rounded-end custom-text">
                                    <div className="anitClock">
                                      <img src={`${APP_SUB_FOLDER}${antiClock}`} className="anti-img" alt="" />
                                      <p className="otp-time">{timeLeft}</p>
                                    </div></div>
                                  ) : (
                                    <button onClick={() => getOTPMobile()} className="input-group-text rounded-end text-primary" id="basic-addon1" disabled={isResend}>Resend OTP</button>
                                  )
                                }
                    
                            </div>

                            <p className="mb-2 mt-2">Please use mobile number registered with {currentLocation?.label} to avoid re-registration</p>

                            <button
                              className="btn btn-custom rounded-1"
                              onClick={(e) => { verifyOTPMobile() }}>
                              Submit    {spinnerResponse == true? <SpinnerComponent />: null}
                            </button>
                         
                          </>
                        )
                      }

                      <p className="mt-3 mb-0">
                        <Link to="/user-register" onClick={(e) => closeNaveBar(e)} className="text-primary">Register Now</Link> on the {currentLocation?.label} Patient Portal
                      </p>
                    </div>
                  </li>
                </ul>
              </li>
              <li> <Link onClick={(e) => closeNaveBar(e)} className="nav-link dropdown-toggle position-relative  d-block d-lg-none" to="/user-register">Register</Link></li>
              </>
             : ''
            }


            
                <li className="nav-item dropdown">
                  
                <a
                  className={`${hospitalAddressList?.length == 1 ? "":"dropdown-toggle" } nav-link d-none d-lg-block 11`}
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  
                      <img src={`${APP_SUB_FOLDER}${location}`} className="loginUser1 me-1" />
                     {currentLocation?.label} 
                 
                   
                </a>
                <span className="nav-link d-flex d-lg-none justify-content-between align-items-center nav-service" onClick={handleCurrentLocation}>
                {currentLocation?.label} {currentLocationMob === true ? (<i className="mhc-icon icon-plus"></i>) : (<i className="mhc-icon icon-minus"></i>)}
                  </span>
                <ul
                  className={`${hospitalAddressList?.length == 1 ? "d-none":"" } ${currentLocationMob ? "mobsubnone" : "mobsublock"} dropdown-menu dropdown-right locationDrop`}
                  aria-labelledby="navbarDropdown"
                >
                <li>
                <div className="px-2 pt-3 " style={{"minWidth":"300px"}}>
                  {
                    hospitalAddressList?.map((item,index)=>{    
                            
                      return(
                        <div key={index} className="form-check mt-0 custom-checkbox mb-3"  >
                        <input className="form-check-input" type="radio"  name="hopistalLocation" defaultValue={item.hospitalID} data-type={item.type} title={item.label}  id={`hopistal${item.hospitalID}`} onChange={(e)=>handleHopital(e, index)} checked={item.hospitalID===currentLocation?.hospitalID ? true:false} />
                        
                        <label className="form-check-label custom-text cursor-pointer d-inline fw-bolder" htmlFor={`hopistal${item.hospitalID}`} >
                        {item.label}<br />
                        <small className="fw-normal">{item.address}</small>
                        </label>
                      </div>
                      )
                    })
                  }
              
           
                    </div>
                </li>
                </ul>
              </li>
                 <li className="navbar-border"></li>
              {allUsers? 
                <>
              <li className="nav-item dropdown order-first order-lg-last">
                <a
                  className="nav-link dropdown-toggle d-none d-lg-block"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  onClick={(e) => handleLoginSubMenu(e)}
                  aria-expanded="false">
                  <img src={selectedUserProfile?selectedUserProfile.profile_image:loginUser} className="loginUser" />
                
                  <span className="ms-1">{selectedUserProfile && selectedUserProfile.full_name}</span>
                </a>
                <span className="nav-link d-flex d-lg-none justify-content-between align-items-center nav-service" onClick={handleAfterLoginSubmenu}>
                  {selectedUserProfile && selectedUserProfile.full_name} {isAfterLoginSubmenu === true ? (<i className="mhc-icon icon-plus"></i>) : (<i className="mhc-icon icon-minus"></i>)}
                </span>

                
                <ul ref={node} className={`${isAfterLoginSubmenu ? "mobsubnone" : "mobsublock"} dropdown-menu dropdown-right`} aria-labelledby="navbarDropdown">
                   {allUsers.length > 1?
                   <>
                    <li className="dropdown-item pb-2  cursor-pointer border-bottom" onClick={handlecollspe} aria-expanded={open} >
                      <span className="mhc-icon icon-exchange me-2"></span> Switch
                      Profile
                    </li>
                    <li className="p-0">
                      <div className={`collapse collapse-switch ${open === true && "show"}`} >
                        <ul className="list-unstyled">
                          {
                            userList && userList.map((user_item) => {
                              return (
                                <li className="dropdown-item py-2 border-bottom" key={user_item.id}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="switchUser"
                                      id={'navUserId_'+user_item.id}
                                      value={user_item.id}
                                      checked={'navUserId_'+currentUserId === 'navUserId_'+user_item.id}
                                      onChange={(e) => { setCurrentUserId(e.target.value); setShowConfirmation(true); }}
                                    />
                                    <label className="form-check-label ms-2" htmlFor={'navUserId_'+user_item.id} >
                                      {user_item.full_name}
                                      <br />
                                      <small>{user_item.gender}, {calculateAge(user_item.dob)}</small>
                                    </label>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </li>

                      {
                        showConfirmation && (
                          <li className="dropdown-item py-2 border-bottom">
                            <div className="profilebtn">
                              <span type="button" onClick={cancelSwitch}
                                className="btn btn-outline btn-outline-secondary rounded-1 px-2 py-0">
                                Cancel
                              </span>
                              <button type="button" onClick={(e) => confirmSwitch(e)} className="btn btn-outline btn-primary py-0 px-2 rounded-1 ms-3">Continue</button>
                            </div>
                          </li>
                        )
                      }
                    </>
                    : null}
                  <li className="dropdown-item py-2 border-bottom">
                    <Link onClick={(e) => closeNaveBar(e)} to="/my-dashboard"><span className="mhc-icon icon-dashboard me-2"></span>Dashboard</Link>
                  </li>
                  <li className="dropdown-item py-2 border-bottom">
                    <Link onClick={(e) => closeNaveBar(e)} to="/my-profile"><span className="icon-user-_1_ me-2"></span>My Profile</Link>
                  </li>
                  <li className="dropdown-item pt-2">
                    <a className="cursor-pointer" 
                      onClick={((e) => closeNaveBar(e), handleLogout)}>
                      <span className="mhc-icon icon-log-out me-2"></span>
                      Logout
                    </a>
                  </li>
                </ul>
                </li>
                </>
                :null  
            }
          </ul>
        </div>
      </div>
      <ToastContainer />
    </nav>
  );
};

export default NavBar;