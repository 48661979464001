import $ from 'jquery';

// For Check value is Number
export function isNumeric(value) {
    return /^-?\d+$/.test(value);
}
/// function for capitalize word
export let capitalize = str => {

    // str.charAt(0).toUpperCase() +  str.slice(1)

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};


export let capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// age Calculater
  export function calculateAge(dob) {

    if(dob != null){
        let date = dob.split("/");
        let getDOB = date[1]+'/'+date[0]+'/'+date[2];
        var today = new Date();
        var birthDate = new Date(getDOB);
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        let yrs = ' Yr';

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age_now--;

        if(age_now > 0){
            yrs = ' Yrs';
        } 
        return age_now + yrs;
    }
  };

  export function formatAMPM(time) {
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { // If time format correct
            time = time.slice (1);  // Remove full string match value
            time[3] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join (''); // return adjusted time or original string
  }



  export const jQueryLabels = thisElem => {
    $(document).find('.form-select').each(function () {
        var Slabel = $(this).find('option:selected').attr('label');
        if (typeof Slabel === 'undefined') {
            $(this).parent().addClass("notselected");
        } else {
            $(this).parent().removeClass("notselected");
        }
    })
    $(document).on('focusin', '.form-select', function () {
        var getEle = $(this).prop('tagName');
        if (getEle === 'SELECT') {
            $(this).on('change', function () {
                var Slabel = $(this).find('option:selected').attr('label');
                if (typeof Slabel === 'undefined') {
                    $(this).parent().addClass("notselected");
                } else {
                    $(this).parent().removeClass("notselected");
                }
            });
        }
    })



}
export function tConvert(time) {
    return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
  }
  
  export function changeDayString(day) {
    let originalDay = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(day);
    let tempDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][originalDay];
    return tempDay;
}


