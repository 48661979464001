import React from "react";
import bloodTest from "../../assets/images/Blood Test CBC.svg";
import UltrasoundWholeAbdomen from "../../assets/images/Ultrasound Whole Abdomen.svg";
import DownloadIcon from "../../assets/images/download-btn.svg";
import Pickadate from "pickadate/builds/react-dom";
import LodingComponent from "../../component/loding";
import moment from "moment";
import { saveAs } from "file-saver";

const PatientMyReportData = ({ reportList }) => {


  const savePdf = (e, url) => {
    saveAs(url, "myreport.pdf");
  };

  return (
    <React.Fragment>
         

      <div className="table-responsive">
        <table className="table mhc-document-table">
          <thead>
            <tr>
              <th>Description</th>
              <th>Type</th>
              <th>Order Date & Time</th>
              <th className="text-end">Download Report</th>
            </tr>
          </thead>
       

          <tbody>
            {reportList.map((item, i) => {
              let date = moment(item.booking_date).format("DD MMM, YYYY");
              let time = moment(item.booking_date).format("LT");
              let reportType = item.report_type;
              let url = item.patient_report_pdf;
              return (
                <>
                  <tr eventkey={item.id} key={i + 1}>
                    <td>
                      <img
                        src={reportType === 0 ? bloodTest || reportType === 1 : UltrasoundWholeAbdomen}
                        alt={"DocType"}
                        className="test-icon"
                      />
                      {item.dignosis_name}
                    </td>
                    <td>{reportType === 0 ? 'Pathology' || reportType === 1 : 'Radiology'}</td>
                    <td>
                      {date},&nbsp;{time}
                    </td>
                    <td className="text-end">
                      <a
                        onClick={(e) => savePdf(e, url)}
                        download={item.patient_report_pdf}>
                        <img
                          src={DownloadIcon}
                          alt={DownloadIcon}
                          className="downloadbtn"
                        />
                      </a>
                    </td>
                  </tr>
                </>
              );
            })}

          
          
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default PatientMyReportData;
