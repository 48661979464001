import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import userPic from "../../assets/images/user.png";
import { Tabs, Tab, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import antiClock from "../../assets/images/anticlock.png";
import { getProfile, getProfileWithPayload } from "../../actions/get-calls";
import { configurations } from "../../actions/get-calls";

import {
  switchPatient,
  bookAppointmentHospital,
  getOTPWithPayloadTokenBased,
  addFamilyMember,
  verifyMobileOTP,
  verifyMobileOTPTokenBased,
  updateFamilyMember,
} from "../../actions/post-call";
import countries from "../../assets/data/countries.json";
import { parasState, parasCity } from "../../actions/dashboard-api";
import { setSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer } from "../../redux/slice/user-profile";
import { Link } from "react-router-dom";
import { calculateAge, isNumeric } from "../../utils/fliters";
import { subMonths } from "date-fns";
import SpinnerComponent from "../spinnerComponent";
import { APP_SUB_FOLDER, web_source } from "../../utils/constant";

const diaTabs = { FamilyMemberY: "Yes", FamilyMemberN: "No" };
// const Iprops = { btnDisplay: true || false };
const Iprops = { className: "" };
const DashBoardFamilyMember = (props = Iprops) => {
  var oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 18);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentMemberId, setCurrentMemberId] = useState(null);

  const [addToken, setAddToken] = useState(null);
  const [patientId, setPatientId] = useState(null);
  const [currentFamilyMembers, setCurrentFamilyMembers] = useState(null);

  const [callToken, setCallToken] = useState(null);
  const [switchToken, setSwitchToken] = useState(null);
  const [loggedUsers, setLoggedUsers] = useState(null);

  const [selectedLocalUser, setSelectedLocalUser] = useState(null);

  const dispatch = useDispatch();
  const callKey = useSelector((state) => state.callToken.value);
  const switchKey = useSelector((state) => state.switchToken.value);
  const allUsers = useSelector((state) => state.allUsers.value);
  const selectedUser = useSelector((state) => state.selectedUser.value);

  const [salutationList, setSalutationList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [bloodGroupList, setBloodGroupList] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [idTypeList, setIDTypeList] = useState([]);
  const [spinnerResponse, setSpinnerResponse] = useState(false);
  const [spinnerResponseConfirm, setSpinnerResponseConfirm] = useState(false);
  useEffect(() => {
    if (callKey) {
      setCallToken(callKey);
    }
  }, [callKey]);

  useEffect(() => {
    if (switchKey) {
      setSwitchToken(switchKey);
    }
  }, [switchKey]);
  useEffect(() => {
    if (allUsers) {
      setCurrentUserId(allUsers[0].id);
      setCurrentMemberId(allUsers[0].id);
      setLoggedUsers(allUsers);
    }
  }, [allUsers]);
  useEffect(() => {
    if (selectedUser) {
      setSelectedLocalUser(selectedUser);
      setCurrentUserId(selectedUser.id);
      setCurrentMemberId(selectedUser.id);
      //   setMemberSelect(selectedUser);
      setCurrentFamilyMembers(selectedUser.dependents);
      //   resetBooking();
    }

    configurations()
      .then(function (response) {
        // console.log(response)
        setSalutationList(response.data.salutation);
        setGenderList(response.data.gender);
        setMaritalStatusList(response.data.marital_status);
        setBloodGroupList(response.data.blood_group);
        setRelationList(response.data.relation);
        setIDTypeList(response.data.id_type);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [selectedUser]);

  const [modal, setModal] = useState(false);
  const [familyTab, setFamilyTab] = useState(diaTabs.FamilyMemberY);
  const [error, setError] = useState();
  const [errorflag, setErrorFlag] = useState(false);

  const [profile, setProfile] = useState(null);
  const [selectedFile, setProfileView] = useState();
  const [DOB, setDOB] = useState(null);
  const [valid, setValid] = useState(null);
  const [allState, setAllState] = useState(null);
  const [allCity, setAllCity] = useState(null);

  const [showForm, setShowForm] = useState("search");
  const [currentOTP, setCurrentOTP] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedUserList, setSearchedUserList] = useState([]);
  const [emailNaState, setEmailNaState] = useState(false);
  const [frontView, setFrontView] = useState();
  const [frontImg, setFrontImg] = useState();
  const [backView, setBackView] = useState();
  const [backImg, setBackImg] = useState();

  const profileHandle = (e) => {
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setProfileView(reader.result);
      setProfile(file);
    }.bind(this);
  };

  const browseImage = (e, browsefrom) => {
    e.preventDefault();
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      if (browsefrom === "front") {
        setFrontView(reader.result);
        setFrontImg(file);
      }
      if (browsefrom === "back") {
        setBackView(reader.result);
        setBackImg(file);
      }
    }.bind(this);
  };

  const [isUpdate, setIsUpdate] = useState(false);
  function handleShow() {
    setModal(true);
  }
  function handleClose() {
    setModal(false);
    setShowForm("search");

    setFamilyTab(diaTabs.FamilyMemberY);
    setSearchText("");
    setCurrentOTP("");

    setAddToken(null);
    setPatientId(null);

    setTimeLeft(30);
    resetForm();

    setIsUpdate(false);
    setIsResend(false);

    setSearchedUserList([]);
    setDOB();
  }

  const handleEmailNaChange = (e) => {
    if (e.target.checked == true) {
      setEmailNaState(true);
      let email = (document.querySelector("#email").value = "");
      formik.setFieldValue("email", "");
    } else {
      setEmailNaState(false);
    }
  };

  function dateParse(date) {
    let cdate = date.split("/");
    return cdate[1] + "/" + cdate[0] + "/" + cdate[2];
  }
  function handletabs(e) {
    setFamilyTab(e.target.value);
    setProfileView();
    setFrontView();
    setBackView();

    if (e.target.value === diaTabs.FamilyMemberN) {
      setShowForm("manage");
      setSearchText("");
    } else {
      setShowForm("search");
    }
    resetForm();
  }
  function resetForm() {
    setDOB();
    setEmailNaState(false);
    // formik.setFieldValue('family_member_id', 0);
    formik.setFieldValue("salutation", "");
    formik.setFieldValue("firstName", "");
    formik.setFieldValue("middleName", "");
    formik.setFieldValue("lastName", "");
    formik.setFieldValue("mobile", "");
    formik.setFieldValue("email", "");
    formik.setFieldValue("dob", new Date());
    formik.setFieldValue("addressOne", "");
    formik.setFieldValue("addressTwo", "");
    formik.setFieldValue("gender", "");
    formik.setFieldValue("maritalStatus", "");
    formik.setFieldValue("relation", "");
    formik.setFieldValue("country", "India");
    formik.setFieldValue("pincode", "");
    formik.setFieldValue("stateName", "");
    formik.setFieldValue("city", "");
    formik.setFieldValue("id_type", "");
    formik.setFieldValue("id_valid_till", new Date());
  }

  function getOTP() {
    let token = null;
    if (switchToken) {
      token = switchToken;
    } else {
      token = callToken;
    }

    getOTPWithPayloadTokenBased(
      {
        isd_code: 91,
        uid: searchText ? searchText : formik.values.mobile,
        secondary_request: 1,
        hospital_group_id: 1,
        group_id: 1,
      },
      token
    )
      .then(function (otp_response) {
        let otp_res = otp_response.data;

        if (otp_res) {
          if (otp_res.errors && otp_res.errors.length > 0) {
            return showError(otp_res.errors[0].message);
          } else {
            setShowForm("otp");
            setIsResend(true);
            setTimeLeft(30);
          }
        }
      })
      .catch(function (error) {});
  }
  function verifyOTP() {
    setSpinnerResponse(true);
    let accessToken = null;
    if (addToken !== null) {
      accessToken = addToken;
    } else {
      if (switchToken) {
        accessToken = switchToken;
      } else {
        accessToken = callToken;
      }
    }

    if (switchToken !== undefined || switchToken !== null) {
      let vPayload = null;
      if (patientId) {
        vPayload = {
          isd_code: "91",
          otp: currentOTP,
          uid: searchText ? searchText : formik.values.mobile,
          family_member_id: patientId,
          secondary_request: 1,
        };
      } else {
        vPayload = {
          isd_code: "91",
          otp: currentOTP,
          uid: searchText ? searchText : formik.values.mobile,
          parent_id: currentUserId,
          secondary_request: 1,
        };
      }

      verifyMobileOTPTokenBased(vPayload, accessToken)
        .then(function (verify_response) {
          setSpinnerResponse(false);
          let verify_res = verify_response.data;

          if (verify_res && verify_res.errors) {
            return showError(verify_res.errors[0].message);
          }

          if (showForm === "last_otp") {
            if (!isUpdate && verify_res && verify_res.users) {
              // let tempLocalUser = { ...selectedLocalUser };
              // tempLocalUser.dependents = verify_res.users[0].dependents;
              dispatch(setSelectedUserReducer(verify_res.users[0]));
            }
            // dispatch(setSelectedUserReducer(tempLocalUser));
            setModal(false);
            handleClose();
          } else {
            setAddToken(verify_response.headers.authorization);
            setSearchedUserList(verify_res.search_object);
            setShowForm("search_list");
          }

          setCurrentOTP("");
        })
        .catch(function (error) {});
    } else {
      verifyMobileOTP({
        isd_code: "91",
        otp: currentOTP,
        uid: searchText,
        hospital_group_id: 1,
      })
        .then(function (verify_response) {
          let verify_res = verify_response.data;

          if (verify_res && verify_res.errors) {
            return showError(verify_res.errors[0].message);
          }

          if (showForm === "last_otp") {
            if (!isUpdate && verify_res && verify_res.users) {
              // let tempLocalUser = { ...selectedLocalUser };
              // tempLocalUser.dependents = [...tempLocalUser.dependents, { ...verify_res.users[0].dependents }];
              dispatch(setSelectedUserReducer(verify_res.users[0]));
            }
            // dispatch(setSelectedUserReducer(tempLocalUser));
            setModal(false);
            handleClose();
          } else {
            setAddToken(verify_response.headers.authorization);
            setSearchedUserList(verify_res.search_object);
            setShowForm("search_list");
          }

          setCurrentOTP("");
        })
        .catch(function (error) {});
    }
  }
  function setFormForAddMember(id) {
    let payload = `?family_member_id=` + id + `&dependence=true`;
    getProfileWithPayload(payload, addToken)
      .then(function (profile_response) {
        let profile_res = profile_response.data;
        // console.log("profile_res", profile_res)

        if (profile_res) {
          if (profile_res.errors) {
            return showError(profile_res.errors[0].message);
          }

          if (profile_res.user) {
            // console.log(profile_res.user)
            formik.setFieldValue("family_member_id", profile_res.user.id);
            formik.setFieldValue("salutation", profile_res.user.salutation);
            formik.setFieldValue("firstName", profile_res.user.first_name);
            formik.setFieldValue("middleName", profile_res.user.middle_name);
            formik.setFieldValue("lastName", profile_res.user.last_name);
            formik.setFieldValue("mobile", profile_res.user.mobile_number);
            formik.setFieldValue("email", profile_res.user.email);
            setEmailNaState(
              profile_res.user.email == "" || profile_res.user.email == null
                ? true
                : false
            );
            formik.setFieldValue(
              "emergencyContact",
              profile_res.user.emergency_contact
            );
            setDOB(
              profile_res.user.dob
                ? new Date(dateParse(profile_res.user.dob))
                : ""
            );
            formik.setFieldValue(
              "gender",
              profile_res.user.gender
                ? genderList.indexOf(profile_res.user.gender)
                : ""
            );
            // formik.setFieldValue('relation', profile_res.user.relation);
            formik.setFieldValue(
              "maritalStatus",
              profile_res.user.marital_status
                ? maritalStatusList.indexOf(profile_res.user.marital_status)
                : ""
            );
            formik.setFieldValue("addressOne", profile_res.user.address_1);
            formik.setFieldValue(
              "addressTwo",
              profile_res.user.address_2 == null
                ? ""
                : profile_res.user.address_2
            );
            formik.setFieldValue(
              "pincode",
              profile_res.user.pin_code == null ? "" : profile_res.user.pin_code
            );

            formik.setFieldValue("country", profile_res.user.country_name);
            // formik.setFieldValue('profile_picture', profile);
            formik.setFieldValue("stateName", profile_res.user.state_name);
            formik.setFieldValue("city", profile_res.user.city_name);
            formik.setFieldValue("id_type", profile_res.user.id_type);
            setValid(
              profile_res.user.id_valid_till
                ? new Date(dateParse(profile_res.user.id_valid_till))
                : ""
            );

            setProfileView(profile_res.user.profile_image);
            setFrontView(profile_res.user.personal_doc.front);
            setBackView(profile_res.user.personal_doc.back);

            setTimeout(() => {
              let stateEle = document.querySelector("#stateName");
              let stateID = stateEle.options[stateEle.selectedIndex].id;

              parasCity(stateID).then(function (response) {
                setAllCity(response.data.cities);
                setTimeout(() => {
                  let cityName = profile_res.user.city_name
                    .toLowerCase()
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");
                  let cityEle = document.querySelector("#city");
                  cityEle.value = cityName;
                  cityEle.dispatchEvent(new Event("change"));
                }, 600);
              });
            }, 800);

            setShowForm("manage");
            setIsUpdate(true);
          }
        }
      })
      .catch(function (err) {});
  }
  function getJWTtoken() {
    if (typeof window !== "undefined") {
      if (switchToken) {
        return switchToken;
      } else {
        return callToken;
      }
    } else if (callToken === undefined) {
      return null;
    } else {
      return null;
    }
  }

  const formik = useFormik({
    initialValues: {
      salutation: "",
      firstName: "",
      middleName: "",
      lastName: "",
      mobile: "",
      emergencyContact: "",
      email: "",
      dob: new Date(),
      gender: "",
      relation: "",
      maritalStatus: "",
      addressOne: "",
      addressTwo: "",
      country: "India",
      stateName: "",
      city: "",
      pincode: "",
      id_type: "",
      id_valid_till: new Date(),
      id_front_img: "",
    },

    onSubmit: (values, helpers) => {
      setSpinnerResponseConfirm(true);
      if (values.salutation == "" || values.salutation == null) {
        showError("Select Title is required");
        setSpinnerResponseConfirm(false);
        return false;
      }
      if (values.firstName == "") {
        showError("First Name is required");
        setSpinnerResponseConfirm(false);
        return false;
      }
      if (values.lastName == "") {
        showError("Last Name is required");
        setSpinnerResponseConfirm(false);
        return false;
      }
      if (values.mobile == "") {
        showError("Mobile No. is required");
        setSpinnerResponseConfirm(false);
        return false;
      }
      if (emailNaState == false && values.email == "") {
        showError("Email is required");
        setSpinnerResponseConfirm(false);
        return false;
      }
      if (DOB == "" || DOB == undefined) {
        showError("Date of birth is required");
        setSpinnerResponseConfirm(false);
        return false;
      }
      if (String(values.gender) == "") {
        showError("Gender is required");
        setSpinnerResponseConfirm(false);
        return false;
      }
      if (values.relation == "") {
        showError("Relation is required");
        setSpinnerResponseConfirm(false);
        return false;
      }
      if (values.addressOne == "") {
        showError("Address Line 1 is required");
        return false;
      }
      if (values.stateName == "") {
        showError("State is required");
        setSpinnerResponseConfirm(false);
        return false;
      }
      if (values.city == "") {
        showError("City is required");
        setSpinnerResponseConfirm(false);
        return false;
      }

      if (values.id_type == "") {
        showError("Identification Document is required");
        setSpinnerResponseConfirm(false);
        return false;
      }
      // if(valid == '' || valid == undefined){
      //   showError("Valid Till is required");
      //   setSpinnerResponseConfirm(false)
      //   return false
      // }
      if (!frontImg) {
        showError("Upload ID Front Image is required");
        setSpinnerResponseConfirm(false);
        return false;
      }

      var payload = new FormData();
      // payload.append('hospital_group_id', 1);
      if (isUpdate) {
        payload.append("family_member_id", values.family_member_id);
      }
      payload.append("salutation", values.salutation);
      payload.append("first_name", values.firstName);
      payload.append("middle_name", values.middleName);
      payload.append("last_name", values.lastName);
      payload.append("isd_code", "91");
      payload.append("mobile_no", values.mobile);
      if (emailNaState == true) {
        payload.append("email_na", 1);
        // payload.append('email', '');
      } else {
        payload.append("email", values.email);
      }
      payload.append("dob", formattedDate(DOB));
      payload.append("gender", values.gender);
      payload.append("marital_status", values.maritalStatus);
      payload.append("relation", values.relation);
      if (values.emergencyContact) {
        payload.append("emergency_contact", values.emergencyContact);
      }
      payload.append("address_1", values.addressOne);
      payload.append("address_2", values.addressTwo);
      payload.append("country", values.country);
      payload.append("pin_code", values.pincode);
      payload.append("state", values.stateName);
      payload.append("city", values.city);
      payload.append("id_type", values.id_type);
      payload.append("id_valid_till", formattedDate(valid));

      if (profile) {
        payload.append("profile_picture", profile);
      }
      if (frontImg) {
        payload.append("id_front_img_url", frontImg);
      }
      if (backImg) {
        payload.append("id_back_img_url", backImg);
      }

      if (isUpdate) {
        updateFamilyMember(payload, addToken)
          .then(function (x_response) {
            let x_res = x_response.data;

            if (x_res && x_res.user) {
              getProfile(addToken)
                .then(function (profile_response) {
                  if (profile_response && profile_response.data) {
                    dispatch(setUserProfileReducer(profile_response.data.user));
                    dispatch(
                      setSelectedUserReducer(profile_response.data.user)
                    );
                    // window.location.reload();
                  }
                })
                .catch(function (err) {});

              // switchPatient({ patient_id: selectedUser.id }, callToken)
              // .then(function (switch_response) {
              //   let switch_token = switch_response.headers.authorization;
              //   console.log("switch_token", switch_token)

              //  console.log('fromm  switch_response.data.user', switch_response.data.user)
              //   dispatch(setSwitchTokenReducer(switch_token));
              //   dispatch(setSelectedUserReducer(switch_response.data.user));

              //   showToast("Family Member Added Successfully")
              // }).catch(function (err) { });

              setModal(false);
              handleClose();
            }

            if (x_res && x_res.errors) {
              x_res.errors.map((e) => {
                return showError(e.message);
              });
              return false;
            }

            setSpinnerResponseConfirm(false);
          })
          .catch(function (err) {});
      } else {
        let token = null;
        if (switchToken) {
          token = switchToken;
        } else {
          token = callToken;
        }

        addFamilyMember(payload, token)
          .then(function (x_response) {
            let x_res = x_response.data;
            setSpinnerResponseConfirm(false);
            if (x_res.errors) {
              x_res.errors.map((e) => {
                return showError(e.message);
              });
              return false;
            }

            setShowForm("last_otp");
            setIsResend(true);
            setTimeLeft(30);
            setPatientId(x_res.patient_id);
          })
          .catch(function (err) {});
      }
    },
  });

  useEffect(() => {
    parasState()
      .then(function (response) {
        // {id: 21, name: 'India'}
        setAllState(response.data.states);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);

  const onChangeState = (e) => {
    let state = e.target.options[e.target.selectedIndex].id;
    // console.log(e.target.options[e.target.selectedIndex].id)
    if (state != "") {
      parasCity(state)
        .then(function (response) {
          setAllCity(response.data.cities);
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      document.querySelector("#city").value = "";
    }

    let Event = e.target.value;
    formik.setFieldValue("stateName", Event);
  };

  function formattedDate(x) {
    let d = new Date(x);
    return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
      .map((n) => (n < 10 ? `0${n}` : `${n}`))
      .join("/");
  }

  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);

  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }

  function showToast(str) {
    toast.success(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }
  return (
    <>
      <Link
        to=""
        className={`btn btn-outline py-1 btn-custom text-decoration-none fs-7 ${props.className}`}
        onClick={handleShow}
      >
        <span className="mhc-icon icon-plus me-2"></span>Add Family Member
      </Link>
      <Modal show={modal} onHide={handleClose} size="lg">
        <Modal.Body>
          <h4 className="fs-7">Add New Family Member</h4>
          <div className="d-flex">
            <p className="m-0 fs-6  fw-bold">
              Is this patient already registered with {web_source}
            </p>
            <div className="form-check ms-3 flex-none">
              <input
                className="form-check-input"
                name="existingMember"
                type="radio"
                value={diaTabs.FamilyMemberY}
                id="yes"
                autoComplete="off"
                onChange={handletabs}
                defaultChecked
                tabIndex="1"
              />
              <label className="form-check-label" htmlFor="yes">
                {diaTabs.FamilyMemberY}
              </label>
            </div>

            <div className="form-check ms-3 flex-none">
              <input
                className="form-check-input"
                name="existingMember"
                type="radio"
                autoComplete="off"
                value={diaTabs.FamilyMemberN}
                id="no"
                onClick={handletabs}
                tabIndex="2"
              />

              <label className="form-check-label" htmlFor="no">
                {diaTabs.FamilyMemberN}
              </label>
            </div>
          </div>

          {showForm === "search" && (
            <div className="row mt-4">
              <div className="col-md-7 col-lg-6 col-xl-5 ">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    maxLength="10"
                    placeholder="Enter Mobile No"
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    autoComplete="off"
                    tabIndex="3"
                  />
                  <button
                    className="input-group-text btn btn-primary"
                    onClick={() => getOTP()}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          )}

          {showForm === "search_list" && (
            <div className="row">
              <div className="col-md-12">
                <hr />
                {searchedUserList && searchedUserList.length > 0 ? (
                  <>
                    <p className="fs-6 fw-bold my-0">Search results</p>
                    <p className="fs-6">
                      Select a user to add as your patient.
                    </p>
                    <div className="row">
                      {searchedUserList &&
                        searchedUserList.map((item) => (
                          <div className="col-md-6 mb-3" key={item.id}>
                            <div className="form-check mhc-bordered-checkbox">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="searched_user"
                                value={item.id}
                                id={"searched_user_" + item.id}
                                onChange={(e) => setFormForAddMember(item.id)}
                                autoComplete="off"
                                tabIndex="4"
                              />
                              <label
                                className="form-check-label w-100"
                                htmlFor={"searched_user_" + item.id}
                              >
                                <div className="d-flex align-items-center conent">
                                  <div className="imgholder">
                                    <img
                                      src={item.profile_image}
                                      alt={item.full_name}
                                    />
                                  </div>
                                  <p className="m-0">
                                    <span className="fw-bold">
                                      {item.full_name}
                                    </span>
                                    <br />
                                    <small>{calculateAge(item.dob)}</small>
                                  </p>
                                </div>
                              </label>
                            </div>
                          </div>
                        ))}
                    </div>
                  </>
                ) : (
                  <>
                    <p className="fs-6 fw-bold my-0">Search results</p>
                    <p className="fs-6">
                      No patient account available with this number.
                    </p>
                  </>
                )}
              </div>
            </div>
          )}

          {(showForm === "otp" || showForm === "last_otp") && (
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-start anticlock-container align-items-xl-center flex-column flex-lg-row mt-4">
                  <p className="fs-6 fw-bold mb-3 mb-lg-0 me-4">
                    An OTP has been sent to your mobile number, please enter to
                    verify.
                  </p>
                  <div className="d-flex align-items-center justify-content-start">
                    <div className="input-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        maxLength="4"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => setCurrentOTP(e.target.value)}
                        autoComplete="off"
                        tabIndex="5"
                      />
                      <button
                        className="input-group-text btn btn-primary"
                        onClick={verifyOTP}
                      >
                        Verify OTP{" "}
                        {spinnerResponse == true ? (
                          <SpinnerComponent className="spinnerfix" />
                        ) : null}
                      </button>
                    </div>
                    {isResend ? (
                      <div className="anitClock ms-2">
                        <img
                          src={`${APP_SUB_FOLDER}${antiClock}`}
                          className="anti-img"
                          alt=""
                        />
                        <p className="otp-time">{timeLeft}</p>
                      </div>
                    ) : (
                      <button
                        onClick={() => getOTP()}
                        className="btn btn-primary ms-2 fs-6 text-decoration-none text-nowrap"
                        disabled={isResend}
                      >
                        Resend OTP
                      </button>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end mt-3"></div>
              </div>
            </div>
          )}

          {showForm === "manage" && (
            <form onSubmit={formik.handleSubmit}>
              <div className="mhc-register-form">
                <div className="row">
                  <div className="col-12">
                    <h1 className="fs-5 mb-3 mt-4 text-center text-lg-start">
                      Patient Details
                    </h1>
                  </div>

                  <div className="col-12 text-center mb-3">
                    <div className="mhc-user-profile">
                      <img src={selectedFile ? selectedFile : userPic} />
                      <input
                        type="file"
                        name="profile_picture"
                        id="profile_picture"
                        onChange={(e) => profileHandle(e)}
                        accept="image/png, image/gif, image/jpeg"
                      />
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div
                      className={`${
                        formik.values.salutation === "" ||
                        formik.values.salutation === null
                          ? "notselected"
                          : ""
                      } mhc-selectbox mb-4 `}
                    >
                      <select
                        className="form-select"
                        id="salutation"
                        name="salutation"
                        onChange={formik.handleChange}
                        value={formik.values.salutation}
                        autoComplete="off"
                        tabIndex="6"
                      >
                        <option></option>
                        {salutationList?.map((item) => {
                          return (
                            <option
                              key={item.id}
                              label={item.name}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="salutation">
                        Select Title <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* First Name */}
                  <div className="col-lg-9">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        name="firstName"
                        className="form-control"
                        id="firstName"
                        autoComplete="off"
                        placeholder="First Name "
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        tabIndex="7"
                      />
                      <label htmlFor="firstName">
                        First Name <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="middleName"
                        name="middleName"
                        autoComplete="off"
                        placeholder="Middle name"
                        onChange={formik.handleChange}
                        value={formik.values.middleName}
                        tabIndex="8"
                      />
                      <label htmlFor="middleName">Middle Name</label>
                    </div>
                  </div>

                  {/* Last Name */}
                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        autoComplete="off"
                        placeholder=" Last Name "
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        tabIndex="9"
                      />
                      <label htmlFor="lastName">
                        Last Name <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* Mobile */}
                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        name="mobile"
                        maxLength="10"
                        placeholder="Mobile"
                        autoComplete="off"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={formik.handleChange}
                        value={formik.values.mobile}
                        tabIndex="10"
                      />
                      <label htmlFor="mobile">
                        Mobile <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* Email */}
                  <div className="col-lg-6">
                    <div className="d-flex align-items-center">
                      <div className="form-floating w-75 mb-4">
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email"
                          autoComplete="off"
                          onChange={formik.handleChange}
                          value={
                            emailNaState == false ? formik.values.email : ""
                          }
                          tabIndex="11"
                        />
                        <label htmlFor="email">
                          Email {emailNaState == false ? <sup> *</sup> : ""}
                        </label>
                      </div>
                      <div className="form-check w-25 mb-4 ms-3">
                        <input
                          tabIndex="12"
                          className="form-check-input"
                          type="checkbox"
                          id="email_na"
                          name="email_na"
                          defaultChecked={emailNaState == true}
                          onChange={(e) => handleEmailNaChange(e)}
                        />
                        <label
                          className="form-check-label d-block d-sm-inline "
                          htmlFor="email_na"
                        >
                          NA
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* DOB */}
                  <div className="col-lg-3">
                    <div className="form-floating mhc-dob date mb-4">
                      <DatePicker
                        calendarClassName="mhc-input-date"
                        dateFormat="dd/MM/yyyy"
                        selected={DOB}
                        maxDate={new Date()}
                        onChange={(date) => setDOB(date)}
                        calendarStartDay={1}
                        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        openToDate={new Date(oneYearFromNow)}
                        adjustDateOnChange
                        tabIndex="13"
                      />
                      <i className="mhc-icon icon-calendar"></i>
                      <label
                        htmlFor="Dob"
                        className={`${DOB != null ? "dob" : ""}`}
                      >
                        Date of Birth <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* Gender */}
                  <div className="col-lg-3">
                    <div
                      className={`${
                        formik.values.gender === "" ||
                        formik.values.gender === null
                          ? "notselected"
                          : ""
                      } mhc-selectbox mb-4 `}
                    >
                      <select
                        name="gender"
                        id="gender"
                        className="form-select"
                        onChange={formik.handleChange}
                        tabIndex="14"
                        value={formik.values.gender}
                        autoComplete="off"
                      >
                        <option></option>
                        {genderList?.map((item, index) => {
                          return (
                            <option key={index} value={index}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="gender">
                        Gender <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* Relation */}
                  <div className="col-lg-3">
                    <div
                      className={`${
                        formik.values.relation === "" ||
                        formik.values.relation === null
                          ? "notselected"
                          : ""
                      } mhc-selectbox mb-4 `}
                    >
                      <select
                        name="relation"
                        id="relation"
                        className="form-select"
                        onChange={formik.handleChange}
                        tabIndex="15"
                        value={formik.values.relation}
                        autoComplete="off"
                      >
                        <option></option>
                        {relationList?.map((item, i) => {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="relation">
                        Relation <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* Marital Status */}
                  <div className="col-lg-3">
                    <div
                      className={`${
                        formik.values.maritalStatus === "" ||
                        formik.values.maritalStatus === null
                          ? "notselected"
                          : ""
                      } mhc-selectbox mb-4 `}
                    >
                      <select
                        name="maritalStatus"
                        id="maritalStatus"
                        className="form-select"
                        onChange={formik.handleChange}
                        tabIndex="16"
                        value={formik.values.maritalStatus}
                        autoComplete="off"
                      >
                        <option value=""></option>
                        {maritalStatusList?.map((item, index) => {
                          return (
                            <option key={index} value={index}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="maritalStatus">Marital Status</label>
                    </div>
                  </div>

                  {/* Emergency Contact */}
                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="emergencyContact"
                        name="emergencyContact"
                        tabIndex="17"
                        placeholder="Emergency Contact"
                        maxLength="10"
                        autoComplete="off"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={formik.handleChange}
                        value={formik.values.emergencyContact}
                      />
                      <label htmlFor="EmergencyContact">
                        Emergency Contact
                      </label>
                    </div>
                  </div>

                  <div className="col-12">
                    <h1 className="fs-5 mb-3  text-lg-start">Address</h1>
                  </div>

                  {/* A1 */}
                  <div className="col-lg-12">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="addressOne"
                        name="addressOne"
                        autoComplete="off"
                        placeholder="AddressLine"
                        onChange={formik.handleChange}
                        value={formik.values.addressOne}
                        tabIndex="18"
                      />
                      <label htmlFor="AddressLine">
                        Address Line 1 <sup>*</sup>{" "}
                      </label>
                    </div>
                  </div>

                  {/* A2 */}
                  <div className="col-lg-12">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="addressTwo"
                        name="addressTwo"
                        autoComplete="off"
                        placeholder="AddressLine"
                        onChange={formik.handleChange}
                        value={formik.values.addressTwo}
                        tabIndex="19"
                      />
                      <label htmlFor="AddressLine">Address Line 2</label>
                    </div>
                  </div>

                  {/* Country */}
                  <div className="col-lg-6">
                    <div className="mhc-selectbox mb-4">
                      <select
                        name="country"
                        id="country"
                        className="form-select"
                        defaultValue="India"
                        disabled
                        tabIndex="20"
                      >
                        {countries?.map((item) => {
                          const { n } = item;
                          return (
                            <option key={n} value={n}>
                              {n}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="country">
                        Country <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* State */}
                  <div className="col-lg-6">
                    <div
                      className={`${
                        formik.values.stateName === "" ? "notselected" : ""
                      } mhc-selectbox mb-4 `}
                    >
                      <select
                        name="stateName"
                        id="stateName"
                        // required
                        className="form-select"
                        onChange={(e) => {
                          onChangeState(e, "indirect");
                        }}
                        value={formik.values.stateName}
                        autoComplete="off"
                        tabIndex="21"
                      >
                        <option></option>
                        {allState?.map((item) => {
                          return (
                            <option
                              key={item.id}
                              value={item.name}
                              id={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="stateName">
                        State <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* City */}
                  <div className="col-lg-6">
                    <div
                      className={`${
                        formik.values.city === "" ? "notselected" : ""
                      } mhc-selectbox mb-4 `}
                    >
                      <select
                        name="city"
                        id="city"
                        // required
                        className="form-select"
                        onChange={formik.handleChange}
                        value={formik.values.city}
                        autoComplete="off"
                        tabIndex="22"
                      >
                        <option></option>
                        {allCity?.map((item) => {
                          return (
                            <option key={item.name} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="city">
                        City <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  {/* PinCode */}
                  <div className="col-lg-6">
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        name="pincode"
                        id="pincode"
                        placeholder="PinCode"
                        autoComplete="off"
                        maxLength={6}
                        onChange={formik.handleChange}
                        value={formik.values.pincode}
                        tabIndex="24"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      <label htmlFor="pincode">PIN Code</label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div
                      className={`${
                        formik.values.id_type === "" ? "notselected" : ""
                      } mhc-selectbox mb-4 `}
                    >
                      <select
                        name="id_type"
                        id="id_type"
                        className="form-select"
                        // required
                        onChange={formik.handleChange}
                        value={formik.values.id_type}
                        autoComplete="off"
                        tabIndex="25"
                      >
                        <option></option>
                        {idTypeList?.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <label htmlFor="id_type">
                        Identification Document <sup>*</sup>
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-floating mhc-dob date mb-4">
                      <DatePicker
                        name="id_valid_till"
                        id="id_valid_till"
                        calendarClassName="mhc-input-date"
                        dateFormat="dd/MM/yyyy"
                        selected={valid}
                        onChange={(date) => setValid(date)}
                        calendarStartDay={1}
                        minDate={new Date()}
                        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        adjustDateOnChange
                        autoComplete="off"
                        tabIndex="26"
                      />
                      <i className="mhc-icon icon-calendar"></i>
                      <label
                        htmlFor="id_valid_till"
                        className={`${
                          valid === "" || valid === null ? "" : "dob"
                        }`}
                      >
                        Valid Till
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6 inputbrowse">
                    <div className="form-floating mb-4 form-control">
                      <input
                        type="file"
                        id="id_front_img"
                        name="id_front_img"
                        onChange={(e) => browseImage(e, "front")}
                        accept="image/png, image/gif, image/jpeg"
                        tabIndex="27"
                      />
                      <span>Browse</span>
                      <label htmlFor="id_front_img">
                        Upload ID Front Image <sup>*</sup>
                      </label>
                    </div>
                    {frontView ? (
                      <div className="view_upload_img">
                        <img src={frontView} />
                      </div>
                    ) : null}
                  </div>

                  <div className="col-lg-6 inputbrowse">
                    <div className="form-floating mb-4 form-control">
                      <input
                        type="file"
                        id="id_back_img_url"
                        name="id_back_img_url"
                        autoComplete="off"
                        tabIndex="28"
                        onChange={(e) => browseImage(e, "back")}
                        accept="image/png, image/gif, image/jpeg"
                      />
                      <span>Browse</span>
                      <label htmlFor="id_back_img_url">
                        Upload ID Back Image
                      </label>
                    </div>
                    {backView ? (
                      <div className="view_upload_img mb-3">
                        <img src={backView} />
                      </div>
                    ) : null}
                  </div>

                  <div className="col-12 text-center ">
                    <button className="btn btn-primary rounded-1" type="submit">
                      Confirm{" "}
                      {spinnerResponseConfirm == true ? (
                        <SpinnerComponent />
                      ) : null}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>

      <ToastContainer />
    </>
  );
};

export default DashBoardFamilyMember;
