import React, { useEffect, useRef, useState } from "react";
import { apiPATH, APP_SUB_FOLDER } from "../../utils/constant";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import hospital from "../../assets/images/hospital.svg";
import VirtualConsult from "../../assets/images/video-call-_2_.svg";
import { Modal } from "react-bootstrap";
import moment from "moment";
import ImageUploading from "react-images-uploading";
import { Link, useNavigate } from "react-router-dom";
import {
  addNotesDetails,
  addNotesDocuments,
  addNotesImgDelete,
  cancelBookingReason,
  sendFeedBack,
} from "../../actions/dashboard-api";
import { capitalizeFirst } from "../../utils/fliters";
import ReactStars from "react-rating-stars-component";
import { configurations } from "../../actions/get-calls";
import Slider from "react-slick";
import PdfIcon from "../../assets/images/mhc-pdf.svg";
import {
  sakaraClinicUrl,
  showBookConsult,
  showBookTest,
  showOrderMedicine,
} from "../../utils/constant";
import { setcurrentLocationReducer } from "../../redux/slice/current-location";
import { setRescheduleReducer } from "../../redux/slice/reschedule";

const DoctorConsult = ({ dectorConsultData }) => {
  const sliderRef = useRef(null);
  // const [getDectorConsultData, setDectorConsultData] = useState(dectorConsultData)
  const switchToken = useSelector((state) => state.switchToken.value);
  const [modal, setModal] = useState(false);
  const [modalview, setModalview] = useState(false);
  const [viewDetails, setViewDetails] = useState();
  const [viewNoteDetails, setViewNoteDetails] = useState();
  const [notesDocs, setNotesDocs] = React.useState();
  const [feedBack, setFeedBack] = React.useState(false);
  const [feedBackDetails, setFeedBackDetails] = useState();
  const [feedBackCount, setFeedBackCount] = React.useState();
  const [feedBackRemark, setFeedBackRemark] = React.useState();
  const [bookingID, setBookingID] = React.useState();
  const [cancleModal, setCancleModal] = React.useState(false);
  const [cancelReason, setCancelReason] = React.useState();
  const [cancelReasonValue, setCancelReasonValue] = React.useState();
  const [cancelText, setCancelText] = React.useState();
  const [cancelBookingID, setCancelBookingID] = React.useState();
  const [reschedule, setReschedule] = React.useState(false);
  const [notes, setNotes] = useState();
  const [addNotesModal, setAddNotesModal] = useState(false);
  const [addNotesSlider, setAddNotesSlider] = useState();
  const [slideNumber, setSlideNumber] = useState(0);

  const currentLocation = useSelector((state) => state.currentLocation.value);
  const hospitalAddressList = useSelector(
    (state) => state.hospitalAddressList.value
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const maxNumber = 12;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    initialSlide: slideNumber,
  };
  const handleClose = () => {
    setModal(false);
  };
  const handleviewShow = () => {
    setModalview(true);
  };
  const handleviewClose = () => {
    setModalview(false);
  };
  const handleFeedBackCLose = () => {
    setFeedBack(false);
  };
  const handleCalcleClose = () => {
    setCancleModal(false);
  };
  const handleResClose = () => {
    setReschedule(false);
  };
  const handleViewDetails = (e, itemData) => {
    let data = itemData;
    setModalview(true);
    setViewDetails(data);
    // setNotesDocs()
  };

  const handleViewDeatilsAddNote = (e, details) => {
    setNotesDocs([]);
    setViewNoteDetails(details);
    let imgID = details.id;

    addNotesDetails(imgID, switchToken).then(function (response) {
      const imagesList = response.data.patient_docments?.map((item) => {
        return { data_url: item.file, pic_id: item.id };
      });

      setNotesDocs(imagesList);
    });

    setNotes(
      details.notes !== null && details.notes !== "null" ? details.notes : ""
    );
    setModal(true);
  };

  const onHandleImageRemove = (id) => {
    addNotesImgDelete(id, switchToken).then(function (response) {
      return showToast(capitalizeFirst(response.data.message));
    });
  };

  const onChange = (imageList) => {
    setNotesDocs(imageList);
  };

  const uploadError = (errors) => {
    if (errors) {
      return showError("Maximum " + maxNumber + " images are allowed.");
    }
  };

  const HandleInput = (e) => {
    setNotes(e.target.value);
  };

  const addNoteChangeHandler = () => {
    if ((notes == "" || notes == null) && notesDocs.length == 0) {
      showError("Please enter notes or upload document");
      return false;
    }
    var form_data = new FormData();
    form_data.append("note_to_doctor", notes);
    notesDocs.map((item) => {
      if (item.file != undefined) {
        form_data.append("file_upload[]", item.file);
      }
    });

    addNotesDocuments(form_data, viewNoteDetails.id, switchToken)
      .then(function (response) {
        if (response.statusText == "OK") {
          let data = [...dectorConsultData];
          //   data.filter(x=>x.id=== viewNoteDetails.id)[0].documents=;
          data.filter((x) => x.id === viewNoteDetails.id)[0].notes =
            response.data.patient_note_doctor;
          let index = data.findIndex((x) => x.id === viewNoteDetails.id);
          data[index].notes = response.data.patient_note_doctor;
          //   setDectorConsultData(data)
          setModal(false);
          return showToast("Add Notes/ Documents updated successfully");
        } else {
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const openUrl = (url) => {
    const urlPath = apiPATH + url;
    window.open(urlPath, "_self");
  };

  const openPDF = (url) => {
    window.open(url, "_blank");
  };
  const ratingChanged = (newRating) => {
    setFeedBackCount(newRating);
  };
  const textAreaOnchange = (e) => {
    setFeedBackRemark(e.target.value);
  };

  const getFeedBack = (data, booking_id) => {
    let feedData = data;
    setFeedBack(true);
    setBookingID(booking_id);
    setFeedBackDetails(feedData);
  };

  const postFeedBack = () => {
    const payload = {
      hospital_group_id: 1,
      group_id: 1,
      booking_id: bookingID,
      state: 2,
      patient_remarks: feedBackRemark,
      patient_rating: feedBackCount,
    };
    sendFeedBack(payload, switchToken)
      .then(function (response) {
        if (response.statusText == "OK") {
          setFeedBack(false);
          // feedback_btn
          let getEle = document.querySelector("#id_" + bookingID);
          let feedback_btn = getEle.querySelector(".feedback_btn");
          feedback_btn.style.display = "none";

          return showToast("Feedback Sent Successfully ");
        } else {
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const handleReasonOnchange = (e) => {
    setCancelReasonValue(e.target.value);
  };
  const handleReasonTest = (e) => {
    setCancelText(e.target.value);
  };

  const handleCancelAppotinment = (bookingid) => {
    configurations().then(function (response) {
      setCancelReason(response.data.cancellation_reasons);
    });
    setCancleModal(true);
    setCancelBookingID(bookingid);
  };

  const postHandleCancelReason = () => {
    const payload = {
      hospital_group_id: 1,
      group_id: 1,
      booking_id: cancelBookingID,
      cancellation_reason: cancelReasonValue,
      other_reason: cancelText,
    };
    cancelBookingReason(payload, switchToken)
      .then(function (response) {
        if (response.statusText === "OK") {
          setCancleModal(false);
          let getEle = document.querySelector("#id_" + cancelBookingID);
          let ctaCancle = getEle.querySelector(".cta_cancel");
          let txtStatus = getEle.querySelector(".booking_status");
          let rescheduleBtn = getEle.querySelector(".cta_reschedule");
          let PayOpd = getEle.querySelector(".cta_Pay_opd");
          let PayVirtual = getEle.querySelector(".cta_Pay_virtual");

          ctaCancle.style.display = "none";
          rescheduleBtn.style.display = "none";
          if (PayOpd) {
            PayOpd.style.display = "none";
          }
          if (PayVirtual) {
            PayVirtual.style.display = "none";
          }
          // txtStatus.classList.remove("d-none");
          txtStatus.classList.add("text-danger");
          txtStatus.innerText = "Cancelled ";

          return showToast(response.data.message);
        }

        if (response.statusText === "Bad Request") {
          setCancleModal(false);
          return showError(response.data.errors[0].message);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const closeAddNotesSlider = () => {
    setAddNotesModal(false);
    setModal(true);
  };

  const handleAddNotesSlider = (imagesPath, index) => {
    setAddNotesModal(true);
    setAddNotesSlider(imagesPath);
    setSlideNumber(index);
    setModal(false);
  };
  useEffect(() => {
    if (sliderRef.current?.slickGoTo) sliderRef.current.slickGoTo(slideNumber);
  }, [slideNumber]);

  const handleOpenReschedule = (item) => {
    if (item.payment_status == "pay on arrival") {
      setReschedule(true);
    }

    if (item.payment_status == "success") {
      var index = hospitalAddressList
        ?.map(function (obj) {
          return obj.hospitalID;
        })
        .indexOf(item.hospital.id);
      var specInd = item.doctor.specialities_slug
        .map(function (obj) {
          return obj.hospitalID;
        })
        .indexOf(item.hospital.id);
      dispatch(setcurrentLocationReducer(hospitalAddressList[index]));
      dispatch(setRescheduleReducer(item));
      navigate(
        `/book-consult/${item.doctor.specialities_slug[0].slug}/${item.doctor.slug}/${item.hospital.id}/${item.doctor.specialities_slug[0].id}/${item.doctor.id}/${item.consult_type}?reschedule=true`
      );
    }
  };

  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }

  function showToast(str) {
    toast.success(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }

  return (
    <React.Fragment>
      {dectorConsultData.map((item, i) => {
        let date = moment(item.booking_date).format("DD MMM, YYYY");
        let day = moment(item.booking_date).format("ddd");
        let time = moment(item.booking_date).format("LT");
        const profileImg = item.doctor.profile_image;
        let bookDate = moment(item.booking_date).unix();
        let expirebooking = moment(item.booking_date)
          .subtract(moment.duration({ hours: 6 }))
          .unix();
        let expirereschedule = moment(item.booking_date)
          .subtract(moment.duration({ hours: 1 }))
          .unix();
        let expirerePayNow = moment(item.booking_date)
          .add(moment.duration({ hours: 1 }))
          .unix();
        let CurrentDate = moment().unix();

        // let CurrentDate = moment().subtract(1,'days').unix();
        // let CurrentDate = moment(moment().subtract(moment.duration({hours: 6})).format('ddd, D MMM, YYYY h:mm A')).unix();

        return (
          <div
            id={`id_` + item.id}
            className="mhc-patient-details-card mhc-booking"
            key={i + 1}
          >
            <div className="row">
              <div className="col-md-12 col-lg-4 order-3 order-lg-1 mt-3 mt-lg-0">
                <p className="mb-1 text-primary fw-bold">
                  {day}, {date} | {time}{" "}
                </p>
                <p className="mb-0 tittle ">
                  {item.patient_prescription.patient_name}
                </p>
                <p className="mb-0">
                  <small>{item.booking_code}</small>
                </p>
              </div>
              <div className="col-8 col-sm-8 col-md-8 col-lg-5 order-1 order-lg-2">
                <div className="d-flex align-items-start align-items-md-top">
                  <div className="dctor-img">
                    <img src={profileImg} alt="dectorProfile" />
                  </div>
                  <div className="text-wrap">
                    {" "}
                    <p className="mb-1 tittle ">{item.doctor.name}</p>
                    {item.doctor.specialities.map((spc, spcindex) => (
                      <span className="mb-0 me-2" key={spcindex}>
                        {spc}
                      </span>
                    ))}
                    <p>{item.hospital.name}</p>
                  </div>
                </div>
              </div>
              <div className="col-4 col-sm-4 col-md-4 col-lg-3 order-2 order-lg-3 text-end">
                <p
                  className={`mb-1 fw-bolder booking_status ${
                    item.booking_status === "Upcoming" ? "d-none1" : ""
                  }  ${
                    item.booking_status === "Pending" ||
                    item.booking_status === "PENDING"
                      ? "text-primary"
                      : item.booking_status === "Canceled" ||
                        item.booking_status === "CANCELLED"
                      ? "text-danger"
                      : item.booking_status === "Missed" ||
                        item.booking_status === "MISSED"
                      ? "text-missed"
                      : "text-success"
                  }`}
                >
                  {item.booking_status
                    .replace("Canceled", "Cancelled")
                    .replace("Arrived", "Paid")
                    .replace(
                      "Upcoming",
                      item.payment_status == "pay on arrival"
                        ? "Pay on arrival"
                        : item.consult_type_name == "OPD Consult"
                        ? "Confirmed"
                        : "Paid"
                    )}{" "}
                </p>
                <p className="mb-0 ">INR {item.amount}</p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-8 order-1 order-lg-0">
                {item.actions.map((actItem, actindex) => {
                  return (
                    <span key={actindex}>
                      {
                        /* For PAy Now for Virtual Consult */
                        actItem.name == "Pay Now" &&
                        item.payment_status != "success" &&
                        item.consult_type == 1 &&
                        bookDate >= CurrentDate ? (
                          <button
                            onClick={() => openUrl(actItem.uri)}
                            className="btn btn-outline btn-outline-secondary cta_Pay_virtual   py-0 mt-2 rounded-1 me-2"
                          >
                            {actItem.name}
                          </button>
                        ) : null
                      }

                      {
                        /* For PAy Now for OPD Consult */
                        actItem.name == "Pay Now" &&
                        item.payment_status != "success" &&
                        item.booking_status != "Canceled" &&
                        item.consult_type == 2 &&
                        expirerePayNow >= CurrentDate ? (
                          <button
                            onClick={() => openUrl(actItem.uri)}
                            className="btn btn-outline btn-outline-secondary cta_Pay_opd  py-0 mt-2 rounded-1 me-2"
                          >
                            {actItem.name}{" "}
                          </button>
                        ) : null
                      }

                      {
                        /* For Cancel */
                        actItem.name == "Cancel" &&
                        item.booking_status == "Upcoming" &&
                        expirebooking >= CurrentDate ? (
                          <button
                            onClick={() => handleCancelAppotinment(item.id)}
                            className="btn btn-outline cta_cancel btn-outline-secondary  py-0 mt-2 rounded-1 me-2"
                          >
                            {actItem.name}
                          </button>
                        ) : null
                        // onClick={() => openUrl(actItem.uri)}
                      }

                      {
                        /* For Reschedule */
                        actItem.name == "Reschedule" &&
                        bookDate >= CurrentDate &&
                        item.booking_status == "Upcoming" &&
                        expirereschedule >= CurrentDate &&
                        (item.payment_status == "pay on arrival" ||
                          item.payment_status == "success") ? (
                          <button
                            onClick={(e) => handleOpenReschedule(item)}
                            className="btn btn-outline btn-outline-secondary cta_reschedule  py-0 mt-2 rounded-1 me-2 "
                          >
                            Reschedule
                          </button>
                        ) : (
                          ""
                        )
                      }

                      {/* 
                                                {(item.booking_status == "Upcoming" || item.booking_status == "Pending") &&  (actItem.name != 'Call Doctor' &&  actItem.name != 'Reschedule' && actItem.name != 'Cancel' && (actItem.name == 'Pay Now' && item.payment_status != "success" && bookDate >= CurrentDate)) 
                                                
                                                ? 
                                                <button onClick={() => openUrl(actItem.uri)} className="btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2">{actItem.name}</button>
                                                : ''}

                                                {
                                                    (item.booking_status == "Upcoming" ) && actItem.name == 'Cancel' && expirebooking >= CurrentDate ? <button onClick={() => handleCancelAppotinment(item.id)} className="btn btn-outline btn-outline-secondary py-0 mt-2 rounded-1 me-2 cta_cancel">Cancel</button> : ''
                                                }
                                                 */}
                    </span>
                  );
                })}
                {item.booking_status != "Canceled" &&
                item.booking_status != "Completed" ? (
                  <button
                    onClick={(e) => handleViewDeatilsAddNote(e, item)}
                    className="btn btn-outline btn-outline-secondary py-0 mt-2 rounded-1 me-2"
                  >
                    Add Notes/ Documents
                  </button>
                ) : (
                  ""
                )}

                {item.booking_status == "Canceled" ||
                item.booking_status == "Completed" ? (
                  <button
                    onClick={(e) => handleViewDetails(e, item)}
                    className="btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2 "
                  >
                    View Details
                  </button>
                ) : (
                  ""
                )}

                {item.booking_status != "Canceled" &&
                item.booking_status != "Missed" &&
                item.consult_type_name == "OPD Consult" &&
                item.patient_prescription.upload_prescription != "" &&
                item.patient_prescription.upload_prescription != null ? (
                  <button
                    onClick={() =>
                      openPDF(item.patient_prescription.upload_prescription)
                    }
                    className="btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2 "
                  >
                    View Prescription
                  </button>
                ) : (
                  ""
                )}

                {item.invoice_download != "" ? (
                  <button
                    onClick={() => openPDF(item.invoice_download)}
                    className="btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2 "
                  >
                    Invoice
                  </button>
                ) : (
                  ""
                )}

                {item.booking_status == "Completed" &&
                item.feedback_submitted == false ? (
                  <button
                    onClick={(e) => getFeedBack(item, item.id)}
                    className="btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2 feedback_btn"
                  >
                    Feedback
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-4 order-0 order-lg-1 text-start text-lg-end mt-2">
                {item.consult_type == 1 ? (
                  <img
                    className="hospital-icon"
                    src={`${APP_SUB_FOLDER}${VirtualConsult}`}
                    alt="VirtualConsult"
                  />
                ) : (
                  <img
                    className="hospital-icon"
                    src={`${APP_SUB_FOLDER}${hospital}`}
                    alt="hospital"
                  />
                )}

                <span className="text-primary ms-2">
                  {item.consult_type_name}
                </span>
              </div>
            </div>
          </div>
        );
      })}

      <Modal show={modal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Add Notes/ Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {viewNoteDetails !== undefined ? (
            <>
              <div className="mhc-booking ">
                <div className="d-flex align-items-start align-items-md-center">
                  <div className="dctor-img">
                    <img
                      src={viewNoteDetails.doctor.profile_image}
                      alt="dectorProfile"
                    />
                  </div>
                  <div>
                    {" "}
                    <p className="mb-1 tittle ">
                      {viewNoteDetails.doctor.name}
                    </p>
                    <p className="mb-0 ">{viewNoteDetails.hospital.name}</p>
                  </div>
                </div>
              </div>
              <div className="patient-details  my-4">
                <div className="content">
                  <p className="text-primary fs-13 mb-0">Booked For</p>
                  <p className="mb-0">{viewNoteDetails.patient_name}</p>
                </div>
                <div className="content">
                  <p className="text-primary fs-13 mb-0">Booking Code</p>
                  <p className="mb-0">{viewNoteDetails.booking_code}</p>
                </div>
                <div className="content">
                  <p className="text-primary fs-13 mb-0">Appointment Type</p>
                  <p className="mb-0">
                    {viewNoteDetails.consult_type == 1 ? (
                      <img
                        className="hospital-icon"
                        src={`${APP_SUB_FOLDER}${VirtualConsult}`}
                        alt="VirtualConsult"
                      />
                    ) : (
                      <img
                        className="hospital-icon"
                        src={hospital}
                        alt="hospital"
                      />
                    )}

                    <span className="ms-2">
                      {viewNoteDetails.consult_type_name}
                    </span>
                  </p>
                </div>
                <div className="content">
                  <p className="text-primary fs-13 mb-0">Date and Time</p>
                  <p className="mb-0">
                    {moment(viewNoteDetails.booking_date).format("ddd")}{" "}
                    {moment(viewNoteDetails.booking_date).format(
                      "DD MMM, YYYY"
                    )}{" "}
                    | {moment(viewNoteDetails.booking_date).format("LT")}
                  </p>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <p className="mb-2">
            Please upload any documents that you would like to share with the
            doctor prior to your appointment.
          </p>
          <h4 className="fs-6">Notes for Doctor: </h4>
          <div className="row">
            <div className="col-12">
              <textarea
                name="notes"
                defaultValue={notes}
                onChange={(e) => HandleInput(e)}
                className="form-text-area"
              ></textarea>

              {/* <input type="text" className="form-control" name="notes" value={notes} onChange={e => HandleInput(e)}  />  */}
            </div>
          </div>
          <div className="mt-4">
            <div className="mhc-multiImage">
              <ImageUploading
                multiple
                name="file_upload[]"
                value={notesDocs}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={["jpg", "gif", "png", "pdf"]}
                allowNonImageType={true}
                onError={uploadError}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <>
                    <div className="upload__image-wrapper">
                      <div className="d-flex flex-wrap">
                        {imageList.map((image, index) => (
                          <div className="position-relative" key={index + 1}>
                            <div
                              key={index}
                              className="image-item"
                              onClick={(e) =>
                                handleAddNotesSlider(imageList, index)
                              }
                            >
                              {/* <img src={image["data_url"].split('.').slice(-1)[0] == "pdf"? PdfIcon: image["data_url"]} alt={image.pic_id} width="100"  /> */}
                              <embed
                                type=""
                                src={
                                  image["data_url"].split(".").slice(-1)[0] ==
                                  "pdf"
                                    ? PdfIcon
                                    : image["data_url"]
                                }
                                alt={image.pic_id}
                                width="100"
                              />
                               <button
                                onClick={() => {
                                  onImageRemove(index);
                                  onHandleImageRemove(image.pic_id);
                                }}
                                className="close"
                              ></button>
                            </div>
                            
                          </div>
                        ))}

                        <button
                          className="btn mhc-add-more"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={(e) => {
                            onImageUpload(e);
                          }}
                          disabled={imageList.length == maxNumber}
                          {...dragProps}
                        >
                          <br /> Add More
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </ImageUploading>
            </div>

            <button
              className="btn btn-custom rounded-1   mt-4"
              onClick={() => addNoteChangeHandler()}
            >
              Upload Document
            </button>
          </div>
          <h1 className="fs-5 text-secondary  mt-4">Instructions</h1>

          {
            //For Virtual Consult
            viewNoteDetails?.consult_type == 1 ? (
              <ul className="mhc-instruction-list">
                <li>
                  Keep your Sakra Patient App active 15 minutes before your
                  appointment time.
                </li>
                <li>
                  The doctor will call you on your Sakra Patient app at the time
                  of your appointment. Please do allow for a delay in case the
                  doctor is attending to an emergency.
                </li>
                <li>
                  In case you have booked an appointment for your family member,
                  please ensure that they are using the Sakra Patient App and
                  log in with your registered mobile number.
                </li>
                <li>
                  You can cancel your appointment only up to 6 hour prior to
                  your appointment time. Please contact the hospital for
                  appointment cancellation at{" "}
                  <a
                    href={`mailto:${currentLocation?.email}`}
                    className="text-primary"
                  >
                    {currentLocation?.email}
                  </a>{" "}
                  or{" "}
                  <a
                    href={`tel:${currentLocation?.tel}`}
                    className="text-primary"
                  >
                    {currentLocation?.tfn}
                  </a>
                </li>
                <li>Registration charges are Non-refundable.</li>
              </ul>
            ) : (
              <ul className="mhc-instruction-list">
                <li>
                  Please be at the hospital 30 mins prior to your appointment
                  time.
                </li>
                <li>
                  Please do allow for a delay in case the doctor is attending to
                  an emergency.
                </li>
                <li>
                  You can cancel your appointment only up to 6 hour prior to
                  your appointment time. Please contact the hospital for
                  appointment cancellation at{" "}
                  <a
                    href={`mailto:${currentLocation?.email}`}
                    className="text-primary"
                  >
                    {currentLocation?.email}
                  </a>{" "}
                  or{" "}
                  <a
                    href={`tel:${currentLocation?.tel}`}
                    className="text-primary"
                  >
                    {currentLocation?.tfn}
                  </a>
                </li>
                <li>Registration charges are Non-refundable.</li>
              </ul>
            )
          }
        </Modal.Body>
      </Modal>

      <Modal show={modalview} onHide={handleviewClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">View Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {viewDetails !== undefined ? (
            <>
              <div className="mhc-booking ">
                <div className="d-flex align-items-start align-items-md-center">
                  <div className="dctor-img">
                    <img
                      src={viewDetails.doctor.profile_image}
                      alt="dectorProfile"
                    />
                  </div>
                  <div>
                    {" "}
                    <p className="mb-1 tittle ">{viewDetails.doctor.name}</p>
                    <p className="mb-0 ">{viewDetails.hospital.name}</p>
                  </div>
                </div>
              </div>
              <div className="patient-details  my-4">
                <div className="content">
                  <p className="text-primary fs-13 mb-0">Booked For</p>
                  <p className="mb-0">{viewDetails.patient_name}</p>
                </div>
                <div className="content">
                  <p className="text-primary fs-13 mb-0">Booking Code</p>
                  <p className="mb-0">{viewDetails.booking_code}</p>
                </div>
                <div className="content">
                  <p className="text-primary fs-13 mb-0">Appointment Type</p>
                  <p className="mb-0">
                    {viewDetails.consult_type == 1 ? (
                      <img
                        className="hospital-icon"
                        src={`${APP_SUB_FOLDER}${VirtualConsult}`}
                        alt="VirtualConsult"
                      />
                    ) : (
                      <img
                        className="hospital-icon"
                        src={`${APP_SUB_FOLDER}${hospital}`}
                        alt="hospital"
                      />
                    )}

                    <span className="ms-2">
                      {viewDetails.consult_type_name}
                    </span>
                  </p>
                </div>
                <div className="content">
                  <p className="text-primary fs-13 mb-0">Date and Time</p>
                  <p className="mb-0">
                    {moment(viewDetails.booking_date).format("ddd")}{" "}
                    {moment(viewDetails.booking_date).format("DD MMM, YYYY")} |{" "}
                    {moment(viewDetails.booking_date).format("LT")}
                  </p>
                </div>
              </div>
              {/* <p className="mb-2">Please upload any documents that you would like to share with the doctor prior to your appointment.</p> */}
              <h4 className="fs-6">Notes for Doctor: </h4>
              <div className="row">
                <div className="col-12">
                  <textarea
                    className="form-control text-secondary"
                    defaultValue={viewDetails.notes}
                    disabled
                  ></textarea>
                </div>
              </div>

              {viewDetails.documents.length > 0 ? (
                <div className="multiview mt-4">
                  {viewDetails.documents.map((imgItem, index) => {
                    return (
                      <div className="image-item" key={index}>
                        {/* <img src={imgItem} alt="" />  */}
                        <img src={imgItem} className="image-item" />
                        {/* <button type="Submit" className="btn close"><span className="mhc-icon icon-plus"></span></button> */}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </>
          ) : null}
        </Modal.Body>
      </Modal>
      <Modal show={feedBack} onHide={handleFeedBackCLose} size="md">
        <Modal.Body>
          <h3 className="text-secondary fs-7">Please rate Your experience</h3>
          <h3 className="text-secondary fs-7">
            {feedBackDetails?.doctor.name}
          </h3>
          <p className="text-secondary">
            {feedBackDetails?.doctor.specialities.map((spc, spcindex) => (
              <span className="mb-0 me-2" key={spcindex}>
                {spc}
              </span>
            ))}
          </p>
          <div className="d-flex align-items-center">
            <div className="feedback-minwidth">
              <span className="text-secondary">Appointment</span>
            </div>
            <div className="ms-4">
              <p className="mb-0 text-primary fw-bold">
                {feedBackDetails?.booking_date}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center mt-3">
            <div className="feedback-minwidth">
              <span className="text-secondary">Type</span>
            </div>
            <div className="ms-4">
              <p className="mb-0 text-primary d-flex fw-bold">
                {feedBackDetails?.consult_type == 1 ? (
                  <img
                    className="hospital-icon"
                    src={`${APP_SUB_FOLDER}${VirtualConsult}`}
                    alt="VirtualConsult"
                  />
                ) : (
                  <img
                    className="hospital-icon"
                    src={`${APP_SUB_FOLDER}${hospital}`}
                    alt="hospital"
                  />
                )}

                <span className="ms-1">
                  {feedBackDetails?.consult_type_name}
                </span>
              </p>
            </div>
          </div>
          <div className="border-bottom border-light w-100 transition mt-3 mt-lg-4"></div>

          <div className="d-flex justify-content-center">
            <ReactStars
              count={5}
              onChange={ratingChanged}
              size={35}
              isHalf={true}
              emptyIcon={<i className="mhc-icon icon-star-empty"></i>}
              halfIcon={<i className="mhc-icon icon-star-half"></i>}
              fullIcon={<i className="mhc-icon icon-star"></i>}
              activeColor="#f37b89"
            />
          </div>
          <div className="form-floating mb-4 mt-2">
            <textarea
              type="text"
              className="form-control"
              placeholder="Remarks"
              value={feedBackRemark}
              onChange={(e) => textAreaOnchange(e)}
            />
            <label htmlFor="first_name">
              Remarks <sup>*</sup>
            </label>
          </div>
          <div className="w-100 text-center">
            <button
              className="btn btn-outline btn-outline-secondary  rounded-1 me-3 "
              onClick={handleFeedBackCLose}
            >
              Not Now
            </button>
            <button
              type="submit"
              onClick={postFeedBack}
              className="btn btn-custom rounded-1  "
            >
              Submit{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={cancleModal} onHide={handleCalcleClose} size="md">
        <Modal.Body>
          <h3 className="text-secondary fs-7">
            Please let us know reason for cancellation
          </h3>
          <ul className="list-unstyled p-0">
            {cancelReason?.map((reason) => (
              <li>
                <div className="form-check my-3">
                  <input
                    className="form-check-input"
                    value={reason.id}
                    onChange={(e) => handleReasonOnchange(e)}
                    type="radio"
                    name="flexRadioDefault"
                    id={reason.id}
                  />
                  <label
                    className="form-check-label d-block d-sm-inline"
                    for={reason.id}
                  >
                    {reason.name}
                  </label>
                </div>
              </li>
            ))}
          </ul>
          {cancelReasonValue == 9 ? (
            <div className="form-floating mb-4 mt-4">
              <input
                type="text"
                className="form-control"
                placeholder="Reason"
                onChange={(e) => handleReasonTest(e)}
              />
              <label htmlFor="first_name">
                Reason <sup>*</sup>
              </label>
            </div>
          ) : (
            ""
          )}

          <div className="w-100 text-center">
            <button
              className="btn btn-outline btn-outline-secondary  rounded-1 me-3 "
              onClick={handleCalcleClose}
            >
              Dismiss
            </button>
            <button
              type="submit"
              onClick={postHandleCancelReason}
              className="btn btn-custom rounded-1  "
            >
              Cancel Appointment{" "}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={reschedule} onHide={handleResClose} size="md">
        <Modal.Body>
          <h3 className="text-secondary text-center fs-7">Sakra Self-Help</h3>
          <p className="text-secondary text-center">
            For Pay On Arrival Booking, please contact the hospital to
            reschedule
          </p>
          <div className="w-100 text-center">
            <button
              className="btn btn-outline btn-outline-secondary  rounded-1 me-3 "
              onClick={handleResClose}
            >
              Ok
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={addNotesModal}
        onHide={closeAddNotesSlider}
        className="mhc-imgPopup"
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Slider ref={sliderRef} {...settings}>
            {addNotesSlider === undefined
              ? ""
              : addNotesSlider.map((itemm) => (
                  <div>
                    <embed src={itemm.data_url} className="popup-img" />
                  </div>
                ))}
          </Slider>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DoctorConsult;
