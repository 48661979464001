import React from "react";

const DaysFileter =({getDatFiltterDay, filterDays, id, name})=>{  
    return(
        <ul className="list-unstyled mb-0 mt-4 mt-lg-0 d-flex gap-3 align-items-center">
        <li><span className="fw-bolder">Last</span></li>
        <li>
       <div className="form-check mt-1">
        <input
        className="form-check-input"
        type="radio"
        name={`filterDay${name}`}
        id={`30Days${id}`}
        value="30"
        checked={filterDays === 30}
        onChange={(e) => getDatFiltterDay(e)}
        />
        <label className="form-check-label" htmlFor={`30Days${id}`}>30 Days</label>
        </div>
        </li>
        <li>
        <div className="form-check mt-1">
        <input
        className="form-check-input"
        type="radio"
        name={`filterDay${name}`}
        id={`60Days${id}`}
        value="60"
        checked={filterDays === 60 }
        onChange={(e) => getDatFiltterDay(e)}
        />
        <label className="form-check-label" htmlFor={`60Days${id}`}>60 Days</label>
        </div>
        </li>
        <li>
        <div className="form-check mt-1">
        <input
        className="form-check-input"
        type="radio"
        name={`filterDay${name}`}
        id={`180Days${id}`}
        value="180"
        checked={filterDays === 180}
        onChange={(e) => getDatFiltterDay(e)}
        />
        <label className="form-check-label" htmlFor={`180Days${id}`}>180 Days</label>
        </div>
        </li>

</ul>
    )
}

export default DaysFileter