import React from "react";
const Iprops = {
    className: "",
  
  };
export default function SpinnerComponent (props=Iprops){
    return(
        <>
        <span className={`spinner ${props.className}`}><span class="bounce1"></span><span class="bounce2"></span><span class="bounce3"></span></span>
        </>
    )
}