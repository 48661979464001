import React, { useState, useEffect } from "react";
import { Container, Accordion, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import LoginUser from '../../../component/login-register/login'
import PatientDetails from '../../../component/login-register/patientDetails'
import FamilyMember from '../../../component/family-member/familyMember'
import SelectDateTime from '../../../component/select-date-time/selectDateTime'
import {  checkBookingStatus, checkBookingStatusPackage, checkBookingStatusRemoveCopun, getTimeslotByHospital, testDetails, thyrocareDetail } from "../../../actions/get-calls";
import NewUserRegister from "../../../component/login-register/register";
import VerifyRegister from "../../../component/login-register/VerifyRegister";
import { APP_SUB_FOLDER, apiURL, calculateAge, currentTimeConvertIntoAM_PM, dateFormatIntoYYYYMMDD, payLaterLink, timeConvertIntoAM_PM } from "../../../utils/constant";
import { setSelectedMembersReducer } from "../../../redux/slice/selected-members";
import { setSelectedMemberIdReducer } from "../../../redux/slice/selected-member-id";
import { clearPackageDetailReducer } from "../../../redux/slice/package-detail";
import { addWallet, applyCoupon, orderPackage, payLater, removeCoupon, removeWallet } from "../../../actions/post-call";
import { setBookingSourceReducer } from "../../../redux/slice/booking-source";
import BreadcrumbWithUrl from "../../../component/breadcrumb/breadcrumb-with-url";
import { clearVendorReducer, setVendorReducer } from "../../../redux/slice/vendor";
import { setPackageOrderHistory } from "../../../redux/slice/package-order-id";

const PackageBooking = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const vendorId = useSelector((state) => state.vendor.value);
	const locationData = useSelector((state) => state.selectedPincode.value);
	const callToken = useSelector((state) => state.callToken.value);
	const switchToken = useSelector((state) => state.switchToken.value);

	const selectedUser = useSelector((state) => state.selectedUser.value);
	const _members = useSelector((state) => state.selectedMembers.value);

	const _memberId = useSelector((state) => state.selectedMemberId.value);
	const packageDetail = useSelector((state) => state.packageDetail.value);
	const bookingSource = useSelector((state) => state.bookingSource.value);
	const packageOrderHistory = useSelector((state) => state.packageOrderHistory?.value);
	const [registered, setRegistered] = useState(true);
	const [verifyOTP, setVerifyOTP] = useState(false);
	const [mobile, setMobile] = useState('');
	const [otpVerified, setOtpVerified] = useState(false);

	const [modal, setModal] = useState(false);

	const [currentDeliveryDetail, setCurrentDeliveryDetail] = useState(null);
	const [isDeliveryDetailAdded, setIsDeliveryDetailAdded] = useState(false);
	const [memberSelect, setMemberSelect] = useState(null);
	let today = new Date();
	today = today.setDate(vendorId == 4? today.getDate() + 2 : today.getDate());
	const [selectedDay, setSelectedDay] = useState(today);
	
	const [timeSlots, setTimeSlots] = useState(null);
	const [isTimeSlotLoading, setIsTimeSlotLoading] = useState(false);
	const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

	const [profileSelect, setProfileSelect] = useState(false);
	const [members, setMembers] = useState(selectedUser);

	const [bookingResponse, setBookingResponse] = useState(null);
	const [currentCityId, setCurrentCityId] = useState(null);
	const [isPackageSaved, setIsPackageSaved] = useState(false);

	const [packageTotalOrderAmount, setPackageTotalOrderAmount] = useState(0);
	const [packageDetailPayload, setPackageDetailPayload] = useState(null);
	const [orderDateTime, setOrderDateTime] = useState(null);

	const [isTermRead, setIsTermRead] = useState(false);
	const [payUsingWallet, setPayUsingWallet] = useState(false);
	const [couponCode, setCouponCode] = useState('');
	const [couponData, setCouponData] = useState(null);
	const [showPackageDetail, setShowPackageDetail] = useState(false);
	const [infoPackage, setPackageDetail] = useState(null);
	const [toBePaidAmount, setToBePaidAmount]=useState()
	const [forWallet, setForBEWallet] = useState(0);


	let JWTresponse =getJWTtoken();
	const checkingToken = checkToken();

	useEffect(() => {
		let currentBookingSource = window.location.pathname.split('/').pop();
		if (bookingSource) {
			if (bookingSource !== currentBookingSource) {
				dispatch(setSelectedMembersReducer(null));
				dispatch(setSelectedMemberIdReducer(null));
				dispatch(setBookingSourceReducer(currentBookingSource));
			}
		} else {
			dispatch(setBookingSourceReducer(currentBookingSource));
		}
	}, [bookingSource])

	useEffect(() =>{
		if (members?.length == 0 && packageDetail) {
			let mElement = selectedUser;
			let finalPackage;
			let packagePayload = {}
			packagePayload = {
				...packageDetail,
				name: packageDetail.name,
				test_code: packageDetail.product_code,
				unit_price: packageDetail.rates.pay_amt,
				net_amount: packageDetail.rates.pay_amt,
				test_id: packageDetail.test_id,
				diagnostic_id: packageDetail.diagnostic_id,
				pincode: packageDetail.pincode
			}
			finalPackage = { packages: [{ ...packagePayload }] };
			mElement = { ...mElement, ...finalPackage };
			dispatch(setSelectedMembersReducer([mElement]));
		}

	}, [])

	

	useEffect(async () => {
		let currentBookingSource = window.location.pathname.split('/').pop();
		if (bookingSource === currentBookingSource) {
			
			if (_members && !isPackageSaved) {
				
				if (packageDetail) {
					let packagePayload = {}, tempMember = [];

					packagePayload = {
						...packageDetail,
						name: packageDetail.name,
						test_code: packageDetail.product_code,
						unit_price: packageDetail.rates.pay_amt,
						net_amount: packageDetail.rates.pay_amt,
						test_id: packageDetail.test_id,
						diagnostic_id: packageDetail.diagnostic_id,
						pincode: packageDetail.pincode
					}

					for (let index = 0; index < _members.length; index++) {
						let mElement = _members[index];

						if (mElement.id === _memberId) {
							if (mElement.packages.length === 0) {
								let finalPackage;
								finalPackage = { packages: [{ ...packagePayload }] };
								mElement = { ...mElement, ...finalPackage };
							} else {
								if (mElement.packages.findIndex(p => p.test_id === packageDetail.test_id) < 0) {
									let finalPackage, oldPackage = mElement.packages;
									oldPackage = [...oldPackage, { ...packagePayload }];
									finalPackage = { packages: [...oldPackage] };
									mElement = { ...mElement, ...finalPackage };
								}
							}
						}
						tempMember.push(mElement);
					}

					if (tempMember) {
						let finalTempMember = [];
						setPackageTotalOrderAmount(0);
						let _price = 0;

						tempMember.forEach(memberElement => {
							if (memberElement.packages.length > 0) {
								memberElement.packages.forEach(packageElement => {
									_price = _price + packageElement.unit_price;
								});
							}

							finalTempMember.push({
								patient_id: memberElement.id,
								type: 2,
								patient_name: memberElement.first_name + ' ' + memberElement.last_name,
								tests: [...memberElement.packages]
							});
						});
                           
						setPackageTotalOrderAmount(_price);
						setPackageDetailPayload(finalTempMember);
					}

					setMembers(tempMember);
					dispatch(setSelectedMembersReducer(tempMember));
				} else {
					setMembers(members?members:_members);
				}

				resetBooking();
				setIsPackageSaved(true);
			}
			
		}

	}, [bookingSource, _members, _memberId]);



	useEffect(() => {
		if (profileSelect) {
			toggleAccordion(1);
		}
	}, [profileSelect]);

	useEffect(() => {
		if (isDeliveryDetailAdded) {
			toggleAccordion(2);
		}
	}, [isDeliveryDetailAdded]);

	useEffect(() => {
		if (selectedTimeSlot && vendorId != 4) {
			setBookingResponse(null);
			setCouponData(null);

			setCouponCode('');

			setIsTermRead(false);
			setPayUsingWallet(false);
			
		}
	}, [selectedTimeSlot]);

	useEffect(async () => {
		if (isDeliveryDetailAdded) {
			if (!bookingResponse && vendorId != 4) {
				setIsTimeSlotLoading(true);
				const dateNew = moment(selectedDay).format("YYYY-MM-DD");
				const response = await getTimeslotByHospital(vendorId, currentCityId, dateNew, locationData);
           
				setIsTimeSlotLoading(false);
				if (response && response.data && response.data.data && response.data.data.phlebo_slots) {
					setTimeSlots(response.data.data.phlebo_slots);
				}
			}
			if(vendorId == 4){
				setIsTimeSlotLoading(false);
			}
		// } else {
		// 	resetBooking();
		}
	}, [currentCityId, selectedDay, isDeliveryDetailAdded]);

	function handleShow() {
		setModal(true);
	}

	function handleClose() {
		setModal(false);
	}

	function getJWTtoken() {
		if (typeof window !== "undefined") {
			if (switchToken) {
				return switchToken;
			} else {
				return callToken;
			}
		} else if (callToken === undefined) {
			return null;
		} else {
			return null;
		}
	}

	function checkToken() {
		if (JWTresponse !== null) {
			return true;
		} else {
			return false;
		}
	}

	const resetBooking = () => {
		setProfileSelect(false);
		setIsDeliveryDetailAdded(false);
		setSelectedTimeSlot(null);

		setBookingResponse(null);
		setCouponData(null);

		setCouponCode('');

		setIsTermRead(false);
		setPayUsingWallet(false);
	};

	const displaySlotTime = () => {
		let content = (<div className="mx-2 mt-3 alert alert-danger text-center">No time slots available!</div>);
		if (!timeSlots) return content;
		if (timeSlots) {
			return content = (
				<>
					<h6 className="fs-6 mb-3 mt-4 date-time">Select Time Slot</h6>
					<ul className="list-unstyled d-flex flex-wrap mb-3">
						{timeSlots.map((item) => (
							<li key={item.stm_id}>
								<button
									disabled={item.blocked_count}
									className={`btn time-slot-btn rounded-1 ${(item.blocked_count) ? 'selected' : ''} ${(selectedTimeSlot && (selectedTimeSlot.stm_id === item.stm_id)) ? 'active' : ''} `}
									onClick={() => setSelectedTimeSlot(item)}>
									{timeConvertIntoAM_PM(item.slot)} - {timeConvertIntoAM_PM(item.end_time)}
								</button>
							</li>
						))}
					</ul>
				</>
			);
		}

		if (timeSlots === []) return content;
	};

	function addEmptyMorePackage(){
		if(_members.length == 0){
			// dispatch(setVendorReducer(null));
			dispatch(clearVendorReducer());
		}
	}

	function addMorePackage(id) {
		dispatch(setSelectedMemberIdReducer(id));
		dispatch(clearPackageDetailReducer());
	}

	function clearAllPackageFromAllMember() {
		setIsPackageSaved(false);
		dispatch(setSelectedMembersReducer(null));
		setMembers(null);
		toggleAccordion(0);
	}

	

	function deletePackage(mainIndex, packageId) {
		setIsPackageSaved(true);

		let removeOption = _members.map((o, index) => {
			return (index === mainIndex) ? { ...o, packages: o.packages.filter((s) => s.test_id !== packageId) } : { ...o };
		});

		if (removeOption[mainIndex].packages.length === 0) removeOption.splice(mainIndex, 1);

		if(removeOption.length == 0){
			dispatch(clearPackageDetailReducer(null))
		}
	
		dispatch(setSelectedMembersReducer(removeOption));
		setMembers(removeOption);	
		setPayUsingWallet(false)
		if (removeOption) {
			let finalTempMember = [];
			setPackageTotalOrderAmount(0);
			let _price = 0;

			removeOption.forEach(memberElement => {
				if (memberElement.packages.length > 0) {
					memberElement.packages.forEach(packageElement => {
						_price = _price + packageElement.unit_price;
					});
				}

				finalTempMember.push({
					patient_id: memberElement.id,
					type: 2,
					patient_name: memberElement.first_name + ' ' + memberElement.last_name,
					tests: [...memberElement.packages]
				});
			});
			setPackageDetailPayload(finalTempMember)
		}
	
		setBookingResponse(null);

	}

	function showError(str) {
		toast.error(str, {
			position: "top-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: false
		});
	}

	function toggleAccordion(index) {
		let parentEl = document.getElementsByClassName('accordion-item')[index];
		let child = (parentEl.firstElementChild || parentEl.firstChild);
		let secondChild = (child.firstElementChild || child.firstChild);
		secondChild.click();
	}

	function formatOrderDateTime() {
		if(vendorId == 4){
			return moment(selectedDay).format("DD MMM YYYY");
		}else{
			return moment(new Date(orderDateTime)).format("ddd, D MMM YYYY | ") + timeConvertIntoAM_PM(selectedTimeSlot.slot) + " - " + timeConvertIntoAM_PM(selectedTimeSlot.end_time);
		}
	}

	function getJWTtoken() {
		if (switchToken) {
			return switchToken;
		} else if (callToken) {
			return callToken;
		} else {
			return null;
		}
	}

	function bookPackage() {
		if (typeof members?.length == 'undefined' || members?.length == 0) {
			return showError('Please choose package/tests!');
		}

		if (!selectedUser) {
			return showError('Please login to continue!');
		}

		if (!profileSelect) {
			return showError('Please select a member!');
		}

		if (!currentDeliveryDetail) {
			return showError('Please fill delivery details!');
		}

		if (!selectedTimeSlot && vendorId != 4) {
			return showError('Please select time slot!');
		}

		if (!packageDetailPayload) {
			return showError('Please select package!');
		}

		const currentTime = currentTimeConvertIntoAM_PM();
		setOrderDateTime(dateFormatIntoYYYYMMDD(selectedDay) + ' ' + currentTime);

		let payload = {
			patient_id: selectedUser.id,
			from_time: vendorId == 4? '' : timeConvertIntoAM_PM(selectedTimeSlot.slot),
			to_time: vendorId == 4? '' : timeConvertIntoAM_PM(selectedTimeSlot.end_time),
			order_date: vendorId == 4? dateFormatIntoYYYYMMDD(selectedDay) : orderDateTime,
			order_time: vendorId == 4? '' : currentTime,
			locality_name: "",
			phlebo_charges: "",
			type: 2,
			address1: currentDeliveryDetail.address1,
			amount_after_discount: packageTotalOrderAmount.toString(),
			is_payment_completed: false,
			pin_code: currentDeliveryDetail.pincode,
			discount_amount: 0,
			unit_price: packageTotalOrderAmount.toString(),
			discount_percentage: 0,
			state_name: currentDeliveryDetail.state,
			slot_date: dateFormatIntoYYYYMMDD(selectedDay),
			city_name: currentDeliveryDetail.city,
			address2: currentDeliveryDetail.address2,
			booking_source: 2,
			vendor_id: vendorId,
			slot_id: selectedTimeSlot? selectedTimeSlot.stm_id.toString(): null,
			login_id: selectedUser.id.toString(),
			city_id: currentCityId.toString(),
			test_details: packageDetailPayload
		}
       
		orderPackage(payload)
			.then(function (response) {
				let res = response.data;
				if (res && res.errors) {
					res.errors.map((e) => {
						return showError(e.message);
					})
					return false;
				}
                     
				setBookingResponse(res);
				setToBePaidAmount(res.final_amount)
			})
			.catch(function (err) { });
	}

	function applyCouponCode() {
		let JWTresponse = getJWTtoken();
		applyCoupon({
			booking_id: bookingResponse ? bookingResponse.order_id : null,
			coupon_code: couponCode,
			is_diagnostics: 1,
			type: 1
		}, JWTresponse)
			.then(function (response) {
				let res = response.data;
				if (res && res.errors && res.errors.length > 0) {
					return showError(res.errors[0].message);
				}

				setCouponData(res);
			})
			.catch(function (err) { });
	}


	function removeCouponCode() {
		let JWTresponse = getJWTtoken();
		const payload={
			booking_id: bookingResponse ? bookingResponse.order_id : null,
			coupon_code:couponCode,
			is_diagnostics: 1,
			type: 1
		}

		removeCoupon(payload, JWTresponse)
			.then(function (response) {
				let res = response.data;
				if (res && res.errors && res.errors.length > 0) {
					return showError(res.errors[0].message);
				}

				setCouponData(null);
			})
			.catch(function (err) { });
	}

	function manageWallet(value) {
	 
		setPayUsingWallet(value);
		if(value===true){
				if(bookingResponse.wallet_amount > bookingResponse.final_amount){
					setToBePaidAmount(0);
					setForBEWallet(bookingResponse.final_amount)
				}else{
					setToBePaidAmount( Math.abs(bookingResponse.final_amount-bookingResponse.wallet_amount));
					setForBEWallet(Math.abs(bookingResponse.final_amount-bookingResponse.wallet_amount))
				}
	
			
		  }
		 else{
			setToBePaidAmount(bookingResponse.final_amount)
		 }
		if (value) { return false; }
		  let JWTresponse = getJWTtoken();  
	
		
	}
        function PayNow (order_id,wallet_amount) {
			 var url =apiURL + bookingResponse.action.uri
	
 
			if(payUsingWallet){
				let payload = {	
		
					'booking_id':order_id, 
					'wallet_amount':  wallet_amount,
					'type': 1,  
					'is_diagnostics': 1
		}
			addWallet(payload , JWTresponse)
		.then(function (response) {
			let res = response.data;	
			window.location.href = url;	
			dispatch(setPackageOrderHistory({wallet_status:true, order_id:order_id, wallet_id:response.data.booking.wallet_id}));

			if (res && res.errors && res.errors.length > 0) {
				return showError(res.errors[0].message);
			}
		})
		.catch(function (err) { });
			}
			else{
				window.location.href = url;
			}
			
	
		}
        
		useEffect(async () => {	
            if(packageOrderHistory && packageOrderHistory.wallet_status == true && packageOrderHistory?.wallet_id){			 
			   checkBookingStatusPackage(packageOrderHistory.order_id).then(function (response) {				
			
				if(response.data.data.booking_status != 'CONFIRMED')
				{
					let payload = {			
						wallet_id: packageOrderHistory.wallet_id,
						booking_id: packageOrderHistory.order_id,	
						type: 1,
						is_diagnostics: 1						
						}
	
						removeWallet(payload , JWTresponse)
							.then(function (response) {							
								dispatch(setPackageOrderHistory(null))
								let res = response.data;
								if (res && res.errors && res.errors.length > 0) {
									return showError(res.errors[0].message);
								}
							})
						.catch(function (err) { });
							
				        }
					})
					 .catch(function (err) {
					 });

		    }
		},[packageOrderHistory])

	function payLaterForNow(value) {
		let JWTresponse = getJWTtoken();

		if (value) { return false; }

		payLater({
			booking_id: bookingResponse ? bookingResponse.id : null,
			vendor_id: vendorId
		}, JWTresponse)
			.then(function (response) {
				let res = response.data;
				if (res && res.errors && res.errors.length > 0) {
					return showError(res.errors[0].message);
				}
				window.open(payLaterLink + (bookingResponse ? bookingResponse.id : ''), '_blank').focus();
				setModal(false);
				resetBooking();
			})
			.catch(function (err) { });
	}

	const breadCrumbData = [
		{ type: 1, url: `${APP_SUB_FOLDER}/`, name: 'Home' },
		{ type: 1, url: `${APP_SUB_FOLDER}/book-package-or-test/packages`, name: 'Book Package & Test' },
		{ type: 4, url: `${APP_SUB_FOLDER}/book-package-or-test/packages`, name: 'Packages' },
		{ type: 3, url: '', name: 'Booking' }
	];

	async function handleInfoShow(selectedPackage) {
		setShowPackageDetail(true);
		const _thyroProduct = await thyrocareDetail(selectedPackage.diagnostic_id, packageDetail.pincode);

		if (_thyroProduct && _thyroProduct.data && _thyroProduct.data.details) {
			let modifiedChild = _thyroProduct.data.details[0].childs.reduce(function (
				r,
				a
			) {
				r[a.group_name] = r[a.group_name] || [];
				r[a.group_name].push(a);
				return r;
			},
				Object.create(null));

			let sortedChild = [];
			for (var x in modifiedChild) {
				sortedChild.push(modifiedChild[x]);
			}

			let mChild = { mData: sortedChild };
			_thyroProduct.data.details[0] = {
				..._thyroProduct.data.details[0],
				...mChild,
			};
		}

		setPackageDetail(_thyroProduct.data);
	}

	return (
		<React.Fragment>
			<Container>
				<BreadcrumbWithUrl breadcrumbs={breadCrumbData} />
				<div className="row">
					<div className="col-lg-6">
						<Accordion defaultActiveKey="0"  className="mhc-profile-tabs">
							<Accordion.Item eventKey="0">
								<Accordion.Header>1. Login/ Register</Accordion.Header>
								<Accordion.Body className="p-3 px-0">

									{(registered && !checkingToken) &&
										<LoginUser
											registeredState={setRegistered}
											setMobile={setMobile}
											setOtpVerified={setOtpVerified} />
									}

									{(!registered && !verifyOTP) && (
										<NewUserRegister
											setVerifyOTP={setVerifyOTP}
											mobile={mobile} />
									)}

									{verifyOTP && (
										<VerifyRegister
											mobile={mobile}
											setOtpVerified={setOtpVerified}
										/>
									)}

									{(otpVerified || checkingToken) && (
										<FamilyMember
											resetBooking={resetBooking}
											setMemberSelect={setMemberSelect}
											setProfileSelect={setProfileSelect}
											setIsPackageSaved={setIsPackageSaved}										
											mode="checkbox"
										/>
									)}
								</Accordion.Body>
							</Accordion.Item>

							{
								profileSelect ? (
									<>
										<Accordion.Item eventKey="1">
											<Accordion.Header>2. Patient Details &amp; Address</Accordion.Header>
											<Accordion.Body className="py-3">
												<PatientDetails
													setCurrentDeliveryDetail={setCurrentDeliveryDetail}
													setIsDeliveryDetailAdded={setIsDeliveryDetailAdded}
													setCurrentCityId={setCurrentCityId}
												/>
											</Accordion.Body>
										</Accordion.Item>

										{
											isDeliveryDetailAdded ? (
												<Accordion.Item eventKey="2">
													<Accordion.Header>3. Pick your date &amp; time</Accordion.Header>
													<Accordion.Body className="py-3">
														<SelectDateTime
															selectedDay={selectedDay}
															setSelectedDay={setSelectedDay}
															minDate={moment(today).toDate()}
														/>

														{
															isTimeSlotLoading ?
																<div className="col-12 position-relative text-center mt-3">
																	<img src={`../../../loader.gif`} style={{ height: "30px" }} alt="Loading..." />
																</div> :
																timeSlots ?
																	<>
																	 	{displaySlotTime()}
																		<div className="text-end">
																			<button type="button" className="btn btn-outline-secondary rounded-1 me-2" onClick={() => toggleAccordion(0)}>Add More Member</button>
																			<button disabled={selectedTimeSlot ? false : true} className="btn btn-outline-primary rounded-1" onClick={(e) => { bookPackage() }}>Confirm</button>
																		</div>
																	</> : 
																	<>
																	{vendorId == 4? 
																		<>
																			<div className="text-center"><h6 className="py-3">Your selected date: {moment(selectedDay).format("DD MMM YYYY")}</h6></div>
																			<div className="text-end">
																			<button type="button" className="btn btn-outline-secondary rounded-1 me-2" onClick={() => toggleAccordion(0)}>Add More Member</button>
																			<button className="btn btn-outline-primary rounded-1" onClick={(e) => { bookPackage() }}>Confirm</button>
																		</div>
																		</>	
																		: 
																		<div className="mx-2 mt-3 alert alert-danger text-center">Slots are full. Please select next date and time</div>
																	}
																	</>
																	
														}

													</Accordion.Body>
												</Accordion.Item>
											) : ''
										}
									</>
								) : ''
							}
						</Accordion>
					</div>

					{
						(members && members.length) ? (
							<div className="col-lg-6">
								{
									members.map((memberItem, mIndex) => {
										return memberItem.packages ? (
											<div className="mhc-package-details mb-2" key={memberItem.id}>
												<div className={`row align-items-center${memberItem.packages.length !== 0 ? ' border-bottom' : ''}`}>
													<div className="col-3">Patient Name </div>
													<div className="col-auto">
														<span >:</span>
													</div>
													<div className="col-6"><span className="fw-bold">{memberItem.first_name} {calculateAge(memberItem.dob)} Yrs, {memberItem.gender === "Male" ? "M" : "F"}, {(selectedUser && (selectedUser.id === memberItem.id)) ? 'Self' : memberItem.relation}</span></div>
													<div className="col-auto">
														<Link
															to={`${APP_SUB_FOLDER}/book-package-or-test`}
															onClick={(e) => addMorePackage(memberItem.id)}
															className="btn btn-sm btn-outline-secondary rounded-1">Add Package</Link>
													</div>
												</div>

												{
													memberItem.packages ? memberItem.packages.map((packageItem, index) => {

														return (
															<div className="row mt-2" key={index}>
																<div className="col-9"><span>{packageItem.name}</span> <i
																	className="mhc-icon icon-mhc-info cursor-pointer"
																	onClick={() => handleInfoShow(packageItem)}
																></i></div>
																<div className="col-3 text-end">
																	<span className="me-2">:</span>
																	<span >INR {packageItem.net_amount}</span>
																	<i className="mhc-icon icon-trash-empty cursor-pointer" onClick={() => deletePackage(mIndex, packageItem.test_id)}></i>
																</div>
															</div>
														)
													}) : ''
												}
											</div>
										) : ''
									})
								}

								{
									bookingResponse ? (
										<>
											{
												(selectedUser && selectedUser.mobile_number) ?
													<div className="mhc-patient-details mt-4">
														<div className="row mt-2">
															<div className="col-4">Mobile No. </div>
															<div className="col-auto"><span >:</span></div>
															<div className="col-7"><span className="fw-bold">+{selectedUser.isd_code} {selectedUser.mobile_number}</span></div>
														</div>
														<div className="row mt-2">
															<div className="col-4">Address</div>
															<div className="col-auto"><span>:</span></div>
															<div className="col-7"><span className="fw-bold">{currentDeliveryDetail.address1}, {currentDeliveryDetail.address2}, {currentDeliveryDetail.city}, {currentDeliveryDetail.state}, {currentDeliveryDetail.pincode}</span></div>
														</div>
														<div className="row mt-2">
															<div className="col-4">Appointment</div>
															<div className="col-auto"><span >:</span></div>
															<div className="col-7"><span className="fw-bold text-primary">{formatOrderDateTime()}</span></div>
														</div>
													</div> : ''
											}

											<div className="mhc-payment-details mt-4">
												<div className="row mt-2">
													{
														!couponData ? (
															<>
																<div className="col-md-9 col-lg-9 col-xl-10">
																	<div className="form-floating">
																		<input
																			type="text"
																			className="form-control"
																			id="CouponCode"
																			placeholder="Coupon Code"
																			value={couponCode}
																			onChange={(e) => setCouponCode(e.target.value)}
																		/>
																		<label htmlFor="name">Coupon Code </label>
																	</div>
																</div>
																<div className="col-md-3 col-lg-3 col-xl-2 mt-2 mt-md-0">
																	<button className="btn btn-outline-secondary w-100 rounded-1 bg-white" onClick={(e) => applyCouponCode()}>
																		Apply
																	</button>																	
																</div>
															</>
														) : (
															<div className="col-12">
																<label className="alert alert-success w-100">Coupon code has been applied successfully.
																	<a className="btn btn-link p-0 text-danger ms-1 text-decoration-none" onClick={(e) => removeCouponCode()}>Remove coupon</a>
																</label>
															</div>
														)
													}
                                                
													<div className="row mt-2">
														<div className="col-6">Package Fee </div>
														<div className="col-6 text-end"><span className="me-1">:</span><span className="fw-bold">INR {bookingResponse && bookingResponse.final_amount} </span></div>
													</div>

													{bookingResponse.phlebo_charges? 
															<div className="row mt-2">
																<div className="col-6">Convenience Fee </div>
																<div className="col-6 text-end"><span className="me-1">:</span><span className="fw-bold">INR {bookingResponse.phlebo_charges}</span></div>
															</div>										
														: null
													}


													{
														couponData && (
															<div className="row mt-2">
																<div className="col-6">Coupon Discount </div>
																<div className="col-6 text-end">
																	<span className="me-1">:</span>
																	<span className="fw-bold">INR {couponData.discount_amount}</span>
																</div>
															</div>
														)
													}
													<div className="row mt-2">
														<div className="col-6">
															<div className="form-check">
																<input
																	className="form-check-input"
																	type="checkbox"
																	id="MyWallet"
																	checked={payUsingWallet}
																	onChange={(e) => manageWallet(e.target.checked)}
																/>
																<label
																	className="form-check-label d-block d-sm-inline"
																	htmlFor="MyWallet">
																	Use MyWallet for Payment
																</label>
															</div>
														</div>
														<div className="col-6 text-end">
															<span className="me-1">:</span>
															<span className="fw-bold">INR {bookingResponse && bookingResponse.wallet_amount}</span>
														</div>
													</div>

													<div className="border-bottom border-light w-100 transition mt-3"></div>

													<div className="row mt-2">
														<div className="col-6"><span className="fw-bold">Total to be paid</span></div>
														<div className="col-6 text-end">:INR {toBePaidAmount}</div>
													</div>
												</div>
											</div>

											<div className="mhc-register-form mt-4">
												<div className="form-check">
													<input
														className="form-check-input"
														type="checkbox"
														onClick={(e) => setIsTermRead(e.target.checked)}
														id="isTerm" />
													<label className="form-check-label  d-block d-sm-inline" htmlFor="isTerm">
														I have read and agree to the <a href="https://www.myhealthcare.co/terms-and-conditions" target="_blank" className="text-primary text-decoration-none">Terms and Conditions.</a>
													</label>
												</div>
											</div>

											{
												bookingResponse ? (
													<div className="row my-4">
														<div className="col-12 text-center text-md-end">
															<button onClick={(e) => {
																resetBooking();
																clearAllPackageFromAllMember();
															}} className="btn btn-outline-secondary rounded-1">Cancel</button>
															{
																isTermRead ?
																	(
																		<>
																			<button className="btn btn-outline-primary ms-3 rounded-1" onClick={handleShow}>Pay Later</button>
																			<a onClick={()=>PayNow(bookingResponse.order_id, bookingResponse.wallet_amount)}  className="btn btn-primary ms-3 rounded-1">Pay Now</a>
																		</>
																	) : (
																		<>
																			<button disabled className="btn btn-outline-primary ms-3 rounded-1">Pay Later</button>
																			<button disabled className="btn btn-primary ms-3 rounded-1">Pay Now</button>
																		</>
																	)
															}
														</div>
													</div>
												) : ''
											}
										</>
									) : ''
								}
							</div>
						) : <div className="col-auto">
							{(otpVerified || checkingToken) && 
							<Link to={`${APP_SUB_FOLDER}/book-package-or-test`} onClick={(e) => addEmptyMorePackage()} className="btn btn-sm btn-outline-secondary rounded-1">Add Package</Link>}
						</div>
					}
				</div>
			</Container>

			<Modal show={modal} onHide={handleClose} size="md" >
				<Modal.Body >
					<p className="fs-6 text-center fw-bold">Using “Pay Now” option is more rewarding As you can earn/redeem wallet points.</p>
					<div className="row mt-4">
						<div className="col-12 text-center ">
							<button type="button" onClick={() => payLaterForNow()} className="btn btn-outline-primary rounded-1" >Pay Later</button>
							<a target="_blank" rel="noreferrer" href={(bookingResponse && bookingResponse.action) ? (apiURL + bookingResponse.action.uri) : ''} className="btn btn-primary ms-3 rounded-1" onClick={handleClose}>Pay Now</a>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showPackageDetail}
				onHide={() => {
					setShowPackageDetail(false);
					setPackageDetail(null);
				}}
				size="xl"
				className="mhc-package-modal">
				<Modal.Header closeButton>
					{infoPackage && (
						<div className="d-flex justify-content-between align-items-center">
							<div>
								<h6 className="m-0">{infoPackage.name}</h6>
								<p className="m-0">
									Profile Includes ({infoPackage.details[0].testCount} Parameters)
								</p>
							</div>
							<div>
								<img
									src={infoPackage.details[0].diagnosis_logo}
									className="pacg-icon"
								/>
							</div>
						</div>
					)}
				</Modal.Header>
				<Modal.Body>
					{infoPackage ? (
						<Accordion defaultActiveKey={0}>
							{infoPackage.details[0].mData.map((xItem, index) => {
								return (
									<Accordion.Item eventKey={index} key={index}>
										<Accordion.Header>
											{xItem[0].group_name} (includes {xItem.length} tests)
										</Accordion.Header>
										<Accordion.Body>
											<ul className="m-0">
												{xItem &&
													xItem.map((mItem, mIndex) => {
														return <li key={mIndex}>{mItem.name}</li>;
													})}
											</ul>
										</Accordion.Body>
									</Accordion.Item>
								);
							})}
						</Accordion>
					) : (
						<div className="mhc-Loding text-center my-5">
							<img src={`${APP_SUB_FOLDER}../../../loader.gif`} alt="loader" />
						</div>
					)}
				</Modal.Body>
			</Modal>

			<ToastContainer />
		</React.Fragment >
	)
}

export default PackageBooking;