import React from "react";
import { Link } from "react-router-dom";
import { APP_SUB_FOLDER } from "../../utils/constant";
// const IProps = {
// textUrl:[]
// }
const Breadcrumb =({textUrl})=>{
 
        return (
            <React.Fragment>
                <nav aria-label="breadcrumb" className="breadcrumb_sticky">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/"> Home</Link></li>
                        {
                             textUrl===undefined ?'':
                            textUrl.map((item, index)=>{  
                                return(
                                    <li key={index} className={`breadcrumb-item ${item.url===''? 'active':''}`}>{item.url===''?<span>{item.name}</span>:<Link  to={item.url}> {item.name}</Link>}</li>
                                )
                            })
                        }
  
                        {/* <li className="breadcrumb-item active" aria-current="page" ><a className="position-relative" style={{top: '1px'}} onClick={(e)=>{e.preventDefault()}}></a></li> */}
                    </ol>
                </nav>
            </React.Fragment>
        )
    }


export default Breadcrumb