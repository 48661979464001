import React  from "react";
// import {Logo} from "../../utils/constant";
import { Container } from "react-bootstrap";
import { APP_SUB_FOLDER } from "../../utils/constant";

const PageNotFound =()=> {
  return (
    <React.Fragment>
         <Container className="error-min-body-height">
                <div className='jumbotron err-vertical-center d-flex align-items-center min-vh-100s'>
                    <div className='container center-container'>
                        <div className="row">
                          <div className="col-12 text-center">
                            <span className="err-number">404</span>
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-12 text-center">
                            <span className="oops">OOPS! NOTHING WAS FOUND</span>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-12 text-center">
                            <span className="description">The page you are looking for might have been removed, had its name changed or is temporarily unavailable.
                              <a href={`${APP_SUB_FOLDER}/`} className="text-danger"> Return to homepage</a>
                             
                            </span>
                          </div>
                        </div>
                    </div>
                  </div>
                </Container>
      </React.Fragment>
  );
};
export default PageNotFound;