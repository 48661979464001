import React, { useEffect, useRef, useState } from "react";
import Pickadate from 'pickadate/builds/react-dom'
import { Accordion, Modal } from "react-bootstrap";
import PdfIcon from '../../assets/images/mhc-pdf.svg'
import imgIcon from '../../assets/images/mhc-Image.svg'
import moment  from 'moment';
import Slider from "react-slick";


const PatientMyDocumentMyAppointments =({documentList})=>{
  const sliderRef = useRef(null);
  const [show, setShow] = useState(false);
  const [imgUrl, setImgUrl]=useState();
  // const[currentSlider, setCurrentSlider]=useState()
  const [slideNumber, setSlideNumber]=useState(0)
  const handleClose = () => {
    setSlideNumber()
    setShow(false);}
 
  const handleImages=(e, url, i)=>{
    e.preventDefault();
    let geturl = url;
 
    setImgUrl(geturl)
    // setCurrentSlider(i)
    setSlideNumber(i)
     setShow(true); 

  }

  useEffect(() => {
    if (sliderRef.current?.slickGoTo)
    sliderRef.current.slickGoTo(slideNumber);
  }, [slideNumber]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight:true,
    // slickGoTo:currentSlider,
    // slickCurrentSlide:currentSlider
  };

        return(
            <React.Fragment>
               <div className="row d-none">
                <div className="col-xl-5 col-lg-6 col-md-8">
                 <div className="d-flex flex-column flex-md-row">
                 <div className="form-floating mhc-dob date mb-4">
              <Pickadate.InputPicker  required    readOnly={false} />
              <i className="mhc-icon icon-calendar"></i>
             <label htmlFor="Dob">Start Date</label>
                    </div>
                    <div className="form-floating mhc-dob date mb-4 ms-0 ms-md-3">
              <Pickadate.InputPicker  required    readOnly={false} />
              <i className="mhc-icon icon-calendar"></i>
             <label htmlFor="Dob">End Date</label>
</div>
                 </div>
                 </div>
                 </div>
                 <Accordion className="mhc-appoinment-tabs mb-4">
               { 
                   documentList?.map((item, i) => {                      
                           let date=moment(item.booking_date).format('DD MMM, YYYY')
                            let time=moment(item.booking_date).format('LT')
                            let sliderImg= item.documents
                            // console.log("sliderImg;;;;", sliderImg)
                           return(
                                     <>
                                       <Accordion.Item eventKey={item.id} key={i+1}>
    <Accordion.Header><span>{item.doctor.name} </span> <span className="text-primary">{date}</span></Accordion.Header>
    <Accordion.Body>
    <div className="row">
      <div className="col-10">
        <p className="fw-bolder mb-2">Patient Notes &amp; Documents</p>
        <p className="mb-0">{item.notes}</p>
        <div className="d-flex mt-3">
   {
  sliderImg.map((imgScr, i)=>{
        let getUrl = imgScr.split('.');
        let getExt = getUrl.slice(-1)[0];
        return (
          <div >
              <a href={imgScr} onClick={(e)=>handleImages(e, sliderImg, i)}>
                <img src={getExt == 'pdf' || getExt == 'PDF'? PdfIcon: imgIcon} alt="PdfIcon" className="img-icon" />
              </a>
          </div>
    )}) 
    
   }
        
        </div>
      </div>
      <div className="col-2 text-end">
        <p className="mb-0 text-primary fw-bolder">{time}</p>
      </div>
    </div>
    </Accordion.Body>
  </Accordion.Item>
                                     </>
                           )                 
                         })
        }
                         
                   


</Accordion>
<Modal show={show} onHide={handleClose} className="mhc-imgPopup"
      size="lg"
      centered
>
        <Modal.Header closeButton>        
        </Modal.Header>
        <Modal.Body className="minHeight5">
        <Slider ref={sliderRef} {...settings}>
          {/* <embed type="" src={imgUrl} className="popup-img" /> */}
       
          {
             imgUrl  === undefined ? "" : imgUrl.map((itemm, i)=>(
              <div>
              <embed type="" src={itemm} className="popup-img" />
              </div>
            ))
          }
          </Slider>
          </Modal.Body>
      </Modal>

            </React.Fragment>
        )
    }
export default PatientMyDocumentMyAppointments