import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";
import NavBar from "./component/navbar/index";

import Footer from "./component/footer/index";

import BookingConsult from "./pages/booking/index";
import UserRegister from "./pages/register/index";
import PatientProfile from "./pages/my-profile/index";
import ProfileEdit from "./pages/my-profile/profile-edit";
import ConsultDoctor from "./pages/consult-doctor/specialities-doctors";
import DectorProfile from "./pages/dector-profile/dectorProfile";

import PatientDashBoard from "./pages/patient-portal/patient-dashboard";
import PatientMyBooking from "./pages/patient-portal/patientMyBooking";
import PatientMyprescriptions from "./pages/patient-portal/patientMyprescriptions";
import PatientMyDocument from "./pages/patient-portal/patientMyDocument";
import PatientMyReport from "./pages/patient-portal/patientMyReport";
import DashboardLogin from "./component/login-register/dashboardLogin";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { locationReducer } from "./redux/slice/location-slice";
import PageNotFound from "./component/404";
import PatientMyBills from "./pages/patient-portal/patientMyBills";
import MyDischargeSummary from "./pages/patient-portal/My-Discharge-Summary";
import { APP_SUB_FOLDER, site_SEO_META } from "./utils/constant";
import QrRegister from "./pages/register/qr-rigster";
import ReactGA from "react-ga4";
import BookLabRadiologyTests from "./pages/book-lab";
import BookPackageOrTest from "./pages/book-package-or-test";
import PackageComparison from "./pages/package-comparison/packageComparison";
import PackageBooking from "./pages/book-package-or-test/package/packageBooking";
import PackageBookingConfirmed from "./pages/Booking-Confirmed/packageBookingConfirmed";
import PackageTestDetail from "./pages/book-package-or-test/packageTestEyeButton";
import TestBooking from "./pages/book-package-or-test/test/testBooking";
import CarePackageCategories from "./pages/care-package/carePackageCategories";
import CarePackages from "./pages/care-package/carePackages";
import CareBookingConfirmed from "./pages/care-package/careBookingConfirmed";
import CarePackageEyeButton from "./pages/care-package/CarePackageEyeButton";
import CarePackageBooking from "./pages/care-package/carePackageBooking";

import HomecareService from "./pages/homecareService/homecareService";
import HomecareCategory from "./pages/homecareService/homecareCategory";
import HomecareCategoryPackages from "./pages/homecareService/homecareCategoryCompare";
import HomecarePackageCompare from "./pages/homecareService/homecarePackageCompare";
// import HomecareServiceBooking from "./pages/package-login/HomecareServiceBooking";

function App() {
  const location = useSelector((state) => state.location.value);
  const dispatch = useDispatch();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  const allUsers = useSelector((state) => state.allUsers.value);

  if (site_SEO_META == true) {
    // OUR_TRACKING_ID
    ReactGA.initialize([
      { trackingId: "UA-216820759-2", gaOptions: { name: "Google" } },
    ]);
    // ReactGA.send("pageview");
  }

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus("Geolocation is not supported by your browser");
    } else {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          dispatch(
            locationReducer({
              lat: position.coords.latitude,
              long: position.coords.longitude,
            })
          );
        },
        () => {
          setStatus("Unable to retrieve your location");
        }
      );
    }
  };
  useEffect(() => {
    getLocation();
  }, []);

  return (
    <React.Fragment>
      <Router basename={`${APP_SUB_FOLDER}`}>
        {window.location.pathname == `${APP_SUB_FOLDER}/login` ||
        window.location.pathname == `${APP_SUB_FOLDER}/qr-register` ? null : (
          <NavBar />
        )}
        <Routes>
          <Route path={`/`} element={<BookingConsult />} />
          <Route
            path={`/book-consult/:specialities/:Id/:sId`}
            element={<ConsultDoctor />}
          />
          <Route
            path={`/book-consult/:specialities/:doctor/:hId/:sId/:dId/:cId`}
            element={<DectorProfile />}
          />

          <Route path={`/login`} element={<DashboardLogin />} />
          <Route path={`/user-register`} element={<UserRegister />} />
          <Route path={`/qr-register`} element={<QrRegister />} />
          <Route path={`/my-dashboard`} element={<PatientDashBoard />} />
          <Route path={`/my-profile`} element={<ProfileEdit />} />
          <Route path={`/my-bookings`} element={<PatientMyBooking />} />
          <Route
            path={`/my-prescriptions`}
            element={<PatientMyprescriptions />}
          />
          <Route path={`/my-documents`} element={<PatientMyDocument />} />
          <Route path={`/my-reports`} element={<PatientMyReport />} />
          <Route path={`/my-bills`} element={<PatientMyBills />} />
          <Route
            path={`/my-discharge-summary`}
            element={<MyDischargeSummary />}
          />

          <Route
            path={`/book-package-or-test/packages`}
            element={<BookPackageOrTest />}
          />
          <Route
            path={`/book-package-or-test/test`}
            element={<BookPackageOrTest />}
          />
          <Route
            path={`/package-comparison/:categoryName/:compare1/:compare2`}
            element={<PackageComparison />}
          />
          <Route
            path={`/book-package-or-test/packages/:slug/package-booking`}
            element={<PackageBooking />}
          />
          <Route path={`/package-booking`} element={<PackageBooking />} />
          <Route
            path={`/package-booking/:slug/:dId`}
            element={<PackageTestDetail />}
          />
          <Route
            path={`/book-package-or-test/test/booking`}
            element={<TestBooking />}
          />
          <Route
            path={`/package-booking-confirm`}
            element={<PackageBookingConfirmed />}
          />

          {/* home care service */}
          <Route path={`/homecare-services`} element={<HomecareService />} />
          <Route
            path={`/homecare-service-category/:categoryName/:categoryId`}
            element={<HomecareCategory />}
          />
          <Route
            path={`/homecare-services/:slug/:categoryId`}
            element={<HomecareCategory />}
          />

          <Route
            path={`/homecare-service-packages/:slug/:hospitalSlug/:categoryId/:hospitalId`}
            element={<HomecareCategoryPackages />}
          />
          <Route
            path={`/homecare-service-package-compare/:hospitalId/:compare1/:compare2/:categoryName/:subCatName`}
            element={<HomecarePackageCompare />}
          />
          {/* <Route path={`/homecare-service-booking/:categoryName/:subCatName/:categoryId/:hospitalId`} element={<HomecareServiceBooking />} /> */}

          {/* care  package */}
          <Route
            path={`/care-package-categories`}
            element={<CarePackageCategories />}
          />
          <Route
            path={`/care-packages/:categoryName/:categoryId`}
            element={<CarePackages />}
          />
          <Route
            path={`/care-package-comparison/:categoryName/:compare1/:compare2`}
            element={<PackageComparison />}
          />
          <Route
            path={`/care-package-booking/:categoryName/:subCategoryName`}
            element={<CarePackageBooking />}
          />
          <Route
            path={`/care-booking-confirmed`}
            element={<CareBookingConfirmed />}
          />
          <Route
            path={`/care-package-info`}
            element={<CarePackageEyeButton />}
          />

          <Route path={`*`} element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </React.Fragment>
  );
}

export default App;
