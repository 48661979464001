import React, { Component, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Collapse } from "react-bootstrap";
import Pickadate from 'pickadate/builds/react-dom'
import moment from 'moment';
import { Modal, Accordion } from "react-bootstrap";
import { diagnosticsParametersDetails } from "../../actions/dashboard-api";
import { DiagnosticTrackOrder } from "../../actions/dashboard-api";
import LodingComponent from "../../component/loding";

import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import { capitalizeFirst } from "../../utils/fliters";

const PatientDiagnostics = ({ diagnosticsData }) => {
    const switchToken = useSelector((state) => state.switchToken.value);
    const [open, setOpen] = useState(false)
    const [modal, setModal] = useState(false);
    const [packageName, setPackageName] = useState()
    const [packageGroupData, setpackageGroupData] = useState([])
    const [diatrackData, setDiatrackData] = useState()
    const [collID, setCollID] = useState('')

    
    const handleClose = () => setModal(false);

    const handlebookingData = (e, bookingCode, pinCode, packageName) => { 
        setpackageGroupData([]);
        setPackageName(packageName);
        diagnosticsParametersDetails(bookingCode, pinCode, switchToken).then(function (response) {
                const packageData = response.data.details[0].childs;
                const key = 'group_name';
                const package_group = function(xs, key) {
                    return xs.reduce(function(rv, x) {
                        (rv[x[key]] = rv[x[key]] || []).push(x);
                        return rv;                            
                    }, {});
                };
            let packageGroupData =  package_group(packageData, key);
            setpackageGroupData(packageGroupData);
        })
            .catch(function (err) {
                console.log(err);
            });
        setModal(true)
    }

    const handlecollspe = (e, i, bookCode) => {
        setOpen(open => !open);
        setCollID('collapse_' + i);
         let diagCode = bookCode;
         setDiatrackData(null);
        DiagnosticTrackOrder(diagCode, switchToken).then(function (response) {
            let trackData = response.data.data.order_status;
            setDiatrackData(trackData)       
         
        })
            .catch(function (err) {
                console.log("error", err);
            });
    }

    return (
        <React.Fragment>
            <div className="row d-none">
                <div className="col-xl-5 col-lg-6 col-md-8">
                    <div className="d-flex flex-column flex-md-row">
                        <div className="form-floating mhc-dob date mb-4">
                            <Pickadate.InputPicker required readOnly={false} />
                            <i className="mhc-icon icon-calendar"></i>
                            <label htmlFor="Dob">Start Date</label>
                        </div>
                        <div className="form-floating mhc-dob date mb-4 ms-0 ms-md-3">
                            <Pickadate.InputPicker required readOnly={false} />
                            <i className="mhc-icon icon-calendar"></i>
                            <label htmlFor="Dob">End Date</label>
                        </div>
                    </div>
                </div>
            </div>

            {diagnosticsData != undefined || diagnosticsData != null ?
                diagnosticsData.map((item, i) => {
                    let date = moment(item.created_at).format('DD MMM, YYYY')
                    let day = moment(item.created_at).format('ddd')
                    let time = moment(item.created_at).format('LT')
                    let testName = item.test_details;
                    let pinCode = item.pin_code;
                    let bookCode = item.booking_code;
                  
                    return (
                        <div className="mhc-medicines">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3 col-lg-3">
                                        <p className="mb-1 custom-text fw-bold">{day} {date} | {time}</p>
                                        <p className="mb-0 tittle ">{item.patient_name}</p>
                                        <p className="mb-0 ">Booking Code: <span>{bookCode}</span></p>
                                    </div>
                                    <div className="col-md-6 col-lg-6 mt-3 mt-md-0 text-start ">

                                        {

                                            testName.map((obj) => {
                                                return (
                                                    <>
                                                        {
                                                            obj.hasOwnProperty("tests") ?
                                                                obj.tests.map((test) => {
                                                                    return (
                                                                        <p className="mb-0 tittle ">{test.name} <i class="mhc-icon icon-mhc-info cursor-pointer text-primary" onClick={(e) => handlebookingData(e, test.diagnostic_id, pinCode, test.name)}></i></p>
                                                                    )
                                                                })
                                                                : <p className="mb-0 tittle ">{obj.name} <i class="mhc-icon icon-mhc-info cursor-pointer text-primary" onClick={(e) => handlebookingData(e, obj.diagnostic_id, pinCode, obj.name)}></i></p>


                                                        }
                                                    </>
                                                )

                                            })
                                        }
                                        {/* <p className="mb-0 ">{item.diagnosis_type}</p> */}
                                       
                                    </div>
                                    <div className="col-md-3 col-lg-3  mt-3 mt-md-0 text-start text-md-end ">
                                        {/* <p className="mb-1 tittle">{item.booking_status}</p> */}
                                        <p className={`mb-1 fw-bolder text-capitalize ${item.booking_status==='Canceled' || item.booking_status==='CANCELLED' ?'text-danger':item.booking_status==='Missed' || item.booking_status==='MISSED' ? 'text-secondary':'text-success'}` }>{capitalizeFirst(item.booking_status)}</p>
                                        <p className="mb-0 ">INR {item.amount}</p>

                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-2 mt-lg-0">                                       
                                        <button class="btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2 ">Cancel</button>
                                        {item.tracking_status? <button class="btn btn-outline btn-outline-secondary  py-0 mt-2 rounded-1 me-2 " type="button" onClick={(e) => handlecollspe(e, i, bookCode)} aria-controls="trackorder" aria-expanded={open}>Track Order</button> : ''}
                                    </div>
                                    <div className="col-lg-6 text-start order-1 border-lg-2 text-md-end">
                                        <img src={item.vendor_logo} className="hopsital-logo" alt="hospitalLogo" />
                                    </div>
                                </div>
                            </div>
                            
                            <Collapse className={'collapse_' + i == collID ? 'show' : ''} >

                                {
                                diatrackData != null || diatrackData != undefined ?
                                  
                                       
                                    <div id="trackorder" className="mhc-collapse">
                                   
                                        <StepProgressBar
                                            startingStep={0}
                                            //   onSubmit={onFormSubmit}
                                            wrapperClass="Mhc-progressbar"
                                            progressClass="progressbar"
                                            stepClass="step"
                                            contentClass="content"
                                            labelClass="tittle"
                                            subtitleClass="sub-tittle"
                                            buttonWrapperClass="bth-wrapper"                                            
                                            steps={
                                                diatrackData.map((trackItem)=>{
                                                    return(
                                                        {
                                                            label: `${trackItem.status_name}`,
                                                            subtitle: `${trackItem.created_at}`,
                                                        }
                                                    )
                                                })
                                               
                                             
                                            }
                                            
                                        >
                                            </StepProgressBar>
                                    </div>
                                 
                                    : <LodingComponent className="text-center" />
                                }
                            </Collapse>
                        </div>
                    )
                })
                : <div>No Data</div>
            }

            <Modal show={modal} onHide={handleClose} size="lg"     className="mhc-package-modal">
                <Modal.Header closeButton className="pb-0">
                    <Modal.Title className="fs-6">{packageName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Accordion defaultActiveKey="" className="">
                
                    {
                        packageGroupData?
                        Object.entries(packageGroupData).map(([name, tests], i)=>{
                            // console.log("key:", name, "\nvalue:", tests)
                            return(
                                <Accordion.Item eventKey={i}>
                                    <Accordion.Header>{name} (includes {tests.length} {tests.length > 1 ? "tests": "test"})</Accordion.Header>
                                    <Accordion.Body>
                                        <ul className=" m-0">
                                            {
                                                tests.map((test)=>{
                                                return(
                                                    <li>{test.name}</li>
                                                )
                                                })
                                            }
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                          })
                        : <LodingComponent className="text-center" />
                    }
              
                    </Accordion>
                </Modal.Body>
            </Modal>

        </React.Fragment>
    )
}


export default PatientDiagnostics