import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dashboard from '../../assets/images/dashboard.svg'
import { APP_SUB_FOLDER } from "../../utils/constant";

function DashboardLink() {
    const allUsers = useSelector((state) => state.allUsers.value);
    return (
        <>
            {allUsers ?
                <div className="d-flex justify-content-start  justify-content-md-end ">
                    <Link to="/my-dashboard" className="mhc-patient-back">
                        <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                            <div><img src={`${APP_SUB_FOLDER}${dashboard}`} alt="Dashboard" /></div>
                            <div className="fw-bolder ms-2">My Dashboard</div>
                        </div>
                    </Link>
                </div>
                : null}
        </>
    )
}

export default DashboardLink