import { createSlice } from "@reduxjs/toolkit";
const initialStateValue = null;

export const currentLocation = createSlice({
  name: "currentLocation",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setcurrentLocationReducer: (state, action) => {
      state.value = action.payload;
    },

    clearcurrentLocationReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setcurrentLocationReducer, clearcurrentLocationReducer } = currentLocation.actions;

export default currentLocation.reducer;