import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal, Accordion } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from "react-redux";
import { clearPackageDetailReducer, setPackageDetailReducer } from '../../redux/slice/package-detail';

import { carePackages, getPackageDetail, thyrocareDetail } from "../../actions/get-calls";

import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import { APP_SUB_FOLDER } from "../../utils/constant";

const CarePackages = () => {
    const dispatch = useDispatch();
    const { categoryName, categoryId } = useParams();
    const [catName, setCatName] = useState("");
    const [compareId1, setCompareId1] = useState(null);
    const [compareId2, setCompareId2] = useState(null);
    const [packages, setPackages] = useState(null);
    const [packageDetail, setPackageDetail] = useState(null);
    const [showPackageDetail, setShowPackageDetail] = useState(false);

    const selectedPincode = useSelector((state) => state.selectedPincode.value);

    useEffect(async () => {
        setCatName(categoryName);
        let catId = categoryId !== '_' ? categoryId : null;

        if (categoryName && categoryId) {
            let _packages = await carePackages();
            if (_packages && _packages.data && _packages.data.health_offers) {
                for (let index = 0; index < _packages.data.health_offers.length; index++) {
                    const el = _packages.data.health_offers[index];
                    if (el.id == catId) {
                        setPackages(el.packages);
                        break;
                    }
                }
            }
        }
    }, [])
 
    async function handleShow(id) {
        if (!selectedPincode) {
            return toast.error("Pincode not found, go back and enter pincode!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false
            });
        }
        setShowPackageDetail(true);
        const _thyroProduct = await thyrocareDetail(id, selectedPincode);

        if (_thyroProduct && _thyroProduct.data && _thyroProduct.data.details) {
            let modifiedChild = _thyroProduct.data.details[0].childs.reduce(function (
                r,
                a
            ) {
                r[a.group_name] = r[a.group_name] || [];
                r[a.group_name].push(a);
                return r;
            },
                Object.create(null));

            let sortedChild = [];
            for (var x in modifiedChild) {
                sortedChild.push(modifiedChild[x]);
            }

            let mChild = { mData: sortedChild };
            _thyroProduct.data.details[0] = {
                ..._thyroProduct.data.details[0],
                ...mChild,
            };
        }
        setPackageDetail(_thyroProduct.data);
    }

    function handleClose() {
        setShowPackageDetail(false);
        setPackageDetail(null);
    }

    function setCompare(e) {
        if (e.target.checked) {
            if (!compareId1) {
                setCompareId1(parseInt(e.target.value));
            } else {
                setCompareId2(parseInt(e.target.value));
            }
        } else {
            if (compareId1 === parseInt(e.target.value)) {
                setCompareId1(null);
            } else {
                setCompareId2(null);
            }
        }
    }

    function setForwardData(packageDetail) {
        dispatch(clearPackageDetailReducer());
        dispatch(setPackageDetailReducer(packageDetail));
    }

    const breadCrumbData = [
        { type: 1, url: `${APP_SUB_FOLDER}/`, name: 'Home' },
        { type: 1, url: `${APP_SUB_FOLDER}/my-dashboard`, name: 'My Dashboard' },
        { type: 1, url: `${APP_SUB_FOLDER}/book-package-or-test/packages`, name: 'Book Lab/ Radiology' },
        { type: 3, url: '', name: catName.replaceAll('-', ' ') }
    ];

    return (
        <React.Fragment>
            <section className="homecare-category min-body-height">
                <div className="container">
                    <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />
                    <div className="row">
                        <div className="col-12 ">
                            <h1 className="fs-4 mb-1 text-capitalize">{catName.replaceAll('-', ' ')}</h1>
                        </div>
                    </div>
                    <div className="row mt-2 mt-lg-4">
                        {
                            !packages ?
                                <div className="mhc-Loding my-5 text-center">
                                    <img src={`${APP_SUB_FOLDER}../../loader.gif`} alt="loader" />
                                </div>
                                : packages.map((item, index) => {
                                    return (
                                        <div className="col-lg-6 col-xl-4 mb-4" key={index}>
                                            <div className="mhc-package-tab ">
                                                <div className="d-flex">
                                                    <p className="m-0">{item.name}</p>
                                                    {categoryId != "_" ? <i className="ms-2 mhc-icon icon-mhc-info cursor-pointer" onClick={() => { handleShow(item.id) }}></i> : ""}
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mt-2">
                                                    <div>
                                                        <span>
                                                            <del>INR {item.price}</del>
                                                        </span>
                                                        <span className="text-primary ms-3">INR {item.discounted_price}</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end align-items-center mt-3">
                                                    <div className="form-check d-none">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={item.name.trim() + item.id}
                                                            value={item.id}
                                                            disabled={((compareId1 && compareId2) && ((item.id !== compareId1) && (item.id !== compareId2)))}
                                                            onChange={(e) => setCompare(e)} />
                                                        <label className="form-check-label">Compare Now</label>
                                                    </div>
                                                    <Link onClick={() => setForwardData(item)} to={`${APP_SUB_FOLDER}/care-package-booking/${categoryName}/${item.name.toLowerCase().trim().replaceAll(" ", "-") }`} className="btn btn-primary rounded-1">Book</Link>
                                                    {/* <Link onClick={() => setForwardData(item)} to={`/care-package-booking/${categoryName}/${item.name.toLowerCase().trim().replaceAll(" ", "-") }/${categoryId}`} className="btn btn-primary rounded-1">Book</Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>

                    {
                        (compareId1 && compareId2) && (
                            <div className="row my-4">
                                <div className="col-12 text-center">
                                    <Link to={`${APP_SUB_FOLDER}/care-package-comparison/${categoryName}/${compareId1}/${compareId2}`} className="btn btn-primary rounded-1 px-4">Compare</Link>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>

            <Modal
                show={showPackageDetail}
                onHide={() => handleClose()}
                size="xl"
                className="mhc-package-modal"
            >
                <Modal.Header closeButton>
                    {packageDetail && (
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <h6 className="m-0">{packageDetail.name}</h6>
                                <p className="m-0">
                                    Profile Includes ({packageDetail.details[0].testCount}{" "}
                                    Parameters)
                                </p>
                            </div>
                            <div>
                                <img
                                    src={packageDetail.details[0].diagnosis_logo}
                                    className="pacg-icon"
                                />
                            </div>
                        </div>
                    )}
                </Modal.Header>
                <Modal.Body>
                    {packageDetail ? (
                        <Accordion defaultActiveKey={0}>
                            {packageDetail.details[0].mData.map((xItem, index) => {
                                return (
                                    <Accordion.Item eventKey={index} key={index}>
                                        <Accordion.Header>
                                            {xItem[0].group_name} (includes {xItem.length} tests)
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="m-0">
                                                {xItem &&
                                                    xItem.map((mItem, mIndex) => {
                                                        return <li key={mIndex}>{mItem.name}</li>;
                                                    })}
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                );
                            })}
                        </Accordion>
                    ) : (
                        <div className="mhc-Loding text-center my-5">
                            <img src={`${APP_SUB_FOLDER}../../loader.gif`} alt="loader" />
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <ToastContainer />
        </React.Fragment>
    )
}

export default CarePackages;