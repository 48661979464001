import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";
import DashboardLink from "../../component/dashboard-link";
import SwitchProfile from "../../component/login-register/switchProfile";
import PatientDischargeSummaryData from "../patientDischargeSummary/patient-discharge-summary";
import { useSelector, useDispatch } from "react-redux";
import { myDischargeSummary } from "../../actions/dashboard-api";
import LodingComponent from "../../component/loding";
import NoMoreData from "../../component/no-more-data/noMoreData";
import InfiniteScroll from "react-infinite-scroll-component";
import { groupId, hospitalgroupId, is_group_exist } from "../../utils/constant";
const breadcrumArrry=[
    {
      name:'My Dashboard',
      url:"/my-dashboard"
    }, 
    {
        name:'My Discharge Summary',
        url:''
      }, 
  ]
const MyDischargeSummary=()=>{
    const switchToken = useSelector((state) => state.switchToken.value);
    const selectedUserCard = useSelector((state) => state.selectedUserCard.value);
    
    const [dischargeSummary, setDischargeSummary]=useState([])
    const [disPagination, setDisPagination] = useState();
    const [disHasMore, setDisHasMore] = useState(true);
    const [disSummaryPage, setDisSummaryPage] = useState(1);
    const [disSummaryLoader, setDisSummaryLoader] = useState(false);

    useEffect(() => {    
     
        myDischargeSummary(selectedUserCard.id,hospitalgroupId,  switchToken).then(function (response) {            
                 setDisPagination(response.data.pagination)
         setDischargeSummary(response.data.data)

        })
            .catch(function (err) {
                console.log(err);
            });
    }, []);

    const disFetchMoreData=()=>{
      if(disSummaryPage < disPagination.total_pages){
        setDisSummaryLoader(true)
        let nextPage;
        nextPage = disSummaryPage + 1;
        setDisSummaryPage(nextPage)
            myDischargeSummary(selectedUserCard.id, nextPage, switchToken).then(function (response) {
              console.log("response>>", response.data.pagination)
              setDischargeSummary(dischargeSummary.concat(response.data.appointments));
              setDisSummaryLoader(false)
  
            })
              .catch(function (err) {
                  console.log(err);
              });
      }
      else{
        setDisHasMore(false)
      }

    }
    return(
        <React.Fragment>
          <Container className="min-body-height">
                <div className="row  align-items-center breadcrumb_sticky">
                    <div className="col-md-9">   <Breadcrumb textUrl={breadcrumArrry} />        </div>
                    <div className="col-md-3 d-none d-md-block">
                    <DashboardLink />
                    </div>

                </div>
                
                <SwitchProfile />
                <div className="row mt-4">
    <div className="col-12">
    <h1 className="fs-4 mb-1">My Discharge Summary</h1>
              <p className="m-0 fs-6">Search for My Discharge Summary</p>
    </div>
</div>
<div className="mhc-tabs position-relative mt-4">

        

{
                            dischargeSummary?.length == 0 ? <h6 className="text-center mt-2 mb-4 ">No Data Found</h6> :
                            dischargeSummary === undefined? <LodingComponent className="mhc-Loding-minHeight" /> :  <div id="scrollableDiv" className="mhc-scrollDiv">
                                <InfiniteScroll
                                dataLength={dischargeSummary.length}
                                next={disFetchMoreData}
                                hasMore={disHasMore}
                                loader={disSummaryLoader == true ? <LodingComponent className="text-center" /> : ''}
                                scrollableTarget="scrollableDiv"
                                endMessage={disPagination.total_pages > 1 ? <NoMoreData className="text-center mb-3" /> : ''}
                    
                           >
                                {
                                    <PatientDischargeSummaryData  dischargeSummary={dischargeSummary}/>
                                }
                        
                            </InfiniteScroll>
                            </div> 
                            }

</div>

           </Container>  

            </React.Fragment>
    )
}

export default MyDischargeSummary