import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {};

export const deliverySlice = createSlice({
  name: "delivery",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    deliveryReducer: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { deliveryReducer } = deliverySlice.actions;
export default deliverySlice.reducer;
