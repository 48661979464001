import React from 'react'
import NoMoreIcon from '../../assets/images/no-more-data.svg'
const Iprops={
    className:''
}
function NoMoreData (props=Iprops){
    return(
        <>
        <div className={`no-more-data ${props.className}`}>
        <img src={NoMoreIcon} alt={NoMoreIcon} />
        </div>
        </>
    )
}

export default NoMoreData