import React, { useState, useEffect } from "react";
import BookingSection from "./BookingSection";
import { doctorProfileById, getHospitals, doctorAvailabilityById, specialityInfoById} from "../../actions/get-calls";
import { Container, Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";


import Breadcrumb from "../../component/breadcrumb";
import { useSelector } from "react-redux";
import LodingComponent from "../../component/loding";
import DashboardLink from "../../component/dashboard-link";
import { changeDayString } from "../../utils/fliters";

export default function DectorProfile() {

  const {specialities, dId, hId, sId, cId } = useParams();
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const [loadmore, setLoadmore] = useState(false);
  const [docProfile, setDocProfile] = useState(false);
  const [hospitalId, setHospitalId] = useState(hId);
  const [consultId, setConsultId] = useState(cId);
  const [hospital, setHospital] = useState();
  const [docAvail, setDocAvail] = useState(null);
  const [availableDays, setAvailableDays] = useState(null);
  const [specialityName , setSpecialityName] = useState();

  useEffect(async () => {
    const doctors = await doctorProfileById(dId, hospitalId);
    const hospitals = await getHospitals(hospitalId);
    const avails = await doctorAvailabilityById(dId);

    const speciality = await specialityInfoById(sId);

    setSpecialityName(speciality.data.speciality.name);

    setDocProfile(doctors.data.doctor);

    setHospital(hospitals.data.hospital);
    setDocAvail(avails.data.hospitals);

    if (avails && avails.data && avails.data.hospitals) {
      setAvailableDays(avails.data.hospitals[0].availability);
    }
  }, [hospitalId, consultId]);

  const handleLoad = () => {
    setLoadmore(!loadmore);
  };

  useEffect(()=>{
    if (hId != currentLocation.hospitalID) {
      window.location.href ="/";
  }
  },[currentLocation])


  const breadcrumArrry=[
  
    {
        name:`${specialityName}`,
        url:"/"
      }, 
    {
        name: docProfile.name,
        url:`/book-consult/${specialities}/${hId}/${sId}`
      }, 
      {
        name:'Slot Booking Summary',
        url:''
      }, 
  ]


  return (
    <React.Fragment>
      <Container className="min-body-height ">
         {
          docProfile === false ?<LodingComponent className="mhc-Loding-minHeight1" />:<>
              <div className="row align-items-center">
        <div className="col-md-9"><Breadcrumb textUrl={breadcrumArrry} /> </div>
        <div className="col-md-3 d-none d-md-block"><DashboardLink /></div>
        </div>     
          <div className="row ">
          <div className="col-lg-6">
            <div className="mhc-dector-profile-card">
              <div className="img-holder">
                {docProfile === undefined ? 'loading..' : <img src={docProfile.profile_image} alt="img" />}
              </div>
              <div className="ms-3">
                <p className="m-0 fw-bold  text-secondary text-tittle">
                  {docProfile === undefined ? 'loading..' : docProfile.name}
                </p>
                <p className="sub-text mt-1 mb-0">
                  <span className="fw-bold">
                    {docProfile === undefined ? 'loading..' : docProfile.designation}
                  </span>

               
                  <br /> Exp. {docProfile && docProfile.experience} {docProfile.experience >1 ? "Yrs":"Yr"}
                </p>
                <p className="mb-0 mt-2 fw-bold">
                  {hospital === undefined ? 'loading..' : hospital.name}
                </p>
                <ul className="list-unstyled d-flex flex-wrap mb-0 mt-2 text-capitalize fs-6">
                  <li className="pe-1">
                    <span className="text-secondary">Available: </span>
                  </li>
                  {docAvail === undefined ? (
                    <h6>loading..</h6>
                  ) : (
                    (docAvail && docAvail.length > 0) &&
                    docAvail[0].availability.map((item, idx) => (
                      <li key={item} className="px-1">
                        <span className="text-primary">{changeDayString(item)}{((idx + 1) !== docAvail[0].availability.length) && ', '}</span>
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0">
            <div className={`mhc-loadMore ${loadmore === true ? "expend":''}`}>
            <h4 className="fs-7 text-secondary">Profile</h4>  
            {
              docProfile?.summaries.map((item, index)=>{
                return(
                  <p key={index} className="mhc-dector-profile-text" dangerouslySetInnerHTML={{ __html: item.text}}></p>
                )
              })
            }
              {docProfile?.qualifications?
                    <>
                 
                        <h4 className="fs-7 text-secondary mt-3 mb-2">Qualification</h4>
                        <p className="mhc-dector-profile-text mb-1  ps-3 " dangerouslySetInnerHTML={{ __html: docProfile.qualifications }}></p>
                    </>
                  : null}

                  {docProfile?.specialities? 
                    <>
                      <h4 className="fs-7 text-secondary mt-3 mb-0">Specialties</h4>
                      <ul className="d-flexx flex-wrap mb-2">
                      
                        { docProfile?.specialities.map((item, i) => {                        
                            return(
                              <li className="mb-1" key={i+1}>{item}</li>
                            )
                          })
                        }                      
                      </ul>
                    </>
                    : null
                   }
                   </div>
                   <button className="btn btn-link loadMore-btn" onClick={handleLoad}>
                   {loadmore === false ? (
                    <span>More...</span>
                  ) : (
                    <span>Less</span>
                  )}
                   </button>
                  
        
          </div>
        </div>
        <div className="border-bottom border-light w-100 transition mt-3 mt-lg-4"></div>
        <BookingSection
          hospitalId={hospitalId}
          setConsultId={setConsultId}
          availableDays={availableDays}
          reasonCode={docProfile.reason_code}
          docProfile={docProfile}
        />
          </>
         }
        
      </Container>
    </React.Fragment>
  );
}
