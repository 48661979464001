import React, { useEffect, useLayoutEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumb from "../../component/breadcrumb";
import { getHomecareCategory } from "../../actions/get-calls";
import { APP_SUB_FOLDER, encodeString, site_SEO_META } from "../../utils/constant";
import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import { useDispatch } from "react-redux";
import { setVendorReducer } from "../../redux/slice/vendor";
import { Helmet } from "react-helmet";

const HomecareService = () => {

  const [category, setCategory] = useState(null);

  const dispatch = useDispatch();
  dispatch(setVendorReducer(null));


  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  useEffect(() => {
    async function fetchData() {
      const response = await getHomecareCategory();
      let res = response.data;
      if (res && res.homecare_category) {
        setCategory(res.homecare_category);
      }
    }
    fetchData();
  }, []);

  const breadCrumbData = [
    { type: 1, url: `${APP_SUB_FOLDER}/`, name: 'Home' },
    { type: 3, url: '', name: 'Homecare Services' }
  ];
  const pageURL = window.location.origin + window.location.pathname;

  return (
    <React.Fragment>
      {site_SEO_META === true ?
        <Helmet>
          <title>Book Homecare Services | HealthCare Service at Home | My Healthcare</title>
          <meta name="description" content="Book healthcare service at Home. Book services at MyHealthcare for Nursing, Physiotherapy, Critical Care, X-Ray at Home, COVID - 19 Care, ECG, Doctor Visits, Mother & Baby Care, Dialysis, Transition Care, Health Attendant & Nursing Procedures at Home" />
          <meta name="keywords" content="Online Doctor Consultation,Book Doctor Appointments Online,Online Doctor Appointment,Online Medicine Order,Buy medicines online at low prices,Diagnostic Tests and Medical Procedures," />
          <link rel="canonical" href={pageURL} />
          <meta name="robots" content="index, follow" />

          {/* Open Graph / Facebook */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:image" content="https://cms-api-in.myhealthcare.co/image/20220824073108.jpg" />
          <meta property="og:title" content="Online Healthcare Packages | Affordable medical treatment in India | MyHealthcare" />
          <meta property="og:description" content="Book healthcare service at Home. Book services at MyHealthcare for Nursing, Physiotherapy, Critical Care, X-Ray at Home, COVID - 19 Care, ECG, Doctor Visits, Mother & Baby Care, Dialysis, Transition Care, Health Attendant & Nursing Procedures at Home" />
          <meta property="og:keywords" content="Online Doctor Consultation,Book Doctor Appointments Online,Online Doctor Appointment,Online Medicine Order,Buy medicines online at low prices,Diagnostic Tests and Medical Procedures," />
          <meta property="og:url" content={pageURL} />
          <meta property="og:site_name" content="MyHealthcare" />

          {/* Twitter */}
          <meta property="twitter:card" content="Book healthcare service at Home. Book services at MyHealthcare for Nursing, Physiotherapy, Critical Care, X-Ray at Home, COVID - 19 Care, ECG, Doctor Visits, Mother & Baby Care, Dialysis, Transition Care, Health Attendant & Nursing Procedures at Home" />
          <meta property="twitter:url" content={pageURL} />
          <meta property="twitter:title" content="Online Healthcare Packages | Affordable medical treatment in India | MyHealthcare" />
          <meta property="twitter:description" content="Book healthcare service at Home. Book services at MyHealthcare for Nursing, Physiotherapy, Critical Care, X-Ray at Home, COVID - 19 Care, ECG, Doctor Visits, Mother & Baby Care, Dialysis, Transition Care, Health Attendant & Nursing Procedures at Home" />
          <meta property="twitter:image" content="https://cms-api-in.myhealthcare.co/image/20220824073108.jpg" />
        </Helmet>
        :
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      }
      <section>
        <div className="container mb-2 homecare-services">
          <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />
          <div className="row">
            <div className="col-lg-6 ">
              <h1 className="fs-4 mb-1">Homecare Services</h1>
            </div>
          </div>
          <div className="row mt-4">
            {!category ? (
              <div className="mhc-Loding my-5 text-center">
                <img src={`${APP_SUB_FOLDER}loader.gif`} alt="loader" />
              </div>
            ) : (
              category.map((item, index) => {
                return (
                  <>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-4" key={index}>
                      <Card className="homecare-service-card">
                        <div className="row pt-3">
                          <div className="col-12 img-holder">
                            <Card.Img
                              variant="top"
                              className="image"
                              src={item.icon}
                              alt={item.name}
                            />
                          </div>
                        </div>
                        <Card.Body>
                          <div className="row">
                            <div className="col-12 d-flex justify-content-center">
                              <div className="d-flex justify-content-center text-center">
                                <span className="text-dark">{item.name}</span>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-12 d-flex justify-content-center">
                              <Link
                                className="nav-link "
                                aria-current="page"
                                // to={`/homecare-service-category/${encodeString(item.name)}/${item.id}`}>
                                to={`${APP_SUB_FOLDER}/homecare-services/${item.name.replaceAll(' ', '-').toLowerCase()}/${item.id}`}>
                                <button className="btn btn-primary rounded-1 px-4">View</button>
                              </Link>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default HomecareService;
