import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import antiClock from "../../assets/images/anticlock.png";
import { getOTP, switchPatient, verifyMobileOTP } from "../../actions/post-call";
import { getProfile } from "../../actions/get-calls";

import { setCallTokenReducer } from "../../redux/slice/call-token";
import { setSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setAllUsersReducer } from "../../redux/slice/all-users";
import { setSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer } from "../../redux/slice/user-profile";
import SpinnerComponent from "../spinnerComponent";
import { APP_SUB_FOLDER } from "../../utils/constant";

const LoginUser = ({ registeredState, setOtpVerified, setMobile }) => {

  const [mobileNumber, setMobileNumber] = useState('');
  const [currentOTP, setCurrentOTP] = useState('');
  const [showOTP, setShowOTP] = useState(false);

  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(false);
  const [spinnerResponse, setSpinnerResponse]=useState(false)
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.selectedUser.value);

  useEffect(() => {
    if (selectedUser) {
      return registeredState(true);
    }
  }, [selectedUser]);

  function handleOtpRequest() {
      if(mobileNumber !='' && mobileNumber.length == 10){
              getOTP(mobileNumber)
            .then(function (x_response) {
              let x_res = x_response.data;

              if (x_res && x_res.errors) {
                registeredState(false);
                setMobile(mobileNumber);
                return false;
              }

              setIsResend(true);
              setTimeLeft(30);
              setShowOTP(true);
            })
            .catch(function () { });
      }else{
        return showError("Please enter a valid mobile number.");
      }
    
  }

  function verifyOTP() {
    setSpinnerResponse(true)
    verifyMobileOTP({ isd_code: "91", otp: currentOTP, uid: mobileNumber })
      .then(function (login_response) {
        let login_res = login_response.data;
        let firstToken = login_response.headers.authorization;
        setSpinnerResponse(false)
        setOtpVerified(true);
        if (login_res.errors && login_res.errors.length > 0) {
          return showToast(login_res.errors[0].message);
        }

        dispatch(setCallTokenReducer(firstToken));
        dispatch(setAllUsersReducer(login_res.users));

        if (login_res && login_res.users && login_res.users.length > 1) {
          switchPatient({ patient_id: login_res.users[0].id }, firstToken)
            .then(function (switch_response) {
              let switch_token = switch_response.headers.authorization;
              let switch_res = switch_response.data;

              if (switch_response && switch_res && switch_res.errors && switch_res.errors.length > 0) {
                return showToast(switch_res.errors[0].message);
              }

              dispatch(setSwitchTokenReducer(switch_token));
              dispatch(setSelectedUserReducer(switch_res.user));

              getProfile(switch_token)
                .then(function (profile_response) {
                  let profile_res = profile_response.data;

                  if (profile_response && profile_res && profile_res.errors && profile_res.errors.length > 0) {
                    return showToast(profile_res.errors[0].message);
                  }

                  if (profile_response && profile_res) {
                    dispatch(setUserProfileReducer(profile_res.user));
                  }
                }).catch(function (err) { });
            }).catch(function (err) { });
        } else {
          getProfile(firstToken)
            .then(function (profile_response) {
              let profile_res = profile_response.data;

              if (profile_res && profile_res.errors && profile_res.errors.length > 0) {
                return showToast(profile_res.errors[0].message);
              }

              if (profile_res) {
                dispatch(setSelectedUserReducer(profile_res.user));
              }
            }).catch(function (err) { });
        }

        setIsResend(false);
        timeLeft(30);
   
        setShowOTP(false);
      }).catch(function (err) { });
  }

  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1)
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  function showToast(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }

  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }

  return (
    <React.Fragment>
      <ul className={showOTP === false ? "list-unstyled d-flex flex-wrap list-login mb-0" : "list-unstyled d-flex flex-wrap  list-login mb-0"}>
        <li>
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              value={mobileNumber}
              maxLength="10"
              id="mobileNumber"
              name="mobileNumber"
              placeholder="Enter mobile number"
              onChange={(e) => setMobileNumber(e.target.value)}
              onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
            />
            <label htmlFor="name">
              Mobile <sup>*</sup>
            </label>
          </div>
        </li>
        {
          showOTP === false ? (
            <li>
              <button className="btn btn-outline-primary btn-width mt-4 mt-sm-0 rounded-1 mx-0 mx-sm-2" onClick={() => handleOtpRequest()}>Continue </button>
            </li>
          ) : (
            <>
              <li>
                <div className="form-floating ms-0 ms-sm-3  mt-4 mt-sm-0">
                  <input
                    type="text"
                    className="form-control"
                    value={currentOTP}
                    id="OTP"
                    name="OTP"
                    placeholder="Enter OTP"
                    onChange={(e) => setCurrentOTP(e.target.value)}
                  />
                  <label htmlFor="name">OTP <sup>*</sup></label>
                </div>
              </li>
              <li className="text-start text-sm-end">
                <button className="btn btn-outline-primary btn-width mt-4 mt-sm-0 rounded-1"  onClick={() => verifyOTP()}>Verify OTP {spinnerResponse == true? <SpinnerComponent />: null}</button>
              </li>
            </>
          )
        }
      </ul>

      {
        showOTP && (
          <div className="row  mt-4">
            <div className="col-md-8 col-lg-9">
              <p className="m-0">An OTP has been sent to your mobile number, please enter to verify.</p>
            </div>
            <div className="col-md-4 col-lg-3 mt-3 mt-md-0">
              <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                {
                  isResend ? (
                    <div className="anitClock me-2">
                      <img src={`${APP_SUB_FOLDER}${antiClock}`} className="anti-img" alt="" />
                      <p className="otp-time">{timeLeft}</p>
                    </div>
                  ) : (
                    <button onClick={() => handleOtpRequest()} className="btn btn-outline-primary btn-width mt-0 mt-sm-0  me-0 me-sm-2 rounded-1 fs-6 " disabled={isResend}>Resend OTP</button>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      <ToastContainer />
    </React.Fragment>
  );
};

export default LoginUser;
