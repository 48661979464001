import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/breadcrumb";
import DashboardLink from "../../component/dashboard-link";
import {Tabs, Tab } from "react-bootstrap";
import SearchForm from "../../component/search";
import RequestCallback from "../../component/request-callback/requestCallback";
import Pathology from "./pathology";
function BookLabRadiologyTests (){

    const handledSearchData=()=>{

    }
    const breadcrumArrry = [
        {
            name: 'My Dashboard',
            url: "/my-dashboard"
        },
        {
            name: 'Lab/ Radiology tests',
            url: ''
        },
    ]
    return(
        <>
          <div className="min-body-height container position-relative">
            <div className="row align-items-center">
            <div className="col-md-9"><Breadcrumb textUrl={breadcrumArrry} /> </div>
            <div className="col-md-3 d-none d-md-block"><DashboardLink /></div>
            </div>  
            <div className="row justify-content-between">
            <div className="col-lg-6 ">
              <h1 className="fs-4 mb-1">Book Lab/ Radiology tests</h1>
           
            </div>
            <div className="col-lg-5 mt-4 mt-lg-0">
             <SearchForm btnDisplay={true} />
            </div>
          </div>
    <div className="mhc-tabs mhc-tabs-borderless mt-4">
    <RequestCallback position={true} />
        <Tabs defaultActiveKey="Pathology" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="Pathology" title="Pathology">
              <Pathology />
            </Tab>
            <Tab eventKey="Radiology" title="Radiology">
                  2dsfcdsfc
            </Tab>
            <Tab eventKey="Cardiology" title="Cardiology" >
                 3fcxvzc
            </Tab>
           
        </Tabs>
    </div>
          
        </div>
        </>
    )
}

export default BookLabRadiologyTests