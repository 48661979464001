import React, { useState, useEffect } from "react";
import { Accordion, Modal, Container } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb/index";
import SearchForm from "../../component/search/index";
import Fliter from "../../assets/images/fliter.svg";
import videoCall from "../../assets/images/video-call.svg";
import print from "../../assets/images/print.svg";
import doctor from "../../assets/images/doctor-3.svg";
import Pickadate from "pickadate/builds/react-dom";
import { Link, useHistory, useParams } from "react-router-dom";
import { doctorAvailability, doctorTimeSlots } from "../../actions/get-calls";
import { specialityInfoById } from "../../actions/get-calls";
import DoctorConsult from "../patientMyBooking/doctorConsult";
import Doctors from "./doctors";
import { useSelector } from "react-redux";
import LodingComponent from "../../component/loding";
import DashboardLink from "../../component/dashboard-link";

import SearchTextWidget from "../../component/search/search-text-widget";
export default function ConsultDoctor() {
  const { specialities, Id, sId } = useParams();
  const [filterModal, setFilterModal] = useState(false);
  const [doctor, setDoctor] = useState([]);
  const [specialInfo, setSpecialInfo] = useState();
  const [consultType, setConsultType] = useState("0");
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [tempKeyword, setTempKeyoword] = useState('');
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const searchButtonClick=()=>{
    if(keyword.length>3){
      setTempKeyoword(keyword)
    }else if(keyword.length==0){
      setTempKeyoword(keyword)
    }
  }

  useEffect(async () => {
    getDoctors()
    const specials = await specialityInfoById(sId);
  
    setSpecialInfo(specials.data);
  }, []);


  useEffect(async () => {
    if (tempKeyword.length < 3) return false;
    getDoctors();
  }, [tempKeyword]);
  
  useEffect(() => {
    if (tempKeyword.length === 0) {
      getDoctors();
    }
  }, [tempKeyword]);

  function getDoctors() {   
    const delayDebounceFn = setTimeout(async () => {
      setLoading(true);
      const doctors = await doctorAvailability(
        sId,
        Id,
        keyword.length >= 3 ? keyword : ""
      );
      setDoctor(doctors.data.doctors);     
      setLoading(false);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }


  const handleToggle = () => {
    setFilterModal(!filterModal);
  };


  if(specialInfo!== undefined){
    var breadcrumArrry=[
 
      {
          name:`${specialInfo.speciality.name}`,
          url:"/"
        }, 
      {
          name:'Doctors',
          url:''
        }, 
      ]
  }


  return (
    <React.Fragment>
      <section>
        <Container className="min-body-height">


       
               <div className="row align-items-center">
        <div className="col-md-9"><Breadcrumb textUrl={breadcrumArrry} /> </div>
        <div className="col-md-3 d-none d-md-block"><DashboardLink /></div>
        </div>   
        <div className="row justify-content-between">
            <div className="col-lg-6 ">
              <h1 className="fs-4 mb-1">Book Consult</h1>
              <p className="m-0 fs-6">
                Consult with top doctors across specialities
              </p>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
        <SearchTextWidget
              placeHolder={`Search doctors `}
              btnDisplay={false}
              setKeyword={setKeyword}
              searchClickHandler={searchButtonClick}
            />
        </div>
        </div>
          
     <div className="mhc-consult-type d-flex bd-highlight flex-row align-items-start  align-items-lg-center position-relative mt-4 ">
       <div className="order-0 order-lg-0">
         <span className="fw-bold ">Consult Type</span>
       </div>
       <div className="order-2 order-lg-0">
         <ul className="list-unstyled mb-0 mt-lg-0 d-flex align-items-center">
           <li>

             <div className="form-check  ms-0 ms-3 pt-0 pt-sm-1">
               <input
                 className="form-check-input"
                 type="radio"
                 name="flexRadioDefault"
                 id="All"
                 value="0"
                 checked={consultType === "0"}
                 onChange={(e) => setConsultType(e.target.value)}
               />
               <label className="form-check-label" htmlFor="All">All</label>
             </div>
           </li>
           <li>

             <div className="form-check ms-3 ms-sm-4 pt-0 pt-sm-1">
               <input
                 className="form-check-input"
                 type="radio"
                 name={currentLocation.type==="hospital" ? "Hospital":"Clinic"}
                 id="Hospital"
                 value="2"
                 checked={consultType === "2"}
                 onChange={(e) => setConsultType(e.target.value)}
               />
               <label className="form-check-label" htmlFor={currentLocation.type==="hospital" ? "Hospital":"Clinic"}>
                <span >At </span>
                 {
                   currentLocation.type==="hospital" ? "Hospital":"Clinic"
                 }
                
               </label>
             </div>
           </li>
           <li>
             <div className="form-check ms-3 ms-sm-4 pt-0 pt-sm-1">
               <input
                 className="form-check-input"
                 type="radio"
                 name="flexRadioDefault"
                 id="Virtual"
                 value="1"
                 checked={consultType === "1"}
                 onChange={(e) => setConsultType(e.target.value)}
               />
               <label className="form-check-label" htmlFor="Virtual">Virtual</label>
             </div>
           </li>           
         </ul>
       </div>

 
     </div>
     <div className="row mt-4">
      {
      loading===true?    <LodingComponent className="mhc-Loding-minHeight" />: <Doctors doctors={doctor} consultType={consultType} specialities={specialities} sId={sId} Id={Id} />
      }
      
   
     </div>
          
      
        

      
        </Container>
      </section>
    </React.Fragment>
  );
}
