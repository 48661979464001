import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = null;

export const hospitalAddressList = createSlice({
  name: "hospitalAddressList",
  initialState: {
    value: initialStateValue,
  },
  reducers: {
    setHospitalGroupReducer: (state, action) => {
      state.value = action.payload;
    },
    clearHospitalGroupReducer: (state) => {
      state.value = initialStateValue;
    },
  },
});
export const { setHospitalGroupReducer, clearHospitalGroupReducer } = hospitalAddressList.actions;
export default hospitalAddressList.reducer;
