import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { getProfile, configurations } from "../../actions/get-calls";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import countries from "../../assets/data/countries.json";
import userPic from "../../assets/images/user.png"
import { registerOTP } from "../../actions/post-call";

import { jQueryLabels } from "../../utils/fliters";
import { useNavigate } from 'react-router-dom';
import { parasState, parasCity } from "../../actions/dashboard-api";

import moment from "moment";
import SpinnerComponent from "../spinnerComponent";
import { TermsPageURL } from "../../utils/constant";



const NewUserRegister = ({setVerifyOTP, mobile }) => {
  var oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() -18);

  const [DOB, setDOB] = useState(null);
  const [valid, setValid] = useState(null);
  const [allState, setAllState] = useState(null);
  const [allCity, setAllCity] = useState(null);
  const [isTerm, setIsTerm] = useState(false);


  const [salutationList, setSalutationList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [bloodGroupList, setBloodGroupList] = useState([]);
  const [idTypeList, setIDTypeList] = useState([]);

  const [selectedFile, setSelectedFile] = useState();
  const [profileImage, setProfileImage] = useState();
  const [mobile_no, setMobileNo] = useState('');
  const [emailNaState, setEmailNaState] = useState(false);
  const [frontView, setFrontView] = useState();
  const [frontImg, setFrontImg] = useState();
  const [backView, setBackView] = useState();
  const [backImg, setBackImg] = useState();
  const [isVerify, setIsVerify] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [spinnerResponse, setSpinnerResponse]=useState(false)
  useEffect(() => {
    jQueryLabels();    
    parasState().then(function (response) {
      // {id: 21, name: 'India'}
      setAllState(response.data.states)
      }).catch(function (err) {console.log(err);});

      configurations().then(function (response) {
        // console.log(response)
        setSalutationList(response.data.salutation);
        setGenderList(response.data.gender)
        setMaritalStatusList(response.data.marital_status)
        setBloodGroupList(response.data.blood_group)
        setIDTypeList(response.data.id_type)

    }).catch(function (err) {console.log(err);});


  },[]);
  const profilePicChangeHandler = (e) => {
    //  console.log(e.target.files);
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
        setSelectedFile(reader.result);
        setProfileImage(file);
    }.bind(this);
};



const onChangeState = (e) => {
  let state = e.target.options[e.target.selectedIndex].id;
  // console.log(e.target.options[e.target.selectedIndex].id)
    if(state !=''){
      parasCity(state).then(function (response) {
          setAllCity(response.data.cities)
        })
        .catch(function (err) {
            console.log(err);
        });
    }
}

const browseImage = (e, browsefrom) =>{
  e.preventDefault();
  var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      if(browsefrom === "front"){
        setFrontView(reader.result);
        setFrontImg(file);
      }
      if(browsefrom === "back"){       
        setBackView(reader.result);
        setBackImg(file);
      }
    }.bind(this);
}

    const handleEmailNaChange = (e) =>{
      if(e.target.checked == true){
        setEmailNaState(true);
        let email = document.querySelector('#email').value = '';
      }else{
        setEmailNaState(false)
      }
    }
  // useEffect(() => {
  //   formik.setFieldValue('mobile', mobile);
  // }, [mobile])

  const registerUpdateHandler = (e) =>{
    e.preventDefault();
    if(document.querySelector('#salutation').value == ''){
      showError("Salutation is required")
      return false;
    }
    if(document.querySelector('#first_name').value == ''){
      showError("First Name is required")
      return false;
    }
    if(document.querySelector('#last_name').value == ''){
      showError("Last Name is required")
      return false;
    }
    if(document.querySelector('#mobile_no').value == ''){
      showError("Mobile No. is required")
      return false;
    }
    if(emailNaState == false && document.querySelector('#email').value == ''){
      showError("Email is required")
      return false;
    }
    if(emailNaState == false && document.querySelector('#email').value == ''){
      showError("Email is required")
      return false;
    }
    if(document.querySelector('#dob').value == ''){
      showError("Date of Birth is required")
      return false;
    }
    if(document.querySelector('#gender').value == ''){
      showError("Gender is required")
      return false;
    }
    if(document.querySelector('#address_1').value == ''){
      showError("Address Line 1 is required")
      return false;
    }
    if(document.querySelector('#state').value == ''){
      showError("State is required")
      return false;
    }
    if(document.querySelector('#city').value == ''){
      showError("City is required")
      return false;
    }
    if(document.querySelector('#id_type').value == ''){
      showError("Identification Document is required")
      return false;
    }
    // if(document.querySelector('#id_valid_till').value == ''){
    //   showError("Valid Till is required")
    //   return false;
    // }
    if(!frontImg){
      showError("Upload ID Front Image is required")
      return false;
    }

  setMobileNo(e.target.elements.mobile_no.value)

    var payload = new FormData();
        payload.append('hospital_group_id', 1);
        payload.append('salutation', e.target.elements.salutation.value);
        payload.append('first_name', e.target.elements.first_name.value);
        if(e.target.elements.middle_name.value != ''){
        payload.append('middle_name', e.target.elements.middle_name.value);
        }
        payload.append('last_name', e.target.elements.last_name.value);
        payload.append('isd_code', '91');
        payload.append('mobile_no', e.target.elements.mobile_no.value);
        if(emailNaState == true){
            payload.append('email_na', 1);
            // payload.append('email', '');
        }else{
            payload.append('email', e.target.elements.email.value);
        }
        payload.append('dob', e.target.elements.dob.value);
        payload.append('gender', e.target.elements.gender.value);
        payload.append('marital_status', e.target.elements.marital_status.value);
        payload.append('blood_group', e.target.elements.blood_group.value);
        payload.append('emergency_contact', e.target.elements.emergency_contact.value);

        

        if(profileImage){
            payload.append('profile_picture', profileImage);
        }
        
        payload.append('address_1', e.target.elements.address_1.value);
        payload.append('address_2', e.target.elements.address_2.value);
        payload.append('country', e.target.elements.country.value);
        payload.append('pin_code', e.target.elements.pin_code.value);
        payload.append('state', e.target.elements.state.value);
        payload.append('city', e.target.elements.city.value);
        payload.append('id_type', e.target.elements.id_type.value);
        payload.append('id_valid_till', e.target.elements.id_valid_till.value);
        payload.append('id_front_img_url', frontImg);
        if(backImg){
          payload.append('id_back_img_url', backImg);
        }
        setSpinnerResponse(true)
        registerOTP(payload).then(function (response) {
            let res = response.data;            
            setSpinnerResponse(false)
            if (res && res.errors) {
              res.errors.map((e) => {
                return showError(e.message);
              })
              return false;
            }           
            setVerifyOTP(true)
            // toast.success("Registrated successfully! Please enter OTP to login");
          })
          .catch(function (err) { });
     



  }

  
 
  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }


 
  return (
    <React.Fragment>

      <form onSubmit={(e) => registerUpdateHandler(e)} className="">
        <div className="mhc-register-form">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  placeholder="mobile"
                  name="mobile"
                  maxLength="10"
                  required
                  // onChange={formik.handleChange}
                  value={mobile}
                  disabled
                />
                <label htmlFor="name">Mobile <sup>*</sup></label>
              </div>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
              <p className="fs-13 text-primary mb-0">
                Sorry, this number is not registered with us. Please register to
                continue
              </p>
            </div>
          </div>
       
                    <div className="row mt-4">
                    <div className="col-12 text-center">
                    <div className="mhc-user-profile">
                    <img src={selectedFile? selectedFile : userPic} />
                        {selectedFile ==undefined?<span className="mhc-icon icon-camera cameraIcon"></span> :''}
                        <input type="file" name="profile_picture" id="profile_picture" onChange={(e)=>profilePicChangeHandler(e)} accept="image/png, image/gif, image/jpeg" />
                    </div>
                </div>
                      <div className="col-12">
                        <h1 className="fs-5 mb-4 text-center text-lg-start">
                          Patient Details
                        </h1>
                      </div>
                  
                    <div className="col-lg-6">
                            <div className="mhc-selectbox mb-4">
                              <select
                              className="form-select"
                              placeholder="text"
                              id="salutation" name="salutation"
                              tabIndex="1"
                              >
                                
                              <option></option>
                                  {
                                      salutationList?.map((item) => {
                                          return (<option key={item.name} label={item.name} value={item.id}>{item.name}</option>);
                                      })
                                  }
                              </select>
                              <label htmlFor="salutation">Select Title <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                id="first_name"
                                name="first_name"
                                autoComplete="off"
                                placeholder="First name"
                                tabIndex="2"
                              />
                              <label htmlFor="first_name">First Name <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                id="middle_name"
                                name="middle_name"
                                autoComplete="off"
                                placeholder="Middle name"
                                tabIndex="3"
                              />
                              <label htmlFor="middle_name">Middle Name</label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                id="last_name"
                                name="last_name"
                                autoComplete="off"
                                placeholder="Last name"
                                tabIndex="4"
                              />
                              <label htmlFor="last_name">Last Name <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                maxLength="10"
                                placeholder="Mobile"
                                name="mobile_no"
                                id="mobile_no"
                                autoComplete="off"
                                defaultValue={mobile}
                                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                tabIndex="5"
                                />
                              <label htmlFor="mobile_no">Mobile <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                          <div className="d-flex align-items-center">
                            <div className="form-floating w-75 mb-4">
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="email"
                                name="email"
                                autoComplete="off"
                                tabIndex="6"
                                />
                              <label htmlFor="email">Email <sup>{emailNaState == false? '*': ''}</sup></label>
                            </div>
                            <div className="form-check w-25 mb-4 ms-3">
                              <input      tabIndex="7" className="form-check-input" type="checkbox" id="email_na" name="email_na" onChange={(e) => handleEmailNaChange(e)}  />
                                <label className="form-check-label d-block d-sm-inline " htmlFor="email_na">NA</label>
                                </div>
                            </div>
                          </div>
                  
                          <div className="col-lg-6">
                            <div className="form-floating mhc-dob date mb-4">
                              <DatePicker
                                name="dob"
                                id="dob"
                                calendarClassName="mhc-input-date"
                                dateFormat="dd/MM/yyyy"                    
                                selected={DOB}     
                                maxDate={new Date()}             
                                onChange={(date) => setDOB(date)}
                                calendarStartDay={1}
                                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                                openToDate={new Date(oneYearFromNow)}
                                adjustDateOnChange
                                autoComplete="off"
                                tabIndex="8"
                              />
                              <i className="mhc-icon icon-calendar"></i>
                              <label htmlFor="Dob" className={`${DOB!=null? 'dob':''}`}>Date of Birth <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mhc-selectbox mb-4">
                              <select
                                name="gender"
                                id="gender"
                                className="form-select"
                                // required
                                tabIndex="9"
                                >
                                <option></option>
                                {
                                  genderList?.map((item, index) => {
                                    return (<option key={item} label={item} value={index}>{item}</option>);
                                  })
                                }
                              </select>
                              <label htmlFor="gender">Gender <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mhc-selectbox mb-4">
                              <select
                                name="marital_status"
                                id="marital_status"
                                className="form-select"
                                // required
                                tabIndex="10"
                                >
                                    <option></option>
                                {
                                  maritalStatusList?.map((item, index) => {
                                    return (<option key={item} label={item} value={index}>{item}</option>);
                                  })
                                }
                              </select>
                              <label htmlFor="marital_status">Marital Status </label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mhc-selectbox mb-4">
                              <select
                                name="blood_group"
                                id="blood_group"
                                className="form-select"
                                // required
                                tabIndex="11"
                                >
                              <option></option>
                                {
                                  bloodGroupList?.map((item, index) => {
                                    return (<option key={item} label={item} value={index}>{item}</option>);
                                  })
                                }
                              </select>
                              <label htmlFor="blood_group">Blood Group</label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                maxLength="10"
                                className="form-control"
                                id="emergency_contact"
                                placeholder="emergency Contact"
                                name="emergency_contact"
                                autoComplete="off"
                                onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }}
                                tabIndex="12"
                              />
                              <label htmlFor="emergency_contact">Emergency Contact</label>
                            </div>
                          </div>

                          <div className="col-12">
                            <h1 className="fs-5 mb-4 text-center text-lg-start">Address</h1>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                id="address_1"
                                placeholder="Address Line 1"
                                name="address_1"
                                autoComplete="off"
                                tabIndex="13"
                              />
                              <label htmlFor="address_1">Address Line 1 <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                id="address_2"
                                placeholder="Address Line 2"
                                name="address_2"
                                autoComplete="off"
                                tabIndex="14"
                              />
                              <label htmlFor="address_2">Address Line 2</label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mhc-selectbox mb-4">
                              <select
                                name="country"
                                id="country"
                                className="form-select"
                                // value="India" defaultValue={{label:"India", value: "91"}} disabled
                                value="India"  disabled
                                tabIndex="15"
                                >
                                {
                                  countries?.map((item) => {
                                    const { n } = item;
                                    return (<option key={n} label={n} value={n}>{n}</option>);
                                  })
                                }
                              </select>
                              <label htmlFor="country">Country <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mhc-selectbox mb-4">
                              <select
                                name="state"
                                id="state"
                                // required
                                className="form-select"
                                onChange={(e) => { onChangeState(e) }}
                                tabIndex="16"
                                >
                                <option></option>
                                {
                                  allState?.map((item) => {
                                    return (<option key={item.id} label={item.name} value={item.name} id={item.id}>{item.name}</option>);
                                  })
                                }
                              </select>
                              <label htmlFor="state">State <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mhc-selectbox mb-4">
                              <select
                                name="city"
                                id="city"
                                className="form-select"
                                // required
                                tabIndex="17"
                                >
                                  <option></option>
                                {
                                    allCity?.map((item) => {
                                    return (<option key={item.name} label={item.name} value={item.name}>{item.name}</option>);
                                  })
                                }
                              </select>
                              <label htmlFor="city">City <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                id="pin_code"
                                placeholder="PIN Code"
                                name="pin_code"
                                autoComplete="off"
                                tabIndex="18"
                                maxLength={6}
                              />
                              <label htmlFor="pin_code">PIN Code</label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mhc-selectbox mb-4">
                            <select
                                name="id_type" 
                                id="id_type"
                                className="form-select"
                                // required
                                tabIndex="19"
                                >
                                  <option></option>
                                    {
                                    idTypeList?.map((item)=>{
                                        return (<option key={item.id} label={item.name} value={item.id}>{item.name}</option>);
                                    })
                                    }
                              </select>
                              <label htmlFor="id_type">Identification Document <sup>*</sup></label>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-floating mhc-dob date mb-4">
                              <DatePicker
                                name="id_valid_till"
                                id="id_valid_till"
                                calendarClassName="mhc-input-date"
                                dateFormat="dd/MM/yyyy"
                                selected={valid}
                                closeOnScroll={false}
                                minDate={moment().toDate()}
                                onChange={(date) => setValid(date)}
                                calendarStartDay={1}
                                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                                placeholder="Valid Till"
                                dropdownMode="select"
                                showMonthDropdown
                                showYearDropdown
                                adjustDateOnChange
                                autoComplete="off"
                                tabIndex="20"
                              />
                              <i className="mhc-icon icon-calendar"></i>
                              <label htmlFor="id_valid_till" className={`${valid!=null ? 'dob':''}`}>Valid Till </label>
                            </div>
                          </div>

                          <div className="col-lg-6 inputbrowse">
                            <div className="form-floating mb-4 form-control">
                              <input
                                type="file"
                                id="id_front_img"
                                name="id_front_img"
                                onChange={(e)=>browseImage(e, 'front')} accept="image/png, image/gif, image/jpeg"
                                tabIndex="21"
                              />
                              <span>Browse</span>
                              <label htmlFor="id_front_img">Upload ID Front Image <sup>*</sup></label>
                            </div>
                            {frontView? <div className="view_upload_img mb-3"><img src={frontView} /></div> : null}
                          </div>

                          <div className="col-lg-6 inputbrowse">
                            <div className="form-floating mb-4 form-control">
                              <input
                                type="file"
                                id="id_back_img_url"
                                name="id_back_img_url"
                                onChange={(e)=>browseImage(e, 'back')} accept="image/png, image/gif, image/jpeg"
                                tabIndex="22"
                              />
                              <span>Browse</span>
                              <label htmlFor="id_back_img_url">Upload ID Back Image</label>
                            </div>
                            {backView? <div className="view_upload_img mb-3"><img src={backView} /></div> : null}
                          </div>



                          <div className="col-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckDefault"
                                // required
                                value={isTerm}
                                onChange={(e) => setIsTerm(e.target.checked)}
                                tabIndex="23"
                              />
                              <label className="form-check-label d-block d-sm-inline" htmlFor="flexCheckDefault">
                                I have read and agree to the <a href={TermsPageURL} target="_blank" className="text-primary text-decoration-none">Terms and Conditions.</a>
                              </label>
                            </div>
                          </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-12 text-center">
                              <button className="btn btn-custom rounded-1" type="submit" disabled={!isTerm}>Register {spinnerResponse == true? <SpinnerComponent />: null}</button>
                            </div>
                          </div>
                   
            




           </div>
      </form>
      <ToastContainer />
    </React.Fragment>
  );
};

export default NewUserRegister;
