import React, { useEffect, useState } from "react";
import { Container, Tabs, Tab, Modal } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { setcurrentLocationReducer, clearcurrentLocationReducer } from "../../redux/slice/current-location";
import patientmybookings from '../../assets/images/patientmybookings.svg';
import patientmyprescriptions from '../../assets/images/patientmyprescriptions.svg';
import patientMyDocuments from '../../assets/images/patientMyDocuments.svg';
import patientMyReports from '../../assets/images/patientMyReports.svg';
import patientBookConsult from '../../assets/images/patientBookConsult.svg';
import patientBookLab from '../../assets/images/patientBookLab.svg';
import patientBookTests from '../../assets/images/patientBookTests.svg';
import patientOrderMedicine from '../../assets/images/patientOrderMedicine.svg';
import myBills from '../../assets/images/my-bills.svg';
import myDischargeSummary from '../../assets/images/myDischargeSummary.svg';
import { Link, useNavigate } from "react-router-dom";
import SwitchProfile from "../../component/login-register/switchProfile";
import { APP_SUB_FOLDER,  showBookTest, showOrderMedicine } from "../../utils/constant";


const breadcrumArrry = [
  {
    name: 'My Dashboard',
    url: ''
  },
]


const PatientDashBoard = () => {
  const allUsers = useSelector((state) => state.allUsers.value);
  const [modal, setModal] = useState(false)
  const dispatch = useDispatch();
  const hospitalAddressList = useSelector((state) => state.hospitalAddressList.value);
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const navigate = useNavigate();

  function urlRedirection(e, obj) {
    e.preventDefault();
    if (obj.target == '_blank') {
      window.open(obj.url, obj.target);
    } else {
      window.location = obj.url;
    }


  }

  const handleBookConsultModal = (e) => {
    e.preventDefault()
    setModal(true)
  }
  const closeBookConsultModal = () => {
    setModal(false)
    navigate(`/`);
  }

  const handleHopital=(e, index)=>{            
    dispatch(setcurrentLocationReducer(hospitalAddressList[index]));
    navigate(`/`);
    setModal(false)
  }

  return (
    <React.Fragment>
      <Container className="min-body-height">
        <Breadcrumb textUrl={breadcrumArrry} />
        <SwitchProfile />

        <div className="mhc-patient-dashboard mt-4" >

          <div className="row ">
            <div className="col-md-6 col-lg-3 mb-4">
              <Link to="/my-bookings">
                <div className="mhc-card ">
                  <img src={`${APP_SUB_FOLDER}${patientmybookings}`} alt="patientmybookings" />
                  <h4>My Bookings</h4>
                  <p className="m-0">View, reschedule and
                    manage all your booked
                    appointments</p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <Link to="/my-prescriptions">
                <div className="mhc-card">
                  <img src={`${APP_SUB_FOLDER}${patientmyprescriptions}`} alt="patientmyprescriptions" />
                  <h4>My Prescriptions</h4>
                  <p className="m-0">My Prescriptions helps
                    save time and manage
                    your health</p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <Link to="/my-documents">
                <div className="mhc-card">
                  <img src={`${APP_SUB_FOLDER}${patientMyDocuments}`} alt="patientMyDocuments" />
                  <h4>My Documents</h4>
                  <p className="m-0">Self-upload your health
                    documents</p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <Link to="/my-reports">
                <div className="mhc-card">
                  <img src={`${APP_SUB_FOLDER}${patientMyReports}`} alt="patientMyReports" />
                  <h4>My Reports</h4>
                  <p className="m-0">Search for Lab and Radiology reports</p>
                </div>
              </Link>
            </div>





            {showBookTest.show == true ?
              <div className="col-md-6 col-lg-3 mb-4">
                <Link to="/Book-test">
                  <div className="mhc-card">
                    <img src={`${APP_SUB_FOLDER}${patientBookTests}`} alt="patientBookTests" />
                    <h4>Book Tests</h4>
                    <p className="m-0">Book lab tests for all diagnostics procedures and get reports online</p>
                  </div>
                </Link>
              </div>
              : null}

            {showOrderMedicine.show == true ?
              <div className="col-md-6 col-lg-3 mb-4">
                <Link to="/order-medicine">
                  <div className="mhc-card">
                    <img src={`${APP_SUB_FOLDER}${patientOrderMedicine}`} alt="patientOrderMedicine" />
                    <h4>Order Medicine</h4>
                    <p className="m-0">Upload prescription and order online medicine with doorstep delivery</p>
                  </div>
                </Link>
              </div>
              : null}

            <div className="col-md-6 col-lg-3 mb-4">
              <Link to="/my-bills">
                <div className="mhc-card my-bills">
                  <img src={`${APP_SUB_FOLDER}${myBills}`} alt="myBills" />
                  <h4>My Bills</h4>
                  <p className="m-0">With MyBills you can easily
                    manage all your bills.</p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <Link to="/my-discharge-summary">
                <div className="mhc-card my-bills">
                  <img src={`${APP_SUB_FOLDER}${myDischargeSummary}`} alt="myDischargeSummary" />
                  <h4>My Discharge Summary</h4>
                  <p className="m-0">View and download your discharge summaries for past admissions.</p>
                </div>
              </Link>
            </div>


           
            <div className="col-md-6 col-lg-3 mb-4">
              {
                hospitalAddressList?.length > 1?
              
              <Link to="" onClick={handleBookConsultModal}>
                <div className="mhc-card">
                  <img src={`${APP_SUB_FOLDER}${patientBookConsult}`} alt="patientBookConsult" />
                  <h4>Book Consult</h4>
                  <p className="m-0">Book consults with the most
                    trusted doctors in your city</p>
                </div>
              </Link>
              :
              <Link to="/">
              <div className="mhc-card">
                <img src={`${APP_SUB_FOLDER}${patientBookConsult}`} alt="patientBookConsult" />
                <h4>Book Consult</h4>
                <p className="m-0">Book consults with the most
                  trusted doctors in your city</p>
              </div>
            </Link>
              }
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <Link to="/book-package-or-test/packages">
                <div className="mhc-card">
                  <img src={`${APP_SUB_FOLDER}${patientBookLab}`} alt="patientBookLab" />
                  <h4>Book Lab/ Radiology</h4>
                  <p className="m-0">Book lab tests for all diagnostics procedures and get reports online</p>
                </div>
              </Link>
            </div>
          </div>
        </div>

      </Container>
      <Modal show={modal} onHide={closeBookConsultModal} className="mhc-imgPopup"  >
            {/* <Modal.Header closeButton>
                  
                </Modal.Header> */}
                <div className="p-0">
                  <div className="hospital-list px-3 pt-3">
                  {
                    hospitalAddressList?.map((item,index)=>{    
                         
                      return(
                        <div key={index} className="form-check mt-0 custom-checkbox mb-3"  >
                        <input className="form-check-input" type="radio"  name="hopistalLocation" defaultValue={item.hospitalID} data-type={item.type} title={item.label}  id={`hopistall${item.hospitalID}`} onChange={(e)=>handleHopital(e, index)} checked={item.hospitalID===currentLocation?.hospitalID ? true:false} />
                        
                        <label className="form-check-label custom-text cursor-pointer d-inline fw-bolder" htmlFor={`hopistall${item.hospitalID}`} >
                        {item.label}<br />
                        <small className="fw-normal">{item.address}</small>
                        </label>
                      </div>
                      )
                    })
                  }
                 
                 </div>
                  <div className="hospital-footer mt-1 text-center">
                  <button type="submit" onClick={closeBookConsultModal} class="btn btn-custom rounded-1 py-1 px-4">Ok </button>
                 
                    </div>
                    </div>
                    </Modal>

    </React.Fragment>
  )
}


export default PatientDashBoard