import { createGlobalStyle } from "styled-components";
import MHCFontEot from "../assets/fonts/mhc-icon.eot";
import MHCFontSvg from "../assets/fonts/mhc-icon.svg";
import MHCFontTtf from "../assets/fonts/mhc-icon.ttf";
import MHCFontWoff from "../assets/fonts/mhc-icon.woff";
import GothamBookTtf from "../assets/fonts/GothamBook.ttf";
import GothamMediumTtf from "../assets/fonts/GothamMedium.ttf";
import { APP_SUB_FOLDER } from "./constant";
const MHCFontsFace = createGlobalStyle`

@font-face {
    font-family: 'mhc-icon';
    src: url('${APP_SUB_FOLDER}${MHCFontEot}');
    src: url('${APP_SUB_FOLDER}${MHCFontEot}#iefix') format('embedded-opentype'),
         url('${APP_SUB_FOLDER}${MHCFontWoff}') format('woff'),
         url('${APP_SUB_FOLDER}${MHCFontTtf}') format('truetype'),
         url('${APP_SUB_FOLDER}${MHCFontSvg}#mhc-icon') format('svg');
    font-weight: normal;
    font-style: normal;
  }


     @font-face {
          font-family: 'Gotham';
          src: url('${APP_SUB_FOLDER}${GothamBookTtf}');
          font-weight: normal;
          font-style: normal;
     }
          
     @font-face {
          font-family: 'Gotham';
          src: url('${APP_SUB_FOLDER}${GothamMediumTtf}');
          font-weight: 500;
          font-style: normal;
     }
  
  `;
export default MHCFontsFace;