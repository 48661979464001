import React from "react";
import { useSelector } from "react-redux";

import { Link,  } from "react-router-dom";

import { APP_SUB_FOLDER } from "../../utils/constant";
import {Logo} from "../../utils/constant";
const QrNavBar = () => {

  const currentLocation = useSelector((state) => state.currentLocation.value);
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white ">
      <div className="container position-relative">
        <Link className="navbar-brand" to="/qr-register">
          <img src={`${APP_SUB_FOLDER}${Logo}`} />
        </Link>

        <div className={`mhc-phone-call-header` }>
            <a href={`tel:${currentLocation.primary_contact}`}>
                <span className="mhc-icon icon-icon-phone"></span>
                <span className="teltext">{currentLocation.primary_contact} </span>
            </a>
    </div>
      </div>
      
    </nav>
  );
};

export default QrNavBar;