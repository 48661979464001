import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumb from "../../component/breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import countries from "../../assets/data/countries.json";
import { selectedCountry } from "../../utils/constant";
import {
  MemberProfileEdit,
  parasState,
  parasCity,
} from "../../actions/dashboard-api";
import { switchPatient } from "../../actions/post-call";
import { configurations, getProfile } from "../../actions/get-calls";
import DatePicker from "react-datepicker";
import dashboard from "../../assets/images/dashboard.svg";
import memberProfile, {
  setMemberProfileReducer,
} from "../../redux/slice/member-profile";
import { setSelectedUserReducer } from "../../redux/slice/selected-user";
import {
  setSwitchTokenReducer,
  clearSwitchTokenReducer,
} from "../../redux/slice/switch-token";
import DashboardLink from "../../component/dashboard-link";
import { setUserProfileReducer } from "../../redux/slice/user-profile";
import SpinnerComponent from "../../component/spinnerComponent";

const breadcrumArrry = [
  {
    name: "My Dashboard",
    url: "/my-dashboard",
  },
  {
    name: "Edit My Profile",
    url: "",
  },
];

const ProfileEdit = () => {
  const allUsers = useSelector((state) => state.allUsers.value);
  let preSelectedUser = useSelector((state) => state.selectedUser.value);
  let selectedUser = preSelectedUser;
  let memberPorfileData = useSelector((state) => state.memberProfile.value);
  const switchToken = useSelector((state) => state.switchToken.value);
  const callToken = useSelector((state) => state.callToken.value);
  const [switchKey, setSwitchKey] = useState(null);

  const [selectedFile, setSelectedFile] = useState();
  const [profileImage, setProfileImage] = useState();
  const dispatch = useDispatch();

  let profileType = "self";
  if (window.location.href.indexOf("my-profile?member") > -1) {
    selectedUser = memberPorfileData;
    profileType = "member";
  }

  const [salutationList, setSalutationList] = useState([]);
  const [genderList, setGenderList] = useState([]);
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [bloodGroupList, setBloodGroupList] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [idTypeList, setIDTypeList] = useState([]);

  const [allState, setAllState] = useState(null);
  const [allCity, setAllCity] = useState(null);

  const [salutation, setSalutation] = useState();
  const [marital, setMarital] = useState();
  const [gender, setGender] = useState();
  const [bloodGroup, setBloodGroup] = useState();
  const [relation, setRelation] = useState();
  const [state, setState] = useState();
  const [identification, setIdentification] = useState();
  const [valid, setValid] = useState();
  const [frontView, setFrontView] = useState();
  const [frontImg, setFrontImg] = useState();
  const [backView, setBackView] = useState();
  const [backImg, setBackImg] = useState();
  const [emailNavalue, setEmailNaValue] = useState(0);
  const [spinnerResponse, setSpinnerResponse] = useState(false);
  useEffect(() => {
    if (switchToken) {
      setSwitchKey(switchToken);
    }
  }, [switchToken]);

  const onChangeState = (e) => {
    setState(e.target.value);
    let state = e.target.options[e.target.selectedIndex].id;

    if (state != "") {
      setAllCity([]);
      parasCity(state)
        .then(function (response) {
          setAllCity(response.data.cities);
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      setAllCity([]);
    }
  };

  useEffect(() => {
    configurations()
      .then(function (response) {
        // console.log(response)
        setSalutationList(response.data.salutation);
        setGenderList(response.data.gender);
        setMaritalStatusList(response.data.marital_status);
        setBloodGroupList(response.data.blood_group);
        setRelationList(response.data.relation);
        setIDTypeList(response.data.id_type);
      })
      .catch(function (err) {
        console.log(err);
      });

    let getState = countries.find(function (country) {
      return country.n === selectedCountry;
    });

    parasState()
      .then(function (response) {
        // {id: 21, name: 'India'}
        setAllState(response.data.states);
        let stateEle = document.querySelector("#state");
        stateEle.value = selectedUser.state_name;
        stateEle.dispatchEvent(new Event("change"));
      })
      .catch(function (err) {
        console.log(err);
      });

    setTimeout(() => {
      let stateEle = document.querySelector("#state");
      let stateID = stateEle.options[stateEle.selectedIndex].id;

      parasCity(stateID)
        .then(function (response) {
          setAllCity(response.data.cities);
        })
        .catch(function (err) {
          console.log(err);
        });
    }, 2000);

    if (selectedUser) {
      setTimeout(() => {
        setSalutation(selectedUser.salutation);
        setState(selectedUser.state_name);
        setIdentification(selectedUser.id_type);
        if (
          selectedUser.id_valid_till != null &&
          selectedUser.id_valid_till != ""
        ) {
          setValid(new Date(dateParse(selectedUser.id_valid_till)));
        }

        setFrontView(selectedUser.personal_doc.front);
        setBackView(selectedUser.personal_doc.back);

        if (selectedUser.email == null) {
          setEmailNaValue(0);
        }
        if (document.querySelector("#email_na").checked == true) {
          setEmailNaValue(1);
        }
      }, 2000);
    }
  }, [selectedUser]);

  useEffect(() => {
    setMarital(maritalStatusList?.indexOf(selectedUser.marital_status));
  }, [maritalStatusList]);

  useEffect(() => {
    setBloodGroup(bloodGroupList?.indexOf(selectedUser.blood_group));
  }, [bloodGroupList]);

  useEffect(() => {
    setRelation(selectedUser.relation);
  }, [relationList]);
  // useEffect(() => {
  //     setIdentification(selectedUser.id_type)
  // }, [identification]);
  //     useEffect(() => {
  //   setValid(new Date(dateParse(selectedUser.id_valid_till)));
  //     }, [identification]);

  useEffect(() => {
    let cityEle = document.querySelector("#city");
    cityEle.value = selectedUser.city_name;
    cityEle.dispatchEvent(new Event("change"));
  }, [allCity]);

  const profilePicChangeHandler = (e) => {
    //  console.log(e.target.files);
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setSelectedFile(reader.result);
      setProfileImage(file);
    }.bind(this);
  };

  const onEmailNaChange = (e) => {
    if (e.target.checked == true) {
      setEmailNaValue(1);
      // emailNavalue = 1;
      document.querySelector("#email").value = "";
    } else {
      setEmailNaValue(0);
    }
  };

  const browseImage = (e, browsefrom) => {
    e.preventDefault();
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      if (browsefrom === "front") {
        setFrontView(reader.result);
        setFrontImg(file);
      }
      if (browsefrom === "back") {
        setBackView(reader.result);
        setBackImg(file);
      }
    }.bind(this);
  };

  const handleSalutationChange = (e) => {
    e.preventDefault();
    setSalutation(e.target.value);
  };

  const handleMaritalChange = (e) => {
    e.preventDefault();
    setMarital(e.target.value);
  };
  const handleBloodGroupChange = (e) => {
    e.preventDefault();
    setBloodGroup(e.target.value);
  };
  const handleIdentificationChange = (e) => {
    e.preventDefault();
    setIdentification(e.target.value);
  };

  const profileUpdateHandler = (e) => {
    e.preventDefault();
    if (document.querySelector("#salutation").value == "") {
      showError("Select Title is required");
      return false;
    }
    if (emailNavalue == 0 && document.querySelector("#email").value == "") {
      showError("Email is required");
      return false;
    }
    if (document.querySelector("#address_1").value == "") {
      showError("Address Line 1 is required");
      return false;
    }
    if (document.querySelector("#state").value == "") {
      showError("State is required");
      return false;
    }
    if (
      profileType == "member" &&
      document.querySelector("#relation").value == ""
    ) {
      showError("Relation is required");
      return false;
    }
    if (document.querySelector("#city").value == "") {
      showError("City is required");
      return false;
    }
    if (document.querySelector("#id_type").value == "") {
      showError("Identification Document is required");
      return false;
    }
    // if(document.querySelector('#id_valid_till').value == ''){
    //     showError("Valid Till is required");
    //     return false
    // }
    if (!frontImg && !frontView) {
      showError("Upload ID Front Image is required");
      return false;
    }

    var payload = new FormData();
    payload.append("hospital_group_id", 1);
    payload.append("salutation", e.target.elements.salutation.value);
    payload.append("first_name", e.target.elements.first_name.value);
    payload.append("last_name", e.target.elements.last_name.value);
    payload.append(
      "full_name",
      e.target.elements.first_name.value +
        " " +
        e.target.elements.last_name.value
    );
    payload.append("isd_code", selectedUser.isd_code);
    payload.append("mobile_no", e.target.elements.mobile_no.value);

    if (emailNavalue == 1) {
      payload.append("email_na", 1);
      payload.append("email", "");
    } else {
      payload.append("email", e.target.elements.email.value);
    }
    payload.append("dob", e.target.elements.dob.value);
    payload.append("gender", e.target.elements.gender.value);

    payload.append("marital_status", e.target.elements.marital_status.value);
    payload.append("blood_group", e.target.elements.blood_group.value);
    if (profileType == "member") {
      payload.append("family_member_id", selectedUser.id);
      payload.append("relation", e.target.elements.relation.value);
    }
    payload.append(
      "emergency_contact",
      e.target.elements.emergency_contact.value
    );

    if (profileImage) {
      // payload.append('profile_picture', e.target.elements.profile_picture.value);
      payload.append("profile_picture", profileImage);
    }

    payload.append("address_1", e.target.elements.address_1.value);
    payload.append("address_2", e.target.elements.address_2.value);
    payload.append("country", e.target.elements.country.value);
    payload.append("pin_code", e.target.elements.pin_code.value);
    payload.append("state", e.target.elements.state.value);
    payload.append("city", e.target.elements.city.value);
    payload.append("id_type", e.target.elements.id_type.value);

    if (valid) {
      payload.append("id_valid_till", e.target.elements.id_valid_till.value);
    }
    if (frontImg) {
      payload.append("id_front_img_url", frontImg);
    }
    if (backImg) {
      payload.append("id_back_img_url", backImg);
    }
    setSpinnerResponse(true);
    if (allUsers.length > 1) {
      MemberProfileEdit(payload, switchToken)
        .then(function (response) {
          setSpinnerResponse(false);
          if (response.data.message) {
            if (profileType == "member") {
              dispatch(setMemberProfileReducer(response.data.user));
            } else {
              switchPatient({ patient_id: selectedUser.id }, callToken)
                .then(function (switch_response) {
                  let switch_token = switch_response.headers.authorization;
                  dispatch(setSwitchTokenReducer(switch_token));
                  dispatch(setSelectedUserReducer(switch_response.data.user));
                })
                .catch(function (err) {});
            }
            return showToast(response.data.message);
          }
          if (response.data.errors) {
            return showError(response.data.errors[0].message);
          }
        })
        .catch(function (err) {});
    } else {
      MemberProfileEdit(payload, callToken)
        .then(function (response) {
          setSpinnerResponse(false);

          if (response.data.message) {
            if (profileType == "member") {
              dispatch(setMemberProfileReducer(response.data.user));
            } else {
              getProfile(callToken)
                .then(function (profile_response) {
                  let profile_res = profile_response.data;

                  if (
                    profile_res &&
                    profile_res.errors &&
                    profile_res.errors.length > 0
                  ) {
                    return showError(profile_res.errors[0].message);
                  }

                  if (profile_res) {
                    dispatch(setSelectedUserReducer(profile_res.user));
                  }
                })
                .catch(function (err) {});
            }
            return showToast(response.data.message);
          }
          if (response.data.errors) {
            return showError(response.data.errors[0].message);
          }
        })
        .catch(function (err) {});
    }
  };

  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }

  function showToast(str) {
    toast.success(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
    });
  }

  function dateParse(date) {
    let cdate = date?.split("/");
    return cdate[1] + "/" + cdate[0] + "/" + cdate[2];
  }

  return (
    <React.Fragment>
      <Container>
        <div className="row  align-items-center ">
          <div className="col-md-6">
            {" "}
            <Breadcrumb textUrl={breadcrumArrry} />{" "}
          </div>
          <div className="col-md-6">
            <DashboardLink />
          </div>
        </div>

        <form onSubmit={(e) => profileUpdateHandler(e)}>
          <div className="mhc-register-form py-4">
            <h1 className="fs-4  text-center">Profile Edit</h1>
            <div className="row mt-4">
              <div className="col-12 text-center">
                <div className="mhc-user-profile">
                  <img
                    src={
                      selectedFile ? selectedFile : selectedUser.profile_image
                    }
                  />
                  <input
                    tabIndex="1"
                    type="file"
                    name="profile_picture"
                    id="profile_picture"
                    onChange={(e) => profilePicChangeHandler(e)}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <h1 className="fs-5 mb-4 text-center text-lg-start">
                  Patient Details
                </h1>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className={`${
                    salutation === "" ? "notselected" : ""
                  } mhc-selectbox mb-4 `}
                >
                  <select
                    className="form-select"
                    id="salutation"
                    name="salutation"
                    onChange={(e) => handleSalutationChange(e)}
                    value={salutation}
                    tabIndex="2"
                  >
                    <option></option>
                    {salutationList?.map((item) => {
                      return (
                        <option key={item.id} label={item.name} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="salutation">
                    Select Title <sup>*</sup>
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    tabIndex="3"
                    type="text"
                    className="form-control"
                    id="first_name"
                    autoComplete="off"
                    placeholder="First Name"
                    name="first_name"
                    readOnly
                    value={selectedUser.first_name}
                  />
                  <label htmlFor="first_name">
                    First Name <sup>*</sup>
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    tabIndex="4"
                    type="text"
                    className="form-control"
                    id="middle_name"
                    autoComplete="off"
                    placeholder="Middle name"
                    name="middle_name"
                    readOnly
                    value={selectedUser.middle_name}
                  />
                  <label htmlFor="middle_name">Middle Name</label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    tabIndex="5"
                    type="text"
                    className="form-control"
                    id="last_name"
                    autoComplete="off"
                    placeholder="last_name"
                    name="last_name"
                    readOnly
                    value={selectedUser.last_name}
                  />
                  <label htmlFor="last_name">
                    Last Name <sup>*</sup>
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    tabIndex="6"
                    type="tel"
                    className="form-control"
                    maxLength="10"
                    autoComplete="off"
                    id="mobile_no"
                    placeholder="Mobile Number"
                    name="mobile_no"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    readOnly
                    value={selectedUser.mobile_number}
                  />
                  <label htmlFor="mobile_no">
                    Mobile <sup>*</sup>
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="d-flex align-items-center">
                  <div className="form-floating w-80 mb-4">
                    <input
                      tabIndex="7"
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      id="email"
                      placeholder="Email"
                      name="email"
                      defaultValue={
                        emailNavalue == 0 && selectedUser.email
                          ? selectedUser.email
                          : ""
                      }
                    />
                    <label htmlFor="email">
                      Email <sup>{emailNavalue == 0 ? "*" : ""}</sup>
                    </label>
                  </div>
                  <div className="form-check w-20 col-auto mb-4 ms-4">
                    <input
                      tabIndex="8"
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={
                        (emailNavalue == 0 && selectedUser.email == null) ||
                        selectedUser.email == ""
                      }
                      id="email_na"
                      name="email_na"
                      onChange={(e) => {
                        onEmailNaChange(e);
                      }}
                    />
                    <label className="form-check-label " htmlFor="email_na">
                      NA
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="form-floating mhc-dob date mb-4">
                  <input
                    tabIndex="9"
                    type="text"
                    className="form-control"
                    autoComplete="off"
                    id="dob"
                    placeholder="Date of Birth"
                    name="dob"
                    readOnly
                    defaultValue={selectedUser.dob}
                  />
                  {/* <InputDatePiker  /> */}
                  <i className="mhc-icon icon-calendar"></i>
                  <label htmlFor="Dob" className="dob">
                    Date of Birth <sup>*</sup>
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="mhc-selectbox mb-4">
                  <select
                    disabled
                    name="gender"
                    id="gender"
                    tabIndex="10"
                    className="form-select"
                    defaultValue={gender}
                  >
                    {genderList &&
                      genderList.map((item, index) => {
                        return (
                          <option key={item} value={index}>
                            {item}
                          </option>
                        );
                      })}
                  </select>
                  <label htmlFor="gender">Gender</label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className={`${
                    marital === "" ? "notselected" : ""
                  } mhc-selectbox mb-4 `}
                >
                  <select
                    className="form-select"
                    name="marital_status"
                    id="marital_status"
                    onChange={(e) => handleMaritalChange(e)}
                    value={marital}
                    tabIndex="11"
                  >
                    <option></option>
                    {maritalStatusList?.map((item, index) => {
                      return (
                        <option key={index} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </select>

                  <label htmlFor="marital_status">Marital Status</label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className={`${
                    bloodGroup === -1 || bloodGroup === "" ? "notselected" : ""
                  } mhc-selectbox mb-4 `}
                >
                  <select
                    name="blood_group"
                    id="blood_group"
                    className="form-select"
                    onChange={(e) => handleBloodGroupChange(e)}
                    value={bloodGroup}
                    // required
                    tabIndex="12"
                  >
                    <option></option>
                    {bloodGroupList?.map((item, index) => {
                      return (
                        <option key={item} label={item} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="blood_group">Blood Group</label>
                </div>
              </div>

              {profileType == "member" ? (
                <div className="col-md-6 col-lg-3">
                  <div className="mhc-selectbox mb-4">
                    <select
                      // required
                      className="form-select"
                      name="relation "
                      id="relation"
                      tabIndex="13"
                      value={relation}
                      onChange={(e) => setRelation(e.target.value)}
                    >
                      <option></option>
                      {relationList?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor="relation ">
                      Relation <sup>*</sup>
                    </label>
                  </div>
                  {/* <div className="form-floating mb-4">
                                            <input type="text" className="form-control" autoComplete="off" id="relation" placeholder="Relation" name="relation" readOnly defaultValue={selectedUser.relation} />
                                            <label htmlFor="relation ">Relation <sup>*</sup></label>
                                        </div> */}
                </div>
              ) : (
                ""
              )}

              <div className="col-md-6 col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    type="tel"
                    tabIndex="14"
                    maxLength="10"
                    className="form-control"
                    autoComplete="off"
                    id="emergency_contact"
                    name="emergency_contact"
                    placeholder="Emergency Contact"
                    defaultValue={selectedUser.emergency_contact}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <label htmlFor="emergency_contact">Emergency Contact</label>
                </div>
              </div>
              <div className="col-12">
                <h1 className="fs-5 mb-4 text-center text-lg-start">Address</h1>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="form-floating mb-4">
                  <input
                    tabIndex="15"
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    id="address_1"
                    name="address_1"
                    placeholder="Address Line 1"
                    defaultValue={selectedUser.address}
                  />
                  <label htmlFor="address_1">
                    Address Line 1 <sup>*</sup>
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="form-floating mb-4">
                  <input
                    tabIndex="16"
                    type="text"
                    className="form-control"
                    id="address_2"
                    name="address_2"
                    placeholder="Address Line 2"
                    defaultValue={selectedUser.address_2}
                  />
                  <label htmlFor="address_2">Address Line 2</label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="mhc-selectbox mb-4">
                  <select
                    tabIndex="17"
                    className="form-select"
                    required={true}
                    id="country"
                    name="country"
                    defaultValue="India"
                    disabled
                  >
                    {countries?.map((item) => {
                      const { n, c } = item;
                      return (
                        <option key={n} value={n} label={n}>
                          {n}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="country">Country</label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="form-floating mb-4">
                  <input
                    tabIndex="18"
                    type="text"
                    className="form-control"
                    id="pin_code"
                    name="pin_code"
                    maxLength="6"
                    autoComplete="off"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Pin Code"
                    defaultValue={selectedUser.pin_code}
                  />
                  <label htmlFor="pin_code">Pin Code</label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className={`${
                    state === "" ? "notselected" : ""
                  } mhc-selectbox mb-4 `}
                >
                  <select
                    name="state"
                    id="state"
                    // required
                    className="form-select"
                    defaultValue={
                      selectedUser.state_name.charAt(0).toUpperCase() +
                      selectedUser.state_name.slice(1)
                    }
                    // defaultValue={selectedUser.state_name }
                    onChange={(e) => {
                      onChangeState(e);
                    }}
                    tabIndex="19"
                  >
                    <option></option>
                    {allState?.map((item) => {
                      return (
                        <option
                          key={item.id}
                          label={item.name}
                          value={item.name}
                          id={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="state">State</label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className={`${
                    allCity?.length == 0 ? "notselected" : ""
                  } mhc-selectbox mb-4 `}
                >
                  <select
                    name="city"
                    id="city"
                    className="form-select"
                    defaultValue={selectedUser.city_name}
                    // required
                    tabIndex="20"
                  >
                    {allCity?.map((item) => {
                      return (
                        <option
                          key={item.name}
                          label={item.name}
                          value={item.name}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="city">City</label>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div
                  className={`${
                    identification === "" ? "notselected" : ""
                  } mhc-selectbox mb-4 `}
                >
                  <select
                    name="id_type"
                    id="id_type"
                    className="form-select"
                    onChange={(e) => handleIdentificationChange(e)}
                    // onChange={formik.handleChange}
                    value={identification}
                    tabIndex="21"
                  >
                    <option></option>
                    {idTypeList?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="id_type">
                    Identification Document <sup>*</sup>
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="form-floating mhc-dob date mb-4">
                  <DatePicker
                    name="id_valid_till"
                    id="id_valid_till"
                    calendarClassName="mhc-input-date"
                    dateFormat="dd/MM/yyyy"
                    selected={valid}
                    onChange={(date) => setValid(date)}
                    calendarStartDay={1}
                    minDate={new Date()}
                    formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    adjustDateOnChange
                    tabIndex="22"
                    autoComplete="off"
                  />
                  <i className="mhc-icon icon-calendar"></i>
                  <label
                    htmlFor="id_valid_till"
                    className={`${valid != null ? "dob" : ""}`}
                  >
                    Valid Till
                  </label>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 inputbrowse">
                <div className="form-floating mb-4 form-control">
                  <input
                    type="file"
                    tabIndex="23"
                    id="id_front_img"
                    name="id_front_img"
                    onChange={(e) => browseImage(e, "front")}
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  />
                  <span>Browse</span>
                  <label htmlFor="id_front_img">
                    Upload ID Front Image <sup>*</sup>
                  </label>
                </div>
                {frontView ? (
                  <div className="view_upload_img">
                    <embed type="" src={frontView} />
                  </div>
                ) : null}
              </div>

              <div className="col-md-6 col-lg-3 inputbrowse">
                <div className="form-floating mb-4 form-control">
                  <input
                    type="file"
                    tabIndex="24"
                    id="id_back_img_url"
                    name="id_back_img_url"
                    onChange={(e) => browseImage(e, "back")}
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  />
                  <span>Browse</span>
                  <label htmlFor="id_back_img_url">Upload ID Back Image</label>
                </div>
                {backView ? (
                  <div className="view_upload_img">
                    <embed type="" src={backView} />
                  </div>
                ) : null}
              </div>
              <div className="col-12 text-center">
                <button
                  className="btn btn-primary rounded-1"
                  tabIndex="25"
                  type="submit"
                >
                  UPDATE {spinnerResponse == true ? <SpinnerComponent /> : null}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </React.Fragment>
  );
};
export default ProfileEdit;
