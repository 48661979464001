import React, { useState, useEffect } from "react";
import SearchIcon from "../../assets/images/mhc-search-icon.svg";
import { APP_SUB_FOLDER } from "../../utils/constant";

const SearchTextWidget = ({
  placeHolder = "",
  setKeyword = "",
  btnDisplay = true || false,
  radiobutton,
  searchClickHandler

}) => {
  const [closebtn, setClosebtn] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setSearchText('')
  }, [radiobutton]);
  function handleDropdown() {
      
    setClosebtn(true);
  }
  function handleCloseDropdown() {
    setClosebtn(false);
    setSearchText("");
    setKeyword("");
    
  }

  function handleChange(e) {
    setSearchText(e.target.value);
    setKeyword(e.target.value);
  }
 
  function testKeyUP (){
    alert("hello")
  }
  return (
    <React.Fragment>
      <div className="Mhc-search w-100">
        <div className="input-group">
          <span className="input-group-text">
            <img src={`${APP_SUB_FOLDER}${SearchIcon}`} alt="search" />
          </span>
          <input
            type="text"
            value={searchText}
            onChange={(e) => handleChange(e)}
            className="form-control"
            placeholder={placeHolder}
            onKeyUp={(e) => {
              handleDropdown() 
              searchClickHandler()
              if (e.which === 13 || e.target.value === '') {
           
               
              }
            }}
          />
        </div>
        <button
          className={`btn btn-primary ${btnDisplay === true ? "d-block" : "d-none"
            }`}
          onClick={searchClickHandler}
        >
          Search
        </button>
        <button
          className={`search-close d-none mrr  ${closebtn === false ? "d-none" : "d-block"
            }`}
          onClick={() => handleCloseDropdown()}
          type='submit'
        >
          <i className="mhc-icon icon-cancel text-primary"></i>
        </button>
      </div>
    </React.Fragment>
  );
}

export default SearchTextWidget;
