import React, { useState, useEffect, useCallback } from 'react';
import { Card, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import { packageByHospital } from '../../actions/get-calls';
import { clearPackageDetailReducer, setPackageDetailReducer } from '../../redux/slice/package-detail';
import {useSelector, useDispatch } from 'react-redux';
import BreadcrumbWithUrl from '../../component/breadcrumb/breadcrumb-with-url';
import loader from '../../assets/images/loader.gif';
import { APP_SUB_FOLDER } from "../../utils/constant";

const HomecareCategoryPackages = () => {

    const dispatch = useDispatch();
    const { categoryId, hospitalId, slug, hospitalSlug } = useParams();
    const [filterModal, setFilterModal] = useState(false);
    const [hospitalPackages, setHospitalPackages] = useState(null);
    const [packageFeatures, setPackageFeatures] = useState(null);

    const [compareId1, setCompareId1] = useState(null);
    const [compareId2, setCompareId2] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const homeCareImg = useSelector((state) => state.homeCareImg.value);

    useEffect(() => {
        setCompareId1(null);
        setCompareId2(null);
        // packageByHospital(categoryId, hospitalId, 1, 'all')
        //     .then(function (response) {
        //         let res = response.data;
        //         if (res && res.test) {
        //             setHospitalPackages(res.test);
        //         }
        //     })
        //     .catch(function (err) { });

        setLoading(true);
        packageByHospital(categoryId, hospitalId, page, 'all')
            .then(function (response) {
                let res = response.data;
                if (res && res.test) {
                    setTotal(res.pagination.total);
                    setPage(2);
                    setHospitalPackages(res.test);
                }
                setLoading(false);
            })
            .catch(function (err) {
                setLoading(false);
            });
    }, []);

    const loadMorePackageByHospital = () => {
        setLoadingMore(true);
        packageByHospital(categoryId, hospitalId, page, 'all')
            .then(function (response) {
                let res = response.data;
                if (res && res.test) {
                    setTotal(res.pagination.total)
                    setPage(page + 1);
                    setHospitalPackages([...hospitalPackages, ...res.test]);
                }

                setLoadingMore(false);
            })
            .catch(function (err) {
                setLoadingMore(false);
            });
    }

    // useEffect(() => {
    //     packageByHospital();
    // }, [packageByHospital]);

    function setCompare(e) {
        if (e.target.checked) {
            if (!compareId1) {
                setCompareId1(parseInt(e.target.value));
            } else {
                setCompareId2(parseInt(e.target.value));
            }
        } else {
            if (compareId1 === parseInt(e.target.value)) {
                setCompareId1(null);
            } else {
                setCompareId2(null);
            }
        }
    }

    function handleShow(feature) {
        setFilterModal(true);
        setPackageFeatures(feature);
    }

    function handleClose() {
        setFilterModal(false);
        setPackageFeatures(null);
    }

    function setForwardData(packageDetail) {
        dispatch(clearPackageDetailReducer());
        dispatch(setPackageDetailReducer(packageDetail));
    }

    const breadCrumbData = [
        { type: 1, url: `${APP_SUB_FOLDER}/`, name: 'Home' },
        { type: 1, url: `${APP_SUB_FOLDER}/homecare-services`, name: 'Homecare Services' },
        { type: 4, url: '', name: slug.replaceAll('-', ' ') },
        { type: 2, url: '', name: hospitalSlug.replaceAll('-', ' ') },
    ];

    return (
        <React.Fragment>
            <section className="homecare-category">
                <div className="container">
                    <BreadcrumbWithUrl breadcrumbs={breadCrumbData} />

                    <div className="row">
                        <div className="col-lg-6 ">
                            <h1 className="fs-4 mb-1">Homecare Service Packages</h1>
                        </div>
                    </div>
                    <div className="row mt-3">

                        {loading ? (
                            <div className="col-lg-12">
                                <div className="mhc-Loding text-center my-5">
                                    <img src={`${APP_SUB_FOLDER}../loader.gif`} alt="loader" />
                                </div>
                            </div>
                        ) : (
                            hospitalPackages ?
                                <>
                                    {
                                        hospitalPackages.map((item) => (
                                            <div className=" col-lg-4 col-md-6 col-sm-12 col-12 mb-4" key={item.id}>
                                                <Card className="homecare-category-card">
                                                    <Card.Body>
                                                        <div className="row">
                                                            <div className="col-12 d-flex justify-content-between">
                                                                <div className="w-75">
                                                                    <h6>{item.title}</h6>
                                                                    <p className="text-secondary">Starting at INR {item.actual_price}</p>
                                                                </div>
                                                                <div className="w-25 text-end">
                                                                    {/* <Card.Img variant="top" className="image" src={`${APP_SUB_FOLDER}${item.image}`} alt={item.title} /> */}
                                                                    <Card.Img variant="top" className="image" src={`${APP_SUB_FOLDER}${homeCareImg}`} alt={item.title} />
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center justify-content-between">
                                                            <div className="col-auto">
                                                                {/* <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={item.title.trim() + item.id}
                                                                value={item.id}
                                                                disabled={((compareId1 && compareId2) && ((item.id !== compareId1) && (item.id !== compareId2)))}
                                                                onChange={(e) => setCompare(e)} />
                                                            <label className="form-check-label">Compare Now</label>
                                                        </div> */}
                                                            </div>
                                                            <div className="col-auto text-end">
                                                                <div className="d-flex justify-content-end">
                                                                    {/* <button className="btn btn-outline-secondary rounded-1 me-2" onClick={() => handleShow(item)}>Details</button> */}
                                                                    <Link className="btn btn-primary rounded-1" onClick={() => setForwardData(item)} to={`${APP_SUB_FOLDER}/homecare-service-booking/${slug}/${hospitalSlug}/${categoryId}/${hospitalId}`}>Book</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        ))
                                    }

                                    {
                                        loadingMore ? <div className="col-lg-12">
                                            <div className="mhc-Loding text-center my-5">
                                                <img src={`${APP_SUB_FOLDER}${loader}`} alt="loader" />
                                            </div>
                                        </div> : hospitalPackages.length !== total &&
                                        <div className="text-center">
                                            <button onClick={loadMorePackageByHospital} className="btn btn-outline-primary rounded-1 mb-3">Load more</button>
                                        </div>
                                    }
                                </> : <div className="col-12">
                                    <div className="alert alert-danger text-center">No packages available!</div>
                                </div>
                        )}
                    </div>

                    {
                        (compareId1 && compareId2) && (
                            <div className="row my-4">
                                <div className="col-12 text-center">
                                    <Link to={`${APP_SUB_FOLDER}/homecare-service-package-compare/${hospitalId}/${compareId1}/${compareId2}/${slug}/${hospitalSlug}`} className="btn btn-primary rounded-1 px-4">Compare</Link>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>

            <Modal show={filterModal} onHide={() => handleClose()}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    {
                        packageFeatures && (
                            <div className='container homecare-details-modal'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='d-flex justify-content-between align-items-top'>
                                            <h5 className='text-danger'>{packageFeatures.title}</h5>
                                            <span className='img-holder'>
                                                <img src={packageFeatures.image} alt={packageFeatures.title} className='vendor-image'></img>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-12'>
                                        <span className="text-dark">Starting at INR {packageFeatures.actual_price}</span>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-12'>
                                        <h6 >Package Inclusions</h6>
                                        <ul>
                                            {
                                                packageFeatures.features && packageFeatures.features.map((item, index) => {
                                                    return <li key={index}>{item}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default HomecareCategoryPackages;