import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import BreadcrumbWithUrl from "../../component/breadcrumb/breadcrumb-with-url";
import { sethomeCareImg } from "../../redux/slice/homecare-serviceImg";
import { apiURL, APP_SUB_FOLDER } from "../../utils/constant";
import LodingComponent from "../../component/loding";

const HomecareCategory = () => {
  const { categoryName, categoryId, slug } = useParams();
  const [homecare, setHomecare] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedCatName, setSelectedCatName] = useState("");
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (categoryName) {
  //     setSelectedCatName(decodeString(categoryName));
  //   }
  // }, [categoryName])

  // var axios = require('axios');
  const hospitalList = async () => {
    const response = await fetch(
      `${apiURL}hospitals-by-city?category_id=${categoryId}&is_homecare=1`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    // console.log('>>>>>>>>>>>>>>>>>>>>>>result:', result)
    if (result && result.cities) {
      setHomecare(result.cities);
    }
    setLoading(false);
  };

  useEffect(() => {
    hospitalList();

    // hospitalListByCat(categoryId)
    //   .then(function (response) {
    //     let res = response.data;
    //     if (res && res.cities) {
    //       setHomecare(res.cities);
    //     }
    //     setLoading(false);
    //   })
    //   .catch(function (err) { });
  }, []);

  const sendPropsImage = (e, img) => {
    dispatch(sethomeCareImg(img));
  };

  return (
    <React.Fragment>
      <section className="homecare-category">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              <h1 className="fs-4 mb-1">{selectedCatName}</h1>
            </div>
          </div>
          <div className="row mt-3">
            {loading === true ? (
              <LodingComponent className="mhc-Loding-minHeight" />
            ) : (
              <>
                {homecare?.length > 0 ? (
                  <>
                    {homecare.map((item) => {
                      return (
                        <div
                          key={item.id}
                          className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4"
                        >
                          <Card className="homecare-category-card">
                            <Card.Body>
                              <div className="row">
                                <div className="col-8">
                                  <h6>{item.hospitals[0].name}</h6>
                                  <Link
                                    to={`${APP_SUB_FOLDER}/homecare-service-packages/${slug}/${item.hospitals[0].slug}/${categoryId}/${item.hospitals[0].id}`}
                                    onClick={(e) =>
                                      sendPropsImage(e, item.hospitals[0].image)
                                    }
                                    className="btn btn-outline-primary rounded-1 py-1 px-2"
                                  >
                                    View Details
                                  </Link>
                                </div>
                                <div className="col-4 text-end">
                                  <Card.Img
                                    variant="top"
                                    src={`${APP_SUB_FOLDER}${item.hospitals[0].image}`}
                                    alt={item.id}
                                    className="hopistal-icon"
                                  />
                                  {/* <p className="text-secondary fs-13 mb-0">Starting at </p>
                                      <p className="text-primary fs-13 mb-0 fw-bolder">INR {item.test[0].min_price}</p> */}
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="col-12">
                    <div className="alert alert-danger text-center">
                      No packages available!
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default HomecareCategory;
