import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Tabs, Tab } from "react-bootstrap";
import Breadcrumb from "../../component/breadcrumb";

import {
  myReportsCall,
  myReportsDateFilter,
} from "../../actions/dashboard-api";
import { saveAs } from "file-saver";

import { Link, useNavigate } from "react-router-dom";
import SwitchProfile from "../../component/login-register/switchProfile";
import PatientMyReportData from "../patientMyReport/PatientMyReportData";
import InfiniteScroll from "react-infinite-scroll-component";
import LodingComponent from "../../component/loding";
import NoMoreData from "../../component/no-more-data/noMoreData";
import moment from "moment";
import DatePicker from "react-datepicker";
import MyReportComingSoon from "../../component/myReportComingSoon/myReportComingSoon";
import DashboardLink from "../../component/dashboard-link";
import DaysFileter from "../../component/days-fileter";
import { groupId, hospitalgroupId, is_group_exist } from "../../utils/constant";

const breadcrumArrry = [
  {
    name: "My Dashboard",
    url: "/my-dashboard",
  },
  {
    name: "My Reports",
    url: "",
  },
];
const PatientMyReport = () => {
  const switchToken = useSelector((state) => state.switchToken.value);
  const selectedUserCard = useSelector((state) => state.selectedUserCard.value);
  const currentLocation = useSelector((state) => state.currentLocation.value);
  const [myReports, setMyReports] = useState();
  const [filterDays, setFilterDays] = useState(30);
  const [key, setKey] = useState("All");

  const allUsers = useSelector((state) => state.allUsers.value);
  const navigate = useNavigate();
  useEffect(() => {
    if (!allUsers) {
      navigate(`/login`);
    }
  }, []);

  //   console.log("myReports", key, filterDays)
  useEffect(() => {
    const payload = new FormData();
    payload.append("registrationId", selectedUserCard.mhea_patient_id);
    payload.append("page", 1);
    payload.append("departmentType", key === "All" ? "" : key);
    payload.append("encounterType", "");
    payload.append("pageSize", 2000);
    payload.append("days", filterDays);
    payload.append("hospital_id", currentLocation.hospitalID);
    if (is_group_exist === true) {
      payload.append("hospital_group_id", hospitalgroupId);
      // payload.append('group_id', groupId);
    }
    myReportsCall(payload, switchToken)
      .then(function (response) {
        setMyReports(response.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [selectedUserCard.mhea_patient_id]);

  const getDatFiltterDay = (e) => {
    let daysValue = parseInt(e.target.value);
    setFilterDays(daysValue);

    const payload = new FormData();
    payload.append("registrationId", selectedUserCard.mhea_patient_id);
    payload.append("page", 1);
    payload.append("departmentType", key === "All" ? "" : key);
    payload.append("encounterType", "");
    payload.append("pageSize", 2000);
    payload.append("days", daysValue);
    payload.append("hospital_id", currentLocation.hospitalID);
    if (is_group_exist === true) {
      payload.append("hospital_group_id", hospitalgroupId);
      //  payload.append('group_id', groupId);
    }

    myReportsCall(payload, switchToken)
      .then(function (response) {
        setMyReports(response.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const onTabChange = (k) => {
    let departmentType = k;
    setKey(departmentType);
    const payload = new FormData();
    payload.append("registrationId", selectedUserCard.mhea_patient_id);
    payload.append("page", 1);
    payload.append(
      "departmentType",
      departmentType == "All" ? "" : departmentType
    );
    payload.append("encounterType", "");
    payload.append("pageSize", 2000);
    payload.append("days", filterDays);
    payload.append("hospital_id", currentLocation.hospitalID);
    if (is_group_exist === true) {
      payload.append("hospital_group_id", hospitalgroupId);
      // payload.append('group_id', groupId);
    }

    myReportsCall(payload, switchToken)
      .then(function (response) {
        setMyReports(response.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <Container className="min-body-height">
        <div className="row  align-items-center breadcrumb_sticky">
          <div className="col-md-9">
            <Breadcrumb textUrl={breadcrumArrry} />
          </div>
          <div className="col-md-3 d-none d-md-block">
            <DashboardLink />
          </div>
        </div>
        <SwitchProfile />
        <div className="row mt-4">
          <div className="col-12">
            <h1 className="fs-4 mb-1 text-secondary">My Reports</h1>
            <p className="m-0 fs-6">Search for Lab and Radiology reports</p>
          </div>
        </div>
        <div className="mhc-tabs position-relative mt-4">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => onTabChange(k)}
            defaultActiveKey={key}
            className="mb-4"
          >
            <Tab eventKey="All" title="All">
              <DaysFileter
                getDatFiltterDay={(e) => getDatFiltterDay(e)}
                filterDays={filterDays}
                id="all"
                name="all"
              />

              {
                // myReports?.length == 0 || myReports == undefined  ? <MyReportComingSoon />  :
                myReports?.length == 0 ? (
                  <h6 className="text-center mt-2 mb-4 ">No Data Found</h6>
                ) : myReports === undefined ? (
                  <LodingComponent className="mhc-Loding-minHeight" />
                ) : (
                  <PatientMyReportData reportList={myReports} />
                )
              }
            </Tab>
            <Tab eventKey="LIS" title="Pathology">
              <DaysFileter
                getDatFiltterDay={(e) => getDatFiltterDay(e)}
                filterDays={filterDays}
                id="LIS"
                name="LIS"
              />
              {
                // myReports?.length == 0 || myReports == undefined  ? <MyReportComingSoon />  :
                myReports?.length == 0 ? (
                  <h6 className="text-center mt-2 mb-4 ">No Data Found</h6>
                ) : myReports === undefined ? (
                  <LodingComponent className="mhc-Loding-minHeight" />
                ) : (
                  <PatientMyReportData reportList={myReports} />
                )
              }
            </Tab>
            <Tab eventKey="RIS" title="Radiology">
              <DaysFileter
                getDatFiltterDay={(e) => getDatFiltterDay(e)}
                filterDays={filterDays}
                id="RIS"
                name="RIS"
              />
              {
                // myReports?.length == 0 || myReports == undefined  ? <MyReportComingSoon />  :
                myReports?.length == 0 ? (
                  <h6 className="text-center mt-2 mb-4 ">No Data Found</h6>
                ) : myReports === undefined ? (
                  <LodingComponent className="mhc-Loding-minHeight" />
                ) : (
                  <PatientMyReportData reportList={myReports} />
                )
              }
            </Tab>
            <Tab eventKey="OTH" title="Other">
              <DaysFileter
                getDatFiltterDay={(e) => getDatFiltterDay(e)}
                filterDays={filterDays}
                id="OTH"
                name="OTH"
              />
              {
                // myReports?.length == 0 || myReports == undefined  ? <MyReportComingSoon />  :
                myReports?.length == 0 ? (
                  <h6 className="text-center mt-2 mb-4 ">No Data Found</h6>
                ) : myReports === undefined ? (
                  <LodingComponent className="mhc-Loding-minHeight" />
                ) : (
                  <PatientMyReportData
                    filterDays={filterDays}
                    reportList={myReports}
                  />
                )
              }
            </Tab>
          </Tabs>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default PatientMyReport;
