import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";


import { ToastContainer, toast } from 'react-toastify';
import { getProfile, configurations } from "../../actions/get-calls";
import { setCallTokenReducer } from "../../redux/slice/call-token";
import { setSwitchTokenReducer } from "../../redux/slice/switch-token";
import { setAllUsersReducer } from "../../redux/slice/all-users";
import { setSelectedUserReducer } from "../../redux/slice/selected-user";
import { setUserProfileReducer } from "../../redux/slice/user-profile";
import { getOTP,  switchPatient, verifyMobileOTP } from "../../actions/post-call";
import antiClock from "../../assets/images/anticlock.png";
import { useNavigate } from 'react-router-dom';
import SpinnerComponent from "../spinnerComponent";
import { APP_SUB_FOLDER } from "../../utils/constant";
const VerifyRegister = ({ mobile, setOtpVerified }) => {

  const [spinnerResponse, setSpinnerResponse]=useState(false)
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.selectedUser.value);
  const navigate = useNavigate();

  const [currentOTP, setCurrentOTP] = useState('');
  const [mobile_no, setMobileNo] = useState(mobile);
  const [timeLeft, setTimeLeft] = useState(30);
  const [isResend, setIsResend] = useState(true);

  useEffect(() => {
    let timer = setInterval(() => {
      if (isResend) {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1)
        } else {
          clearInterval(timer);
          setIsResend(false);
          setTimeLeft(30);
        }
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });
  function verifyOTP(e) {
    setSpinnerResponse(true)
    e.preventDefault();
    verifyMobileOTP({ isd_code: "91", otp: currentOTP, uid: mobile_no , hospital_group_id: 1})
      .then(function (login_response) {
        setSpinnerResponse(false)
        setOtpVerified(true);
        let login_res = login_response.data;
        let firstToken = login_response.headers.authorization;

        if (login_res.errors && login_res.errors.length > 0) {
          return showError(login_res.errors[0].message);
        }

        dispatch(setCallTokenReducer(firstToken));
        dispatch(setAllUsersReducer(login_res.users));
        
 
        if (login_res && login_res.users && login_res.users.length > 1) {
          switchPatient({ patient_id: login_res.users[login_res.users.length -1].id }, firstToken)
            .then(function (switch_response) {
              let switch_token = switch_response.headers.authorization;
              let switch_res = switch_response.data;

              if (switch_response && switch_res && switch_res.errors && switch_res.errors.length > 0) {
                return showError(switch_res.errors[0].message);
              }

              dispatch(setSwitchTokenReducer(switch_token));
              dispatch(setSelectedUserReducer(switch_res.user));

              getProfile(switch_token)
                .then(function (profile_response) {
                  let profile_res = profile_response.data;

                  if (profile_response && profile_res && profile_res.errors && profile_res.errors.length > 0) {
                    return showError(profile_res.errors[0].message);
                  }

                  if (profile_response && profile_res) {
                    dispatch(setUserProfileReducer(profile_res.user));
                  }
                }).catch(function (err) { });
            }).catch(function (err) { });
        } else {
          getProfile(firstToken)
            .then(function (profile_response) {
              let profile_res = profile_response.data;

              if (profile_res && profile_res.errors && profile_res.errors.length > 0) {
                return showError(profile_res.errors[0].message);
              }

              if (profile_res) {
                dispatch(setSelectedUserReducer(profile_res.user));
              }
            }).catch(function (err) { });
        }

        setIsResend(false);
        timeLeft(30);
        setMobileNo('')

      }).catch(function (err) { });
  }

  function showError(str) {
    toast.error(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  }


  function handleOtpRequest(e) {
    e.preventDefault();
    getOTP(mobile_no)
      .then(function (x_response) {
        let x_res = x_response.data;

        if (x_res && x_res.errors) {
          x_res.errors.map((e) => {
            return showError(e.message);
          })
          return false;
        }

        setIsResend(true);
        setTimeLeft(30);
      })
      .catch(function () { });
  }

  return (
    <form className="mhc-register-form  py-4">
             
    <div className="row justify-content-center">
    {/* <div className="col-md-12 mb-5 text-center"><img className='login-logo' src={logo} /></div> */}
     <div className="col-md-12 mb-3">
         <h3 className="fs-4">Verify OTP</h3>
       </div>

 
     <div className="col-sm-8 ">
     <div className="d-flex ">
       <div className="form-floating w-100 mb-3">
         <input
           type="text"
           className="form-control border-radius-right-0 "
           maxLength="4"
           value={currentOTP}
           id="OTP"
           name="OTP"
           placeholder="Enter OTP"
           onChange={(e) => setCurrentOTP(e.target.value)}
           tabIndex="24"
         />
         <label>OTP <sup>*</sup></label>
       </div>               
       
       {
           isResend===true ? (
             <div className="input-group-text border-radius-left-0 border-start-0  custom-text bg-white">
             <div className="anitClock ">
               <img src={`${APP_SUB_FOLDER}${antiClock}`} className="anti-img" alt="" />
               <p className="otp-time">{timeLeft}</p>
             </div></div>
           ) : (
    <button onClick={(e) => handleOtpRequest(e)} className="input-group-text border-radius-left-0 border-start-0 text-primary bg-white " disabled={isResend}>Resend OTP</button>
           )
         }
        
       </div>
     </div>
     <div className="col-sm-4 ">
 
       <button className="btn btn-custom rounded-1" onClick={(e) => verifyOTP(e)}>Verify OTP {spinnerResponse == true? <SpinnerComponent />: null}</button>
     </div>              
   </div>
     <div className="row mt-3 mt-md-0  justify-content-center">
     <div className="col-12">
       <p className="m-0">An OTP has been sent to your mobile number, please enter to verify.</p>
     </div>
     </div>

 </form>
  );
};

export default VerifyRegister;
